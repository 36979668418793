import React from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { CancelNewOrderButtonProps } from './types';
import { useCancelOrderMutation } from '@src/features/order/redux';
import { ArcButton } from '@src/core/ui';
import { setSelectedOrder } from '../../redux';

const CancelNewOrderButton: React.FC<CancelNewOrderButtonProps> = (props) => {
  const { orderId } = props;

  const dispatch = useDispatch();
  const [cancelOrder, { isLoading }] = useCancelOrderMutation();

  const onCancelOrder = () => {
    cancelOrder(orderId).unwrap().then(() => {
      dispatch(setSelectedOrder(null));
      toast.success('Order has been successfully canceled');
    });
  };
  
  return (
    <ArcButton.ghost 
      fullWidth 
      isLoading={isLoading}
      onClick={onCancelOrder}>
      Cancel 
    </ArcButton.ghost>
  );
};

export default CancelNewOrderButton;