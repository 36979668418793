import React from 'react';
import { ViewerPageProps } from './types';
import { ArcStack, ArcText } from '@src/core/ui';
import { ArcBaseContent, ArcBaseSidebar } from '@src/core/components';
import { EnRouteOrders } from './components';

const ViewerPage: React.FC<ViewerPageProps> = () => {
  return (
    <ArcStack direction='row'>
      <ArcBaseSidebar>
        <ArcStack spacing={1}>
          <ArcText.h4> En Route Orders </ArcText.h4>
          <EnRouteOrders  />
        </ArcStack>
      </ArcBaseSidebar>

      <ArcBaseContent />
    </ArcStack>
  );
};

export default ViewerPage;