import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ArcIconButton, ArcStack, ArcText } from '@src/core/ui';
import { DroneHeaderProps } from './types';
import { useSelector } from 'react-redux';
import { selectDrone } from '../../redux';
import { DroneEntity } from '@src/features/drone/entities';
import { DroneRegisterForm } from '@src/features/drone/components';
import { useDisclosure } from '@src/core/hooks';
import { useDeleteDroneMutation } from '@src/features/drone/redux';
import { useConfirm } from '@src/core/controllers';
import { toast } from 'react-toastify';

const DroneHeader: React.FC<DroneHeaderProps> = () => {
  const { confirm } = useConfirm();
  const { open, show, hide } = useDisclosure();
  const selectedDrone = useSelector(selectDrone) ?? {} as DroneEntity;

  const [ deleteDrone, { isLoading } ] = useDeleteDroneMutation();

  const onDeleteDrone = async () => {
    if (!selectedDrone.id) return;

    if (await confirm()) {
      deleteDrone(selectedDrone.id).unwrap().then(() => {
        toast.success('Delete Drone Success Text');
      });
    }
  };

  return (
    <React.Fragment>
      <DroneRegisterForm 
        open={open}
        onClose={hide}
        title='Change name' 
        drone={selectedDrone} />
      <ArcStack spacing={1} direction='row' alignItems='center' justifyContent='space-between'>
        <ArcStack spacing={1} direction='row' alignItems='center'>
          <ArcText.h3 fontWeight={500}> {selectedDrone.name} </ArcText.h3>
          <ArcText.h5 fontWeight={500} color='text.secondary'> {selectedDrone.serialNumber} </ArcText.h5>
        </ArcStack>

        <ArcStack spacing={2} direction='row' alignItems='center'>
          <ArcIconButton>
            <RefreshIcon />
          </ArcIconButton>
          <ArcIconButton onClick={show}>
            <EditNoteIcon sx={{ color: 'warning.main' }} />
          </ArcIconButton>
          <ArcIconButton isLoading={isLoading} onClick={onDeleteDrone}>
            <DeleteOutlineIcon color='error' />
          </ArcIconButton>
        </ArcStack>
      </ArcStack>
    </React.Fragment>
  );
};

export default DroneHeader;