import React from 'react';
import { DroneListProps } from './types';
import { useDroneProvider } from '@src/features/drone/provider';
import { ArcSkeleton, ArcStack } from '@src/core/ui';
import { ArcDrone } from '@src/core/components';
import { useDispatch, useSelector } from 'react-redux';
import { selectDrone, setSelectedDrone } from '../../redux';
import { DroneEntity } from '@src/features/drone/entities';

const DroneList: React.FC<DroneListProps> = () => {
  const dispatch = useDispatch();
  const selectedDrone = useSelector(selectDrone);
  const { drones, isLoading } = useDroneProvider();

  const onToggleDrone = (drone: DroneEntity) => {
    if (selectedDrone?.id === drone.id) {
      dispatch(setSelectedDrone(null));
      return;
    }
    dispatch(setSelectedDrone(drone));

  };

  if (isLoading) {
    return (
      <ArcStack spacing={1}>
        <ArcSkeleton height={80} />
        <ArcSkeleton height={80} />
        <ArcSkeleton height={80} />
        <ArcSkeleton height={80} />
      </ArcStack>
    );
  }

  return (
    <ArcStack spacing={1}>
      {drones.map((drone) => (
        <ArcDrone.primary 
          key={drone.id} 
          drone={drone}
          selected={selectedDrone?.id === drone.id}
          onClick={() => onToggleDrone(drone)} />
      ))}
    </ArcStack>
  );
};

export default DroneList;