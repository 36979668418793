import { axios } from './get-axios';
import Axios, { AxiosRequestConfig } from "axios";

// types
import { type IAxiosBaseUrl } from './types';
import { type BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { ACCESS_TOKEN_KEY } from '@src/core/config';

export const instance = Axios.create({
  baseURL: 'https://api.asend.ai/api/v1',
});


instance.interceptors.request.use((request: any) => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  request.headers["Content-Type"] = "application/json";
  return request;
});


export const axiosBaseQuery = (): BaseQueryFn<{
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
}> => async ({ url, method, data, params }) => {
  try {
    const axiosData = await axios({ url: url, method, data, params });
    return { data: axiosData.data };
  } catch (axiosError) {
    return { error: axiosError };
  }
};





