import React from 'react';
import { Tooltip } from '@mui/material';
import { ArcTooltipProps } from './types';

const ArcTooltip= React.forwardRef<HTMLDivElement, ArcTooltipProps>((props, ref) => {
  return <Tooltip ref={ref} {...props} />;
});

ArcTooltip.displayName = 'ArcTooltip';

export default ArcTooltip;