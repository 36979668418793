import React from 'react';
import { ArcCollapse, ArcIconButton, ArcStack, ArcStatusPoint, ArcText } from '@src/core/ui';
import { ReactComponent as ArrowUpIcon } from '@src/assets/icons/arrow-up.svg';
import { ToggleCollapseViewProps } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { OrderStatusColors, OrderStatusNames } from '@src/features/order/entities';
import { selectCollapse, setCollapse } from '../../redux';

const ToggleCollapseView: React.FC<ToggleCollapseViewProps> = (props) => {
  const { status, children, count = 0 } = props;

  const dispatch = useDispatch();
  const collapse = useSelector(selectCollapse);

  const isDisabled = count < 1;
  const isOpen = collapse[status];
  

  const onToggleCollapse = () => {
    dispatch(setCollapse({
      ...collapse,
      [status]: !collapse[status],
    }));
  };

  const onClick = () => {
    if (isDisabled) {
      return;
    }
    onToggleCollapse();
  };

  const renderIcon = () => {
    if (isDisabled) {
      return null;
    }

    return (
      <ArrowUpIcon width={18} style={{
        transform: `rotate(${isOpen ? '0deg' : '180deg'})`,
        transition: 'transform 0.3s',
      }}  />
    );
  };

  return (
    <ArcStack>
      <ArcStack 
        spacing={1} 
        direction='row' 
        alignItems='center'
        justifyContent='space-between'
        sx={{ cursor: 'pointer' }}
        onClick={onClick}>

        <ArcStack 
          spacing={1} 
          direction='row' 
          alignItems='center'>
          <ArcStatusPoint.s bgcolor={OrderStatusColors[status]} />
          <ArcText.h5 
            color={isDisabled ? 'text.secondary' : 'text.primary'}> 
            {OrderStatusNames[status]} ({count})
          </ArcText.h5>
        </ArcStack>

        <ArcIconButton size='small'>
          {renderIcon()}
        </ArcIconButton>
      </ArcStack>

      <ArcCollapse in={isOpen}>
        {children}
      </ArcCollapse>
    </ArcStack>
  );
};

export default ToggleCollapseView;