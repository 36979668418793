import React from 'react';
import { DroneRegisterFormProps } from './types';
import { useForm } from 'react-hook-form';
import { IDroneRegisterForm } from '../../entities';
import { EMPTY_VALUE, REQUIRED_VALIDATION_TEXT } from '@src/core/config';
import { ArcButton, ArcDialog, ArcOutlinedInput, ArcStack } from '@src/core/ui';
import { useRegisterDroneMutation } from '../../redux';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setSelectedDrone } from '../../pages/drones-page/redux';

const DroneRegisterForm: React.FC<DroneRegisterFormProps> = (props) => {
  const { drone, open, title, onClose } = props;

  const dispatch = useDispatch();
  const [registerDrone, { isLoading }] = useRegisterDroneMutation();

  const { register, setValue, handleSubmit, formState: { errors } } = useForm<IDroneRegisterForm>({
    defaultValues: {
      name: drone?.name ?? EMPTY_VALUE,
    }
  });

  const onRegisterDrone = (form: IDroneRegisterForm) => {
    if (!drone?.id) return;

    registerDrone({ id: drone.id, name: form.name }).unwrap()
      .then((response) => {
        toast.success('Register Drone Success Text');
        dispatch(setSelectedDrone({
          ...drone,
          ...response,
        }));
        onClose();
      });
  };

  React.useEffect(() => {
    setValue('name', drone?.name ?? EMPTY_VALUE);
  }, [drone]);

  return (
    <ArcDialog open={open} onClose={onClose} title={title}>
      <ArcStack component='form' spacing={2} onSubmit={handleSubmit(onRegisterDrone)}>

        <ArcOutlinedInput 
          autoComplete='off' 
          label='Drone name'
          error={Boolean(errors.name)} 
          helperText={errors.name?.message}
          {...register('name', {
            required: REQUIRED_VALIDATION_TEXT
          })} />

        <ArcButton.brand 
          size='large' 
          type='submit' 
          isLoading={isLoading}>
            Save
        </ArcButton.brand>
      </ArcStack>

      
    </ArcDialog>
  );
};

export default DroneRegisterForm;