import React from 'react';
import { ArcStack } from '@src/core/ui';
import { useFlightProvider } from '@src/features/order/provider';
import { AttitudeIndicator, HeadingIndicator } from 'react-typescript-flight-indicators';
import { EnRouteIndicatorProps } from './types';

const EnRouteIndicator: React.FC<EnRouteIndicatorProps> = () => {
  const { attitudeEuler } = useFlightProvider();
    
  return (
    <ArcStack spacing={1} direction='row' justifyContent='center'>
      <HeadingIndicator 
        size='181px' 
        heading={attitudeEuler?.yaw_deg} />
      <AttitudeIndicator 
        size='181px' 
        pitch={attitudeEuler?.pitch_deg} 
        roll={attitudeEuler?.roll_deg} />
    </ArcStack>
  );
};

export default EnRouteIndicator;