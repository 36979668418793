import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "@src/service";
import { JwtKeyEntity } from "../entities";

export const integrationFeatureApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'integrationFeatureApi',
  tagTypes: ['JwtKeys'],
  endpoints: (builder) => ({
    getJwtKeys: builder.query<JwtKeyEntity[], void>({
      providesTags: ['JwtKeys'],
      query: () => ({
        url: '/integration/v1/jwt-keys',
        method: 'GET',
      }),
    }),
    createJwtKey: builder.mutation<JwtKeyEntity, string>({
      invalidatesTags: ['JwtKeys'],
      query: (name) => ({
        url: '/integration/v1/jwt-issue',
        method: 'POST',
        data: { name },
      }),
    }),
    deleteJwtKey: builder.mutation<void, number>({
      invalidatesTags: ['JwtKeys'],
      query: (id) => ({
        url: `/integration/v1/jwt-keys/${id}`,
        method: 'DELETE',
      }),
    })
  })
});

export const { 
  useGetJwtKeysQuery, 
  useCreateJwtKeyMutation, 
  useDeleteJwtKeyMutation,
} = integrationFeatureApi;