import React from 'react';
import { EnRouteFlightDataProps } from './types';
import { useFlightProvider } from '@src/features/order/provider';
import { ArcCellInfo, ArcContent, ArcStack } from '@src/core/ui';
import { arcLatToDMS, arcLngToDMS, arcSpatium, arcToFixed, arcVelocitas } from '@src/core/extensions';

const cellInfoWidth = 'calc(50% - 8px)';

const EnRouteFlightData: React.FC<EnRouteFlightDataProps> = () => {
  const { flightData, velocityNed, distanceSensor } = useFlightProvider();

  return (
    <ArcContent px={5}>
      <ArcStack gap={2} direction='row' flexWrap='wrap'>
        <ArcCellInfo 
          label='Latency' 
          width={cellInfoWidth}> 
          {arcToFixed(flightData.eta)} ms 
        </ArcCellInfo>
        <ArcCellInfo 
          label='DIST. From Home' 
          width={cellInfoWidth}> 
          {arcSpatium(flightData.distance)}
        </ArcCellInfo>
        <ArcCellInfo 
          label='Vertical Speed' 
          width={cellInfoWidth}> 
          {arcVelocitas(-(velocityNed?.down_m_s ?? 0))}
        </ArcCellInfo>
        <ArcCellInfo 
          label='Horizontal Speed' 
          width={cellInfoWidth}> 
          {arcVelocitas(flightData.speed)}
        </ArcCellInfo>

        <ArcCellInfo 
          label='Latitude' 
          width={cellInfoWidth}> 
          {arcLatToDMS(flightData.latitude)}
        </ArcCellInfo>

        <ArcCellInfo 
          label='Longitude' 
          width={cellInfoWidth}> 
          {arcLngToDMS(flightData.longitude)}
        </ArcCellInfo>

        <ArcCellInfo 
          label='Altitude' 
          width={cellInfoWidth}> 
          {arcSpatium(flightData.altitude)}
        </ArcCellInfo>

        <ArcCellInfo 
          label='Current Distance' 
          width={cellInfoWidth}> 
          {arcSpatium(distanceSensor?.current_distance_m)}
        </ArcCellInfo>
      </ArcStack>
    </ArcContent>
  );
};

export default EnRouteFlightData;