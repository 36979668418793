import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import Main from './main';
import reportWebVitals from './reportWebVitals';
import { store } from '@src/store';
import '@src/styles/global.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material';
import { theme } from './styles/mui';
import { Confirm } from './core/controllers';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>	
      <Provider store={store}>
        <Main />
        <ToastContainer hideProgressBar={true} />  
        <Confirm />
      </Provider>
    </BrowserRouter>
  </ThemeProvider>
);

reportWebVitals();
