import React from 'react';
import { ArcStack, ArcText } from '@src/core/ui';
import { ReactComponent as GlobalIcon } from '@src/assets/global-loading.svg';

const ArcGlobalLoading: React.FC = () => {
  return (
    <ArcStack
      width='100%'
      minHeight='100vh'
      alignItems='center'
      justifyContent='center'
      bgcolor='background.default'>

      <ArcStack display='flex' flexDirection='column' gap='8px'>
        <GlobalIcon />
        <ArcText.h4> Getting everythinkg ready... </ArcText.h4>
      </ArcStack>
    </ArcStack>
  );
};

export default ArcGlobalLoading;