import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "@src/service";
import { OrderEntity, OrderGroupEntity, OrderMissionEntity } from "../entities";
import { IAssignDroneRequest, IAssignDroneResponse, IAutoAssignResponse, ICreateOrderRequest, IModifyRouteRequest, IRequestAuthResponse, ISendCommandRequest, IUpdateOrderRequest } from "./types";
import { OrderWayPointTypeEnum } from "../entities/order-way-point-type-enum";
import { DroneEntity } from "@src/features/drone/entities";

export const orderFeatureApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'orderFeatureApi',
  tagTypes: ['Orders'],
  endpoints: (builder) => ({

    getOrders: builder.query<OrderGroupEntity, void>({
      providesTags: ['Orders'],
      query: () => ({
        url: `/api/v1/orders?page=1&size=100`,
        method: 'GET',
      }),
    }),

    getOrder: builder.query<OrderEntity, number>({
      query: (orderId) => ({
        url: `/api/v1/orders/${orderId}`,
        method: 'GET',
      })
    }),

    getMissionOrder: builder.query<OrderMissionEntity, string | undefined>({
      keepUnusedDataFor: 0,
      query: (missionId) => ({
        url: `/api/v1/orders/mission/${missionId}`,
        method: 'GET'
      }),
      transformResponse: (response: OrderMissionEntity) => {
        const wps = (response.waypoints || []);
        return {
          ...response,
          missionId: response.missionId,
          drone: response.drone ?? {} as DroneEntity,
          orders: response.orders ?? [] as OrderGroupEntity[],
          waypoints: wps.map((wp, idx) => {
            if (idx === 0 || idx === wps.length - 1) {
              return {
                ...wp,
                type: OrderWayPointTypeEnum.DRONE,
              };
            }
            if (wp.orderId) {
              return {
                ...wp,
                type: OrderWayPointTypeEnum.ORDER,
              };
            }
            return {
              ...wp,
              type: OrderWayPointTypeEnum.WAY_POINT,
            };
          })
        };
      }
    }),

    createOrder: builder.mutation<void, ICreateOrderRequest>({
      invalidatesTags: ['Orders'],
      query: (data) => ({
        url: '/api/v1/orders',
        method: 'POST',
        data
      }),
    }),

    updateOrder: builder.mutation<void, IUpdateOrderRequest>({
      invalidatesTags: ['Orders'],
      query: (data) => ({
        url: '/api/v1/orders',
        method: 'PUT',
        data,
      }),
    }),


    autoAssign: builder.mutation<IAutoAssignResponse, number>({
      invalidatesTags: ['Orders'],
      query: (orderId) => ({
        url: `/api/v1/orders/${orderId}/auto-assign-drone`,
        method: 'PUT'
      })
    }),

    cancelOrder: builder.mutation<void, number>({
      invalidatesTags: ['Orders'],
      query: (orderId) => ({
        url: `/api/v1/orders/${orderId}/cancel`,
        method: 'PUT'
      })
    }),

    cancelMissionOrder: builder.mutation<void, string>({
      invalidatesTags: ['Orders'],
      query: (missionId) => ({
        url: `/api/v1/orders/mission/${missionId}/cancel`,
        method: 'PUT',
      })
    }),

    requestAuth: builder.mutation<IRequestAuthResponse, string>({
      invalidatesTags: ['Orders'],
      query: (missionId) => ({
        url: `/api/v1/orders/${missionId}/authorization`,
        method: 'GET',
      })
    }),

    assignDrone: builder.mutation<IAssignDroneResponse, IAssignDroneRequest>({
      invalidatesTags: ['Orders'],
      query: (data) => ({
        url: '/api/v1/orders/assign-drone',
        method: 'PUT',
        data,
      })
    }),

    modifyRoute: builder.mutation<void, IModifyRouteRequest>({
      invalidatesTags: ['Orders'],
      query: (data) => ({
        url: '/api/v1/orders/modify-routes',
        method: 'PUT',
        data,
      })
    }),

    takeOff: builder.mutation<void, string>({
      invalidatesTags: ['Orders'],
      query: (missionId) => ({
        url: `/api/v1/orders/${missionId}/TAKE_OFF`,
        method: 'POST'
      })
    }),

    sendCommand: builder.mutation<void, ISendCommandRequest>({
      query: ({ missionId, command }) => ({
        url: `/api/v1/orders/${missionId}/${command}`,
        method: 'POST',
      })
    }),
  })
});

export const { 
  useGetOrderQuery,
  useGetOrdersQuery, 
  useSendCommandMutation,
  useGetMissionOrderQuery,
  useLazyGetMissionOrderQuery,
  
  useTakeOffMutation,
  useModifyRouteMutation,
  useAutoAssignMutation,
  useAssignDroneMutation,
  useCancelOrderMutation,
  useRequestAuthMutation,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useCancelMissionOrderMutation,
} = orderFeatureApi;