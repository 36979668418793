import React from 'react';
import { ArcDrone } from '@src/core/components';
import { useOrderProvider } from '@src/features/order/provider';
import { ArcContent, ArcSkeleton, ArcStack, ArcText } from '@src/core/ui';

import { EnRouteOrdersProps } from './types';
import { useNavigate } from 'react-router-dom';
import { routesConfig } from '@src/core/routes';

const EnRouteOrders: React.FC<EnRouteOrdersProps> = () => {
  const navigate = useNavigate();
  const { orders, isLoading } = useOrderProvider();

  if (isLoading) {
    return (
      <ArcStack spacing={1} my={1}>
        <ArcSkeleton height={75} />
        <ArcSkeleton height={75} />
        <ArcSkeleton height={75} />
      </ArcStack>
    );
  }

  if (orders.en_route.length < 1) {
    return (
      <ArcContent> 
        <ArcText.h5 textAlign='center'> No orders </ArcText.h5>
      </ArcContent>
    );
  }

  return (
    <ArcStack spacing={1}>
      {orders.en_route.map((o) => (
        <ArcDrone.primary 
          key={o.missionId} 
          drone={o.drone}
          onClick={() => navigate(`${routesConfig.enRouteViewer}/${o.missionId}`)} />
      ))}
    </ArcStack>
  );
};

export default EnRouteOrders;