import React from 'react';
import { ArcDroneIconProps } from './types';
import { ArcStack } from '@src/core/ui';

const ArcDroneIcon: React.FC<ArcDroneIconProps> = (props) => {
  const { iconColor = 'white', ...flexProps } = props;
  return (
    <ArcStack width={30} height={30} {...flexProps}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 30 30" fill="none">
        <path d="M12.4918 12.2457H17.39M12.4918 12.2457V17.1439M12.4918 12.2457L8.20585 7.95975M17.39 12.2457V17.1439M17.39 12.2457L21.6759 7.95975M17.39 17.1439H12.4918M17.39 17.1439L21.6759 21.4298M12.4918 17.1439L8.20585 21.4298M12.4428 7.34748C12.3302 6.55956 12.0004 5.81857 11.4904 5.20753C10.9803 4.59648 10.3103 4.13953 9.55517 3.88786C8.80008 3.6362 7.98983 3.59976 7.21521 3.78264C6.44058 3.96552 5.73218 4.36048 5.16938 4.92329C4.60658 5.48609 4.21161 6.19449 4.02873 6.96911C3.84585 7.74374 3.88229 8.55399 4.13396 9.30908C4.38562 10.0642 4.84258 10.7343 5.45362 11.2443C6.06467 11.7543 6.80566 12.0841 7.59357 12.1967M22.2881 12.1967C23.0761 12.0841 23.8171 11.7543 24.4281 11.2443C25.0391 10.7343 25.4961 10.0642 25.7478 9.30908C25.9994 8.55399 26.0359 7.74374 25.853 6.96911C25.6701 6.19449 25.2751 5.48609 24.7123 4.92329C24.1495 4.36048 23.4411 3.96552 22.6665 3.78264C21.8919 3.59976 21.0816 3.6362 20.3266 3.88786C19.5715 4.13953 18.9014 4.59648 18.3914 5.20753C17.8813 5.81857 17.5516 6.55956 17.4389 7.34748M17.4389 22.0421C17.5516 22.83 17.8813 23.571 18.3914 24.182C18.9014 24.793 19.5715 25.25 20.3266 25.5017C21.0816 25.7533 21.8919 25.7898 22.6665 25.6069C23.4411 25.424 24.1495 25.029 24.7123 24.4662C25.2751 23.9034 25.6701 23.195 25.853 22.4204C26.0359 21.6458 25.9994 20.8355 25.7478 20.0805C25.4961 19.3254 25.0391 18.6553 24.4281 18.1453C23.8171 17.6352 23.0761 17.3055 22.2881 17.1928M7.59357 17.1928C6.80566 17.3055 6.06467 17.6352 5.45362 18.1453C4.84258 18.6553 4.38562 19.3254 4.13396 20.0805C3.88229 20.8355 3.84585 21.6458 4.02873 22.4204C4.21161 23.195 4.60658 23.9034 5.16938 24.4662C5.73218 25.029 6.44058 25.424 7.21521 25.6069C7.98983 25.7898 8.80008 25.7533 9.55517 25.5017C10.3103 25.25 10.9803 24.793 11.4904 24.182C12.0004 23.571 12.3302 22.83 12.4428 22.0421" stroke={iconColor} strokeWidth="2.4491" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </ArcStack>
  );
};

export default ArcDroneIcon;