import React from 'react';
import { DronesSectionProps } from './types';
import { ArcContent, ArcSkeleton, ArcStack, ArcText } from '@src/core/ui';
import { ArcDrone } from '@src/core/components';

const DronesSection: React.FC<DronesSectionProps> = (props) => {
  const { title, isLoading, drones, selectedDrone, onToggle } = props;

  if (isLoading) {
    return (
      <ArcStack spacing={1} my={1}>
        <ArcSkeleton width={120} height={20}/>
        <ArcSkeleton height={75} />
        <ArcSkeleton height={75} />
      </ArcStack>
    );
  }
  return (
    <ArcStack my={2}>
      <ArcText.h5 my={1}> {title} </ArcText.h5>
      {drones.length > 0 
        ? 
        <ArcStack spacing={1}>
          {drones.map((drone) => (
            <ArcDrone.primary 
              key={drone.id} 
              drone={drone}
              selected={selectedDrone?.id === drone.id} 
              onClick={() => onToggle(drone)}/>
          ))}
        </ArcStack> 
        : <ArcContent> 
          <ArcText.h5 textAlign='center'> No drones </ArcText.h5>
        </ArcContent>}
    </ArcStack>
  );
};

export default DronesSection;