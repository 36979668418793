import React from 'react';
import { OrderFeature, ProfileFeature, ViewerFeature } from '@src/features';
import { routesConfig } from '@src/core/routes';
import { Navigate, Route, Routes } from 'react-router-dom';

const ViewerRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/profile/*' element={<ProfileFeature />} />
      <Route path='/viewer/*' element={<ViewerFeature />} />
      <Route path='/orders/*' element={<OrderFeature />}/>
      <Route path='*' element={<Navigate to={routesConfig.viewer}/>} />
    </Routes>
  );
};

export default ViewerRoutes;