import React from 'react';
import { ChannelCallback, ChannelResponseEnum, IArcSocketContext } from './types';
import { BASE_SOCKET_URL, RECONNECT_TIME } from '@src/core/config';

export const ArcSocketContext = React.createContext<IArcSocketContext>({
  readyState: false,
  arcSocket: () => null,
  subscribe: () => {},
  unsubscribe: () => {}
});

const ArcSocketProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const socket = React.useRef<WebSocket>();
  const arcChannels = React.useRef<any>({});
  const [readyState, setReadyState] = React.useState<boolean>(false);

  const arcSocket = () => socket.current ?? null;

  const subscribe = (channel: ChannelResponseEnum, callback: ChannelCallback) => {
    arcChannels.current[channel] = callback;
  };

  const unsubscribe = (channel: ChannelResponseEnum) => {
    delete arcChannels.current[channel];
  };

  const createSocket = React.useCallback(() => {
    const socketURL = process.env.REACT_APP_SOCKET_BASE_URL ?? BASE_SOCKET_URL;
    socket.current = new WebSocket(socketURL);

    socket.current.onopen = () => {
      setReadyState(true);
    };

    socket.current.onclose = () => {
      setTimeout(() => createSocket(), RECONNECT_TIME);
    };

    socket.current.onmessage = (event) => {
      const { type, ...data } = JSON.parse(event.data);
      if (arcChannels.current[type]) {
        arcChannels.current[type](data);
      }
      
    };
  }, []);

  React.useEffect(() => {
    createSocket();
    return () => socket.current && socket.current.close();;
  }, [createSocket]);

  return (
    <ArcSocketContext.Provider 
      value={{ 
        arcSocket, 
        subscribe, 
        unsubscribe,
        readyState,

      }}>
      {children}
    </ArcSocketContext.Provider>
  );

};

export default ArcSocketProvider;