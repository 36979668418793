import React from 'react';
import { ArcStack, ArcText } from '@src/core/ui';

import { ArcOrderProps, ArcOrderVariantProps } from './types';
import { EMPTY_STRING } from '@src/core/config';

const ArcOrder: React.FC<ArcOrderProps> & ArcOrderVariantProps = (props) => {
  const { order, children, ...rest } = props;

  return (
    <ArcStack border='0.5px solid'  {...rest}>
      <ArcStack>
        <ArcText.h6 maxLines={1}> #{order.id} {order.clientName}  </ArcText.h6>
        <ArcText.caption maxLines={4}> {order.deliveryLocation.address ?? EMPTY_STRING} </ArcText.caption>
      </ArcStack>
      {children}
    </ArcStack>
  );
};

ArcOrder.primary = ({ selected, sx, ...rest }) => (
  <ArcOrder 
    p='12px' 
    borderRadius={1}
    bgcolor='background.paper' 
    sx={{ cursor: 'pointer', ...sx }}
    borderColor={selected ? 'brand.main' : 'background.paper'}
    {...rest} /> 
);

ArcOrder.secondary = ({ sx, ...rest }) => (
  <ArcOrder 
    p='12px' 
    borderRadius={1} 
    bgcolor='background.default' 
    sx={{ cursor: 'pointer', ...sx }} 
    {...rest} />
);

export default ArcOrder;