export enum OrderStatusEnum {
    NEW='new',
    ASSIGNED='assigned',
    READY_FOR_TAKE_OFF = 'ready_for_take_off',
    EN_ROUTE = 'en_route',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
    ERROR = 'error',
  }
  
export const OrderStatusNames: Record<OrderStatusEnum, string> = {
  [OrderStatusEnum.NEW]: 'New',
  [OrderStatusEnum.ASSIGNED]: 'Assigned',
  [OrderStatusEnum.READY_FOR_TAKE_OFF]: 'Ready for take off',
  [OrderStatusEnum.EN_ROUTE]: 'En route',
  [OrderStatusEnum.COMPLETED]: 'Completed',
  [OrderStatusEnum.CANCELLED]: 'Cancelled',
  [OrderStatusEnum.ERROR]: 'Error',
};
  
export const OrderStatusColors: Record<OrderStatusEnum, string> = {
  [OrderStatusEnum.NEW]: 'ghost.main',
  [OrderStatusEnum.ASSIGNED]: 'ghost.main',
  [OrderStatusEnum.READY_FOR_TAKE_OFF]: 'success.main',
  [OrderStatusEnum.EN_ROUTE]: 'info.main',
  [OrderStatusEnum.COMPLETED]: 'ghost.main',
  [OrderStatusEnum.CANCELLED]: 'ghost.main',
  [OrderStatusEnum.ERROR]: 'error.main',
};
  