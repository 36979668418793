import React from 'react';
import { ArcStack } from '@src/core/ui';
import { OrdersWrapperView, ToggleCollapseView } from '../../components';
import { ArcDrone } from '@src/core/components';
import { AssignedSectionProps } from './types';
import { OrderStatusEnum } from '@src/features/order/entities';

const AssignedSection: React.FC<AssignedSectionProps> = (props) => {
  const { orders, selected, onSelectOrder } = props;
  
  return (
    <ArcStack>
      <ToggleCollapseView 
        count={orders.length}
        status={OrderStatusEnum.ASSIGNED}>

        <OrdersWrapperView>
          {orders.map((order) => (
            <ArcDrone.primary 
              key={order.missionId} 
              width='calc(50% - 4px)'
              drone={order.drone ?? {}}
              selected={order.missionId === selected?.missionId}
              onClick={() => onSelectOrder(order, OrderStatusEnum.ASSIGNED)} />
          ))}
        </OrdersWrapperView>
        
      </ToggleCollapseView>
    </ArcStack>
  );
};

export default AssignedSection;