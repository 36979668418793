import { EMPTY_VALUE, SETTING_UNITS_KEY } from "../config";
import { UnitType } from "../enums";
import { arcToFixed } from "./arc-to-fixed";

export const arcSpatium = (value?: number | null) => {

  const payload = Number(value);
  
  const type = localStorage.getItem(SETTING_UNITS_KEY);

  const request = isNaN(payload) ? EMPTY_VALUE : arcToFixed(payload);

  if (type === UnitType.SI) return request  + ' m';

  if (type === UnitType.IMPERIAL) return request + ' ft';

  return EMPTY_VALUE;
};