import React from 'react';
import { routesConfig } from '@src/core/routes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ChecklistFeature, DroneFeature, OrderFeature, ProfileFeature } from '@src/features';

const OperatorRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/orders/*' element={<OrderFeature />}/>
      <Route path='/drones/*' element={<DroneFeature />}/>
      <Route path='/profile/*' element={<ProfileFeature />} />
      <Route path='/checklist/*' element={<ChecklistFeature />} />
      <Route path='*' element={<Navigate to={routesConfig.orders}/>} />
    </Routes>
  );
};

export default OperatorRoutes;