import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ViewerPage } from './pages';

const ViewerFeature: React.FC = () => {
  return (
    <Routes>
      <Route index element={<ViewerPage />}/>
    </Routes>
  );
};

export default ViewerFeature;