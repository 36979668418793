import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@src/store';
import { IAssignDroneInitialState } from './types';

const AssignDroneReducer = (state: RootState): IAssignDroneInitialState => state.AssignDronePageReducer;
const AssignDroneState = createSelector([AssignDroneReducer], (state) => state);

export const selectMissionOrders = createSelector([AssignDroneState], (state) => state.missionOrders);
export const selectOrder = createSelector([AssignDroneState], (state) => state.selectedOrder);
export const selectDrone = createSelector([AssignDroneState], (state) => state.selectedDrone);
