import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArcDroneMarker, ArcOrderMarker, resetMapLocation, selectMapLocation, setMapLocation } from '@src/core/controllers';
import { getGeocode } from 'use-places-autocomplete';
import { DroneStatusEnum } from '@src/features/drone/entities';
import { useDroneProvider } from '@src/features/drone/provider';

const CreateOrderMap: React.FC = () => {
  const dispatch = useDispatch();
  const location = useSelector(selectMapLocation);
  const { drones } = useDroneProvider(DroneStatusEnum.IDLE);

  React.useEffect(() => {
    return () => { dispatch(resetMapLocation()); };
  }, []);

  const onDragDrop = async (e: google.maps.MapMouseEvent) => {
    const lat = e?.latLng?.lat();
    const lng = e?.latLng?.lng();

    if (!lat || !lng) return;

    const payload = { location: { lat, lng } };
    const addresses = await getGeocode(payload);
    const [{ formatted_address }] = addresses;

    dispatch(setMapLocation({
      latitude: lat,
      longitude: lng,
      address: formatted_address
    }));
  };

  const orderLat = Number(location?.latitude);
  const orderLng = Number(location?.longitude);
    
  return (
    <React.Fragment>
      

      {isNaN(orderLat) || isNaN(orderLng) 
        ? null 
        : <ArcOrderMarker.active 
          draggable
          onDragEnd={onDragDrop}
          position={{ lat: orderLat, lng: orderLng }} />
      }

      {drones.map((drone) => {
        const lat = Number(drone.latitude);
        const lng = Number(drone.longitude);

        if (isNaN(lat) || isNaN(lng)) return null;

        return (
          <ArcDroneMarker.idle
            key={drone.id} 
            position={{ lat, lng }} />
        );
      })}
        
    </React.Fragment>
  );
};

export default CreateOrderMap;