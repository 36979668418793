import React from 'react';
import { EnRouteCameraProps } from './types';
import { useDisclosure } from '@src/core/hooks';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ArcCircularProgress, ArcIconButton, ArcStack, ArcText } from '@src/core/ui';
import { useDroneCamera } from '../../hooks';
import EnRouteGoHomeAction from '../en-route-go-home-action';
import EnRouteWinchDownAction from '../en-route-winch-down-action';
import { useSelector } from 'react-redux';
import { selectManualControl } from '../../redux';
import EnRouteCameraControl from '../en-route-camera-control';

const EnRouteCamera: React.FC<EnRouteCameraProps> = (props) => {
  const { serialNumber } = props;

  const { open, toggle } = useDisclosure();
  const isManualControl = useSelector(selectManualControl);
  const { videoRef, isLoading, reconnect, isReconnecting } = useDroneCamera(serialNumber);

  const loading = isLoading || isReconnecting;

  return (
    <ArcStack 
      p={1}
      left={485}
      bottom={5}
      zIndex={100}
      borderRadius={1}
      position='absolute'
      bgcolor='background.default'
      height={open ? 'auto' : 200}
      width={open ? 'calc(100% - 490px)' : 400}>


      <ArcIconButton 
        disabled={isReconnecting}
        sx={{ 
          p: 1,
          top: 5,
          right: 55,
          width: 50, 
          height: 50, 
          zIndex: 100,
          position: 'absolute'
        }} 
        onClick={reconnect}> 
        <RefreshIcon /> 
      </ArcIconButton>

      <ArcIconButton 
        disabled={loading}
        sx={{ 
          p: 1,
          top: 5,
          right: 5,
          width: 50, 
          height: 50, 
          zIndex: 100,
          position: 'absolute'
        }} 
        onClick={toggle}> 
        <FullscreenIcon /> 
      </ArcIconButton>

      {isManualControl && open && <EnRouteCameraControl serialNumber={serialNumber} />}

      {loading && (<ArcStack 
        spacing={1} 
        width='100%'
        height='100%'
        position='absolute'
        top={0}
        left={0}
        alignItems='center'
        justifyContent='center'>
        <ArcCircularProgress color='ghost' />
        <ArcStack>
          <ArcText.h2 textAlign='center'> Connecting... </ArcText.h2>
          <ArcText.caption textAlign='center'> Please wait </ArcText.caption>
        </ArcStack>
      </ArcStack>
      )}
    
      <ArcStack 
        width='100%' 
        height='100%' 
        overflow='hidden' 
        alignItems='center' 
        justifyContent='center'>
        <video 
          autoPlay 
          playsInline 
          ref={videoRef}
          style={{
            width: '100%', 
            height: '100%', 
            objectFit: 'cover',
            objectPosition: 'center'
          }} />
      </ArcStack>


      {isManualControl && <ArcStack 
        spacing={1} 
        direction='row'
        position='absolute' 
        justifyContent='center' 
        bottom={20} 
        left={10} 
        right={10}>
        <EnRouteGoHomeAction serialNumber={serialNumber} />
        <EnRouteWinchDownAction serialNumber={serialNumber} />
      </ArcStack>}

    </ArcStack>
  );
};

export default EnRouteCamera;