import React from 'react';
import { FlightContext } from '.';

export const useFlightProvider = () => {
  const { 
    status,
    battery, 
    flightData, 
    velocityNed, 
    attitudeEuler, 
    distanceSensor, 
  } = React.useContext(FlightContext);

  return {
    status,
    battery,
    velocityNed,
    attitudeEuler,
    distanceSensor,

    flightData,
  };
};