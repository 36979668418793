import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { EnRouteHeaderProps } from './types';
import { ArcIconButton, ArcStack, ArcText } from '@src/core/ui';
import { EMPTY_STRING } from '@src/core/config';

const EnRouteHeader: React.FC<EnRouteHeaderProps> = (props) => {
  const { drone } = props;
  const navigate = useNavigate();

  return (
    <ArcStack 
      direction='row' 
      alignItems='center'
      justifyContent='start' 
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(-1)}>
      <ArcIconButton>
        <ArrowBackIosIcon sx={{ fontSize: 20 }}  /> 
      </ArcIconButton>
      <ArcText.h3 ml={1}> {drone?.name ?? EMPTY_STRING} </ArcText.h3> 
    </ArcStack>
  );
};

export default EnRouteHeader;