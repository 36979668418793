import React from 'react';
import { useDisclosure } from '@src/core/hooks';
import { ClickAwayListener } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ArcIconButton, ArcStack, ArcTableCell, ArcText, ArcTooltip } from '@src/core/ui';

import { CopyContentProps } from './types';

const CopyContent: React.FC<CopyContentProps> = (props) => {
  const { text } = props;
  const { open, show, hide } = useDisclosure();

  const onClipBoard = (): void => {
    navigator.clipboard.writeText(text);
    show();
  };

  return (
    <ArcTableCell sx={{ border: 0 }}>
      <ArcStack spacing={1} direction='row' alignItems='center'>
        <ArcIconButton onClick={onClipBoard}>
          <ClickAwayListener onClickAway={hide}>
            <ArcTooltip arrow 
              open={open} 
              title='Copied'
              slotProps={{ popper: { disablePortal: true } }}
              onClose={hide}
            >
              <ContentCopyIcon sx={{ fontSize: 18 }} />
            </ArcTooltip>
          </ClickAwayListener>
        </ArcIconButton>
        <ArcText.h6> {text} </ArcText.h6>
      </ArcStack>
    </ArcTableCell>
  );
};

export default CopyContent;