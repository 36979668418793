import React from 'react';
import { PopupWrapperView } from '../../components';
import { NewPopupProps } from './types';
import { EMPTY_STRING } from '@src/core/config';
import { ArcStack, ArcText } from '@src/core/ui';
import { AutoAssignButton, CancelNewOrderButton, UpdateNewOrderButton } from '../../actions';
import { OrderEntity } from '@src/features/order/entities';

const NewPopup: React.FC<NewPopupProps> = (props) => {
  const { order = {} as OrderEntity } = props;

  return (
    <PopupWrapperView title={`Order #${order.id ?? EMPTY_STRING}`}>
      <ArcStack my={2} spacing={2}>
        <Item label='Destination' value={order.deliveryLocation?.address} />
        <Item label='Method' value={order.deliveryMethod} />
        <Item label='Client Name' value={order.clientName} />
        <Item label='Content' value={order.packageContent} />
      </ArcStack>

      <ArcStack spacing={1}>
        <ArcStack spacing={1} direction='row'>
          <AutoAssignButton orderId={order.id} />
          <UpdateNewOrderButton orderId={order.id} />
        </ArcStack>
        <CancelNewOrderButton orderId={order.id} />
      </ArcStack>
    </PopupWrapperView>
  );
};


const Item: React.FC<{ label: string, value?: string }> = (props) => {
  const { label, value } = props;

  return (
    <ArcStack 
      spacing={2}
      direction='row'
      justifyContent='space-between'>
      <ArcText.button fontSize={10} width={100} whiteSpace='nowrap'> {label} </ArcText.button>
      <ArcText.button fontSize={10} color='text.secondary' textAlign='right'> {value ?? EMPTY_STRING} </ArcText.button>
    </ArcStack>
  );
};

export default NewPopup;