import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IMapControllerInitialState } from "./types";

const setMapZoom: CaseReducer<IMapControllerInitialState, PayloadAction<IMapControllerInitialState['zoom']>> = (state, action) => {
  state.zoom = action.payload;
};

const setMapType: CaseReducer<IMapControllerInitialState, PayloadAction<IMapControllerInitialState['type']>> = (state, action) => {
  state.type = action.payload;
};

const setMapCenter: CaseReducer<IMapControllerInitialState, PayloadAction<IMapControllerInitialState['center']>> = (state, action) => {
  state.center = action.payload;
};

const setMapLocation: CaseReducer<IMapControllerInitialState, PayloadAction<IMapControllerInitialState['location']>> = (state, action) => {
  state.location = action.payload;

  const lat = Number(action?.payload?.latitude);
  const lng = Number(action?.payload?.longitude);

  if (isNaN(lat) || isNaN(lng)) return;

  state.center = { lat, lng };
};

const resetMapLocation: CaseReducer<IMapControllerInitialState, PayloadAction> = (state) => {
  state.location = undefined;
};

export default { 
  setMapZoom,
  setMapType, 
  setMapCenter, 
  setMapLocation,
  resetMapLocation,
};