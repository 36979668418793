import React from 'react';
import { ArcButton, ArcDialog, ArcIconButton, ArcPasswordInput, ArcStack, ArcTooltip } from '@src/core/ui';
import LockResetIcon from '@mui/icons-material/LockReset';

import { ResetPasswordFormProps } from './types';
import { useForm } from 'react-hook-form';
import { IResetPasswordForm } from '@src/features/settings/entities';
import { EMPTY_VALUE, REQUIRED_VALIDATION_TEXT } from '@src/core/config';
import { useDisclosure } from '@src/core/hooks';
import { useResetUserPasswordMutation } from '@src/features/settings/redux';
import { toast } from 'react-toastify';

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const { user } = props;

  const { open, hide, show } = useDisclosure();
  const [resetUserPassword, { isLoading: isSubmitting }] = useResetUserPasswordMutation();

  const { register, handleSubmit, formState: { errors } } = useForm<IResetPasswordForm>({ 
    defaultValues: { 
      newPassword: EMPTY_VALUE, 
      newPassword2: EMPTY_VALUE, 
    }  
  });

  const onResetUserPassword = (data: IResetPasswordForm) => {
    resetUserPassword({ id: user.id, data }).unwrap().then(() => {
      toast.success('SET USER ROLE SUCCESS TEXT');
      hide();
    });
  };

  return (
    <React.Fragment>
      <ArcTooltip title='Reset password'>
        <ArcIconButton onClick={show}>
          <LockResetIcon />
        </ArcIconButton>
      </ArcTooltip>

      <ArcDialog 
        open={open} 
        onClose={hide} 
        title='Reset password'>
        <ArcStack 
          spacing={2} 
          component='form'
          onSubmit={handleSubmit(onResetUserPassword)}>

          <ArcPasswordInput 
            autoComplete='off'
            error={Boolean(errors.newPassword)} 
            helperText={errors.newPassword?.message}
            label='New password' 
            {...register('newPassword', { 
              required: REQUIRED_VALIDATION_TEXT
            })} />


          <ArcPasswordInput 
            autoComplete='off'
            error={Boolean(errors.newPassword2)} 
            helperText={errors.newPassword2?.message}
            label='Confirm new password' 
            {...register('newPassword2', { 
              required: REQUIRED_VALIDATION_TEXT 
            })} />

          <ArcButton.brand type='submit' isLoading={isSubmitting}> 
            Save 
          </ArcButton.brand>

        </ArcStack>
      </ArcDialog>
    </React.Fragment>
  );
};

export default ResetPasswordForm;