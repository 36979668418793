import React from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { ArcGoogleMapProps } from './types';
import {  ArcStack } from '@src/core/ui';
import { useDispatch, useSelector } from 'react-redux';
import { selectMapCenter, selectMapType, selectMapZoom } from '../../redux/selectors';
import { setMapLocation, setMapType, setMapZoom } from '../../redux/actions';
import { getGeocode } from 'use-places-autocomplete';
import { MapTypeStatus } from '../../entities';
import { FindMeButton, WeatherContent } from '../../widgets';

const ArcGoogleMap: React.FC<ArcGoogleMapProps> = (props) => {

  const { children } = props;

  const dispatch = useDispatch();

  const zoom = useSelector(selectMapZoom);
  const center = useSelector(selectMapCenter);
  const mapTypeId = useSelector(selectMapType);

  function onZoomChanged() {
    dispatch(setMapZoom(this.zoom as number));
  }
  function onMapTypeIdChanged() {
    dispatch(setMapType(this.mapTypeId));
  }

  const onDblClick = async (e: google.maps.MapMouseEvent) => {
    const lat = e?.latLng?.lat();
    const lng = e?.latLng?.lng();

    if (!lat || !lng) return;

    const payload = { location: { lat, lng } };
    const addresses = await getGeocode(payload);
    const [{ formatted_address }] = addresses;

    dispatch(setMapLocation({
      latitude: lat,
      longitude: lng,
      address: formatted_address
    }));
  };

  React.useEffect(() => {
    dispatch(setMapType(MapTypeStatus.HYBRID));
  }, []);


  return (
    <ArcStack width='100%' height='100%' position='relative'>
      <WeatherContent />
      <FindMeButton />

      <GoogleMap 
        zoom={zoom}
        center={center}
        mapTypeId={mapTypeId}
        onDblClick={onDblClick}
        onZoomChanged={onZoomChanged}
        onMapTypeIdChanged={onMapTypeIdChanged}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        options={{ fullscreenControl: false }}>

        {children}
        
      </GoogleMap>
    </ArcStack>
  );
};

export default ArcGoogleMap;