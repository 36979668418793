import { OrderEntity, OrderWayPointEntity, OrderWayPointTypeEnum } from "@src/features/order/entities";

export const arcConvertOrderToWp = (order: OrderEntity): OrderWayPointEntity => {
  return {
    orderId: order.id,
    speed: order.speed,
    altitude: order.altitude,
    tetherId: order.tetherId,
    type: OrderWayPointTypeEnum.ORDER,
    deliveryAltitude: order.deliveryLocation.altitude,
    latitude: Number(order.deliveryLocation.latitude),
    longitude: Number(order.deliveryLocation.longitude),
  };
};