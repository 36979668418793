import React from 'react';
import { Marker } from '@react-google-maps/api';
import defaultOrder from '@src/assets/order-icons/default.svg';
import activeOrder from '@src/assets/order-icons/active.svg';

import { ArcOrderMarkerProps, ArcOrderMarkerVariant } from './types';

const ArcOrderMarker: React.FC<ArcOrderMarkerProps> & ArcOrderMarkerVariant = (props) => {
  const {  ...rest } = props;
  return <Marker {...rest} />;
};

ArcOrderMarker.active = (props) => (
  <ArcOrderMarker 
    icon={{
      url: activeOrder,
      scaledSize: new window.google.maps.Size(50, 50),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(25, 40),
    }}  
    {...props}/>
);

ArcOrderMarker.default = (props) => (
  <ArcOrderMarker 
    icon={{
      url: defaultOrder,
      scaledSize: new window.google.maps.Size(50, 50),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(25, 40),
    }}  
    {...props}  />
);



export default ArcOrderMarker;