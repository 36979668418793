import React from 'react';
import { EnRouteGoHomeActionProps } from './types';
import { ArcButton } from '@src/core/ui';
import { ArcSocketContext } from '@src/core/socket';
import { EnRouteCommandEnum, EnRouteEventTypeEnum } from '../../entities';

const EnRouteGoHomeAction: React.FC<EnRouteGoHomeActionProps> = (props) => {
  const { serialNumber } = props;
  const { arcSocket } = React.useContext(ArcSocketContext);

  const goHome = () => {
    const socket = arcSocket();
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          type: EnRouteEventTypeEnum.COMMAND,
          command: EnRouteCommandEnum.DELIVERY_COMPLETED,
          serialNumber,
        })
      );
    }
  };

  return (
    <ArcButton.brand 
      sx={{ width: 200 }} 
      onClick={goHome}> 
        GO HOME 
    </ArcButton.brand>
  );
};

export default EnRouteGoHomeAction;