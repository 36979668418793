import { ArcButton } from '@src/core/ui';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UpdateNewOrderButtonProps } from './types';

const UpdateNewOrderButton: React.FC<UpdateNewOrderButtonProps> = (props) => {
  const { orderId } = props;

  const navigate = useNavigate();
  return (
    <ArcButton.brand
      fullWidth 
      onClick={() => navigate(`update/${orderId}`)}> 
        Update 
    </ArcButton.brand>
  );
};

export default UpdateNewOrderButton;