export const EMPTY_STRING = '---';
export const EMPTY_VALUE = '';
export const ZERO = 0;

export const REQUIRED_VALIDATION_TEXT = 'This field is required';

export const BASE_SOCKET_URL = 'wss://api.asend.ai/websocket';
export const WEATHER_SOCKET_URL = 'wss://api.asend.ai/weather';
export const RECONNECT_TIME = 3000;

export const SETTING_UNITS_KEY = 'units';
