import React from 'react';
import AppFeature from './root';
import { AuthFeature } from './features';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN_KEY } from './core/config';
import { LoadingStatus } from './core/enums';
import { ArcGlobalLoading } from './core/widgets';
import { routesConfig } from './core/routes';

const Main: React.FC = () => {
  const navigate = useNavigate();
  const [initialized, setInitialized] = React.useState(LoadingStatus.NEVER);

  const initApp = () => {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      setInitialized(LoadingStatus.LOADED);
      navigate(routesConfig.app);
      return;
    }
    navigate(routesConfig.auth);
    setInitialized(LoadingStatus.LOADED);
  };

  React.useEffect(() => { initApp(); }, []);

  if (initialized === LoadingStatus.NEVER) {
    return <ArcGlobalLoading />;
  }
  
  return (
    <Routes>				
      <Route path='/auth/*' element={<AuthFeature />} />
      <Route path='/app/*' element={<AppFeature /> }/> 
    </Routes>
  );
};


export default Main;
