import React from 'react';
import { toast } from 'react-toastify';
import { ArcButton } from '@src/core/ui';

import { RequestAuthButtonProps } from './types';
import { useRequestAuthMutation } from '@src/features/order/redux';
import { useDispatch } from 'react-redux';
import { setSelectedOrder } from '../../redux';

const RequestAuthButton: React.FC<RequestAuthButtonProps> = (props) => {
  const { missionId } = props; 
  const dispatch = useDispatch();
  const [requestAuth, { isLoading }] = useRequestAuthMutation();

  const onRequestAuth = () => {
    requestAuth(missionId).unwrap().then((res) => {
      if (res.statusCode === 200) {
        dispatch(setSelectedOrder(null));
        toast.success('Authorization request successful!');
        return;
      }
      toast.error(res.message ?? 'Something went wrong');
    });
  };

  return (
    <ArcButton.brand 
      fullWidth 
      isLoading={isLoading} 
      onClick={onRequestAuth}> 
      Request
    </ArcButton.brand>
  );
};

export default RequestAuthButton;