import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { 
  CreateOrderMap, 
  CreateOrderSidebar, 
  UpdateOrderSidebar, 
  UpdateOrderMap, 
  OrdersSidebar, 
  OrdersMap, 
  AssignDroneSidebar,
  AssignDroneMap, 
  ModifyRouteSidebar,
  ModifyRouteMap,
  EnRouteSidebar,
  EnRouteMap
} from './pages';
import { ArcStack } from '@src/core/ui';
import { ArcBaseContent, ArcBaseSidebar } from '@src/core/components';
import { ArcGoogleMap } from '@src/core/controllers';
import { FlightProvider } from './provider';

const OrderFeature: React.FC = () => {
  return (
    <FlightProvider>
      <ArcStack direction='row'>
        <ArcBaseSidebar>
          <Routes>
            <Route index element={<OrdersSidebar />} />
            <Route path='/create' element={<CreateOrderSidebar />} />
            <Route path='/update/:orderId' element={<UpdateOrderSidebar />} />
            <Route path='/assign-drone' element={<AssignDroneSidebar />} />
            <Route path='/modify-route/:missionId' element={<ModifyRouteSidebar />} />
            <Route path='/en-route/:missionId' element={<EnRouteSidebar />} />
            <Route path='/en-route-viewer/:missionId' element={<EnRouteSidebar viewer />} />
          </Routes>
        </ArcBaseSidebar>
        <ArcBaseContent>
          <ArcGoogleMap>
            <Routes>
              <Route index element={<OrdersMap />} />
              <Route path='/create' element={<CreateOrderMap />} />
              <Route path='/update/:orderId' element={<UpdateOrderMap />} />
              <Route path='/assign-drone' element={<AssignDroneMap />} />
              <Route path='/modify-route/:missionId' element={<ModifyRouteMap />}/>
              <Route path='/en-route/:missionId' element={<EnRouteMap />} />
              <Route path='/en-route-viewer/:missionId' element={<EnRouteMap />} />
            </Routes>
          </ArcGoogleMap>
        </ArcBaseContent>
      </ArcStack>
    </FlightProvider>
  );
};

export default OrderFeature;