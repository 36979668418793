import { EMPTY_STRING } from "../config";

export const arcToFixed = (value?: number) => {

  const target = Number(value);

  if (isNaN(target)) return EMPTY_STRING;

  if (target % 1 !== 0) return target.toFixed(2);

  return target.toString();
};