import React from 'react';
import { toast } from 'react-toastify';
import { ArcSkeleton, ArcStack } from '@src/core/ui';
import { UpdateOrderPageProps } from './types';
import { useNavigate, useParams } from 'react-router-dom';
import { setMapLocation } from '@src/core/controllers';
import { IOrderForm, OrderFormView } from '../../components';
import { useDispatch } from 'react-redux';
import { OrderEntity } from '../../entities';
import { useGetOrderQuery, useUpdateOrderMutation } from '../../redux';
import { arcFtToMeter, arcMphToMs } from '@src/core/extensions';

const UpdateOrderPage: React.FC<UpdateOrderPageProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [ updateOrder, { isLoading: isUpdateLoading } ] = useUpdateOrderMutation();
  const { data = {} as OrderEntity, isLoading } = useGetOrderQuery(Number(orderId));

  const onUpdateOrder = (form: IOrderForm) => {
    const { 
      latitude, 
      longitude, 
      address, 
      deliveryAltitude, 
      ...rest 
    } = form;
    
    updateOrder({ 
      ...rest,
      id: Number(orderId),
      deliveryLocation: {
        address,
        latitude,
        longitude,
        altitude: deliveryAltitude,
      },
    }).unwrap().then(() => {
      navigate(-1);
      toast.success('Your order has been updated successfully!');
    });
  };

  React.useEffect(() => {
    if (Object.keys(data).length > 0) {
      dispatch(setMapLocation({
        address: data.deliveryLocation.address,
        latitude: Number(data.deliveryLocation.latitude),
        longitude: Number(data.deliveryLocation.longitude),
      }));
    }
  }, [data]);

  if (isLoading) {
    return <FormLoading />; 
  }

  return (
    <OrderFormView 
      isLoading={isUpdateLoading}
      submitText='Update Order'
      defaultValues={{ 
        speed: data.speed,
        altitude: data.altitude,
        clientName: data.clientName,
        deliveryMethod: data.deliveryMethod,
        packageContent: data.packageContent,
        address: data.deliveryLocation.address, 
        latitude: data.deliveryLocation.latitude, 
        longitude: data.deliveryLocation.longitude,
        deliveryAltitude: data.deliveryLocation.altitude,
      }} 
      onSubmit={onUpdateOrder} />     
  );
};

const FormLoading: React.FC = () => (
  <ArcStack spacing={3}>
    <ArcStack spacing={2}>
      <ArcSkeleton width={120} height={30} />
      <ArcSkeleton height={50} />
      <ArcSkeleton height={50} />
      <ArcSkeleton height={50} />
    </ArcStack>

    <ArcStack spacing={2}>
      <ArcSkeleton width={120} height={30} />
      <ArcStack direction='row' spacing={2}>
        <ArcSkeleton width='100%' height={50} />
        <ArcSkeleton width='100%' height={50} />
      </ArcStack>
      <ArcSkeleton height={50} />
    </ArcStack>
  </ArcStack>
);

export default UpdateOrderPage;