import React from 'react';
import { Collapse } from '@mui/material';
import { ArcCollapseProps } from './types';

const ArcCollapse: React.FC<ArcCollapseProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <Collapse {...rest}>
      {children}
    </Collapse>
  );
};

export default ArcCollapse;