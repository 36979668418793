import React from 'react';
import { ReactComponent as CloseIcon } from '@src/assets/icons/close.svg';
import { ArcIconButton, ArcStack, ArcText } from '@src/core/ui';
import { PopupWrapperViewProps } from './types';
import { useDispatch } from 'react-redux';
import { setSelectedOrder } from '../../redux';

const PopupWrapperView: React.FC<PopupWrapperViewProps> = (props) => {
  const { title, error, children } = props;

  const dispatch = useDispatch();
  const onRemoveOrder = () => dispatch(setSelectedOrder(null));

  return (
    <ArcStack 
      p={2} 
      width='100%' 
      maxWidth={400}
      minWidth={400}
      borderRadius={1}
      position='absolute'
      left={485}
      top={45}
      zIndex={100}
      bgcolor={error ? 'rose.main' : 'background.paper'}>

      <ArcStack 
        spacing={2}
        direction='row' 
        alignItems='center' 
        justifyContent='space-between'>
        <ArcText.h5 fontWeight={500}> {title} </ArcText.h5>
        <ArcIconButton onClick={onRemoveOrder}> <CloseIcon /> </ArcIconButton>
      </ArcStack>

      {children}
    </ArcStack>
  );
};

export default PopupWrapperView;