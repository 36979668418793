import { DronePageReducer } from "./pages/drones-page/redux";
import { droneFeatureApi } from "./redux";

export const DroneFeatureMiddlewares = [
  droneFeatureApi.middleware,
];

export const DroneFeatureReducers = {
  DronePageReducer,
  [droneFeatureApi.reducerPath]: droneFeatureApi.reducer,
};
