import React from 'react';
import NewSection from './new/section';
import AssignedSection from './assigned/section';
import TakeOffSection from './take-off/section';
import EnRouteSection from './en-route/section';
import CompletedSection from './completed/section';
import CancelledSection from './cancelled/section';
import ErrorSection from './error/section';
import { ArcSkeleton, ArcStack } from '@src/core/ui';
import { useOrderProvider } from '@src/features/order/provider';
import { OrderEntity, OrderMissionEntity, OrderStatusEnum } from '@src/features/order/entities';
import { useDispatch, useSelector } from 'react-redux';
import { isMissionOrder, isNewOrder } from '@src/features/order/utils';
import { setMapLocation } from '@src/core/controllers';
import { selectOrder, setSelectedOrder } from '../redux';

const Sections: React.FC = () => {
  const dispatch = useDispatch();
  const { orders, isLoading } = useOrderProvider();
  const selectedOrder = useSelector(selectOrder);

  const onSelectOrder = (order: OrderEntity | OrderMissionEntity, status: OrderStatusEnum) => {
    if (isNewOrder(order)) {
      dispatch(setMapLocation({
        latitude: Number(order.deliveryLocation.latitude),
        longitude: Number(order.deliveryLocation.longitude),
      }));

      dispatch(setSelectedOrder({ ...order, status }));
    }
  
    if (isMissionOrder(order)) {
      dispatch(setMapLocation({
        latitude: Number(order.drone.latitude),
        longitude: Number(order.drone.longitude),
      }));

      dispatch(setSelectedOrder({ ...order, status }));
    }
  };


  if (isLoading) {
    return (
      <ArcStack spacing={1} mt={1}>
        <ArcSkeleton height={40} />
        <ArcSkeleton height={40} />
        <ArcSkeleton height={40} />
        <ArcSkeleton height={40} />
        <ArcSkeleton height={40} />
        <ArcSkeleton height={40} />
      </ArcStack>
    );
  }


  return (
    <ArcStack my={2} spacing={1}>

      <NewSection 
        onSelectOrder={onSelectOrder}
        orders={orders[OrderStatusEnum.NEW] ?? []} 
        selected={selectedOrder as OrderEntity | null} 
      />

      <AssignedSection 
        onSelectOrder={onSelectOrder}
        orders={orders[OrderStatusEnum.ASSIGNED] ?? []}
        selected={selectedOrder as OrderMissionEntity | null}
      />

      <TakeOffSection 
        onSelectOrder={onSelectOrder}
        orders={orders[OrderStatusEnum.READY_FOR_TAKE_OFF] ?? []} 
        selected={selectedOrder as OrderMissionEntity | null} 
      />

      <EnRouteSection 
        orders={orders[OrderStatusEnum.EN_ROUTE] ?? []}
      />

      <CompletedSection 
        onSelectOrder={onSelectOrder}
        orders={orders[OrderStatusEnum.COMPLETED] ?? []} 
        selected={selectedOrder as OrderMissionEntity | null} 
      />

      <CancelledSection 
        onSelectOrder={onSelectOrder}
        orders={orders[OrderStatusEnum.CANCELLED] ?? []}  
        selected={selectedOrder as OrderMissionEntity | null}  
      />

      <ErrorSection 
        onSelectOrder={onSelectOrder}
        orders={orders[OrderStatusEnum.ERROR] ?? []}
        selected={selectedOrder as OrderMissionEntity | null} 
      />
      
    </ArcStack>  
  );
};

export default Sections;