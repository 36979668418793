import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import ChecklistIcon from '@mui/icons-material/Checklist';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import LocalSeeIcon from '@mui/icons-material/LocalSee';
import { ReactComponent as DroneIcon } from '@src/assets/icons/drones.svg';
import { ReactComponent as OrdersIcon } from '@src/assets/icons/orders.svg';
import { NavLinkEntity } from '../entities';
import { UserRoleTypeEnum } from '@src/features/profile/entites';
import { routesConfig } from '@src/core/routes';

export const rootLinks: NavLinkEntity[] = [
  {
    id: 1,
    name: 'Orders',
    path: routesConfig.orders,
    exact: [
      routesConfig.orders, 
      routesConfig.createOrder, 
      routesConfig.assignDrone, 
    ],
    roles: [UserRoleTypeEnum.ADMIN, UserRoleTypeEnum.OPERATOR],
    icon: <OrdersIcon width={18} />,
    activeIcon: <OrdersIcon width={18} stroke='#9E9EF3' />,
  },
  {
    id: 2,
    name: 'Drones',
    path: routesConfig.drones,
    exact: [routesConfig.drones, routesConfig.addDrone],
    roles: [UserRoleTypeEnum.ADMIN, UserRoleTypeEnum.OPERATOR],
    icon: <DroneIcon width={18} />,
    activeIcon: <DroneIcon width={18} stroke='#9E9EF3' />,
  },
  {
    id: 3,
    name: 'Check',
    path: routesConfig.checklist,
    roles: [UserRoleTypeEnum.ADMIN, UserRoleTypeEnum.OPERATOR],
    exact: [routesConfig.checklist],
    icon: <ChecklistIcon sx={{ color: 'text.secondary', fontSize: 18 }} />,
    activeIcon: <ChecklistIcon sx={{ color: 'brand.main', fontSize: 18 }} />,
  },
  {
    id: 4,
    name: 'Settings',
    path: routesConfig.settings,
    roles: [UserRoleTypeEnum.ADMIN],
    exact: [routesConfig.settings],
    icon: <SettingsIcon sx={{ color: 'text.secondary', fontSize: 18 }} />,
    activeIcon: <SettingsIcon sx={{ color: 'brand.main', fontSize: 18 }} />,
  },
  {
    id: 5,
    name: 'Api',
    path: routesConfig.integration,
    roles: [UserRoleTypeEnum.ADMIN],
    exact: [routesConfig.integration],
    icon: <AspectRatioIcon sx={{ color: 'text.secondary', fontSize: 18 }} />,
    activeIcon: <AspectRatioIcon sx={{ color: 'brand.main', fontSize: 18 }} />,
  },
  {
    id: 6,
    name: 'Viewer',
    path: routesConfig.viewer,
    roles: [UserRoleTypeEnum.ADMIN, UserRoleTypeEnum.VIEWER],
    exact: [routesConfig.viewer],
    icon: <LocalSeeIcon sx={{ color: 'text.secondary', fontSize: 18 }} />,
    activeIcon: <LocalSeeIcon sx={{ color: 'brand.main', fontSize: 18 }} />,
  }
];