import React from "react";
import { ArcDroneMarker, ArcOrderMarker, ArcPolyline } from "@src/core/controllers";
import { TakeOffMapProps } from "./types";
import { arcMissionOrderPath } from "@src/core/extensions";

const TakeOffMap: React.FC<TakeOffMapProps> = (props) => {
  const { order } = props;

  return (
    <React.Fragment>
      <ArcPolyline.future path={arcMissionOrderPath(order)} />

      {order.orders.map((o) => {
        const lat = Number(o.deliveryLocation.latitude);
        const lng = Number(o.deliveryLocation.longitude);
            
        if (isNaN(lat) || isNaN(lng)) return null;
            
        return <ArcOrderMarker.active key={o.id} position={{ lat, lng }}/>;
      })}

      <ArcDroneMarker.idle 
        position={{ 
          lat: Number(order.drone.latitude), 
          lng: Number(order.drone.longitude) 
        }} />
      
    </React.Fragment>
  );
};

export default TakeOffMap;