import { combineReducers } from "redux";
import { ControllerReducers } from "@src/core/controllers/store";
import { OrderFeatureReducers } from "@src/features/order/store";
import { IntegrationFeatureReducers } from "@src/features/integration/store";
import { ProfileFeatureReducers } from "@src/features/profile/store";
import { DroneFeatureReducers } from "@src/features/drone/store";
import { SettingsFeatureReducers } from "@src/features/settings/store";
import { AuthFeatureReducers } from "@src/features/auth/store";

export const reducer = combineReducers({
  ...AuthFeatureReducers,
  ...ControllerReducers,
  ...OrderFeatureReducers,
  ...DroneFeatureReducers,
  ...ProfileFeatureReducers,
  ...SettingsFeatureReducers,
  ...IntegrationFeatureReducers,
});