import React from 'react';
import { AutoAssignButtonProps } from './types';
import { ArcButton } from '@src/core/ui';
import { useAutoAssignMutation } from '@src/features/order/redux';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setSelectedOrder } from '../../redux';

const AutoAssignButton: React.FC<AutoAssignButtonProps> = (props) => {
  const { orderId } = props;

  const dispatch = useDispatch();
  const [autoAssign, { isLoading }] = useAutoAssignMutation();

  const onAutoAssign = () => {
    autoAssign(orderId).unwrap().then(() => {
      dispatch(setSelectedOrder(null));
      toast.success('Auto Assign Success Text');
    });
  };

  return (
    <ArcButton.brand 
      fullWidth 
      isLoading={isLoading} 
      onClick={onAutoAssign}> 
      Auto Assign
    </ArcButton.brand>
  );
};

export default AutoAssignButton;