import { RootState } from "@src/store";
import { IModifyRouteInitialState } from "./types";
import { createSelector } from "@reduxjs/toolkit";

const ModifyRouteReducer = (state: RootState): IModifyRouteInitialState => state.ModifyRouteReducer;
const ModifyRouteState = createSelector([ModifyRouteReducer], (state) => state);

export const selectDrone = createSelector([ModifyRouteState], (state) => state.selectedDrone);
export const selectOrder = createSelector([ModifyRouteState], (state) => state.selectedOrder);
export const selectWayPoints = createSelector([ModifyRouteState], (state) => state.wayPoints);
export const selectMissionOrders = createSelector([ModifyRouteState], (state) => state.missionOrders);
