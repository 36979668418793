export const iceServers = [
  {
    username: "513eeb2c538a6f4fd4b099e9",
    credential: "c2HfwZhBn1dHA+JA",
    urls: "turn:global.relay.metered.ca:443",
  },
  {
    username: "513eeb2c538a6f4fd4b099e9",
    credential: "c2HfwZhBn1dHA+JA",
    urls: "turn:global.relay.metered.ca:80",
  },
  {
    username: "513eeb2c538a6f4fd4b099e9",
    credential: "c2HfwZhBn1dHA+JA",
    urls: "turn:global.relay.metered.ca:80?transport=tcp",
  },
  {
    username: "513eeb2c538a6f4fd4b099e9",
    credential: "c2HfwZhBn1dHA+JA",
    urls: "turns:global.relay.metered.ca:443?transport=tcp",
  },
  { 
    urls: 'stun:stun.relay.metered.ca:80',
  },
  { 
    urls: 'stun:stun.l.google.com:19302',
  }, 
  { urls: 
      'stun:stun.services.mozilla.com' 
  },
];