import React from 'react';
import { ArcBatteryLife, ArcStack, ArcText } from '@src/core/ui';
import { ArcDroneProps, ArcDroneVariantProps } from './types';
import { EMPTY_STRING } from '@src/core/config';

const ArcDrone: React.FC<ArcDroneProps> & ArcDroneVariantProps = (props) => {
  const { drone, ...rest } = props;
  return (
    <ArcStack 
      spacing={1} 
      border='0.5px solid' 
      {...rest}>
      <ArcStack 
        spacing={1} 
        direction='row' 
        alignItems='center'
        justifyContent='space-between'>
        <ArcText.h6 maxLines={1}> {drone.name ?? EMPTY_STRING} </ArcText.h6>
        <ArcText.caption fontSize={12}  whiteSpace='nowrap'> {drone.serialNumber ?? EMPTY_STRING} </ArcText.caption>
      </ArcStack>
      <ArcStack 
        spacing={1} 
        direction='row' 
        alignItems='center' 
        justifyContent='space-between'>
        <ArcText.button color='text.secondary'> {drone.status ?? EMPTY_STRING} </ArcText.button>
        <ArcBatteryLife percent={drone.batteryHealth} />
      </ArcStack>
    </ArcStack>
  );
};

ArcDrone.primary = ({ selected, ...rest }) => (
  <ArcDrone 
    p='12px' 
    borderRadius={1}
    bgcolor='background.paper'
    borderColor={selected ? 'brand.main' : 'background.paper' }
    sx={{ cursor: 'pointer' }} 
    {...rest} />
);

ArcDrone.error = ({ selected, ...rest }) => (
  <ArcDrone 
    p='12px' 
    borderRadius={1}
    bgcolor='rose.main' 
    borderColor={selected ? 'error.main' : 'rose.main'}
    sx={{ cursor: 'pointer' }} 
    {...rest} />
);

export default ArcDrone;