import React from 'react';
import { Autocomplete } from '@mui/material';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { ArcOutlinedInput } from '@src/core/ui';
import { ArcGoogleSearchProps, IArcGoogleSearchOption } from './types';
import { EMPTY_VALUE } from '@src/core/config';

const ArcGoogleSearch: React.FC<ArcGoogleSearchProps> = (props) => {
  const { label, error, helperText, initValue, onSelect } = props;
  const { ready, suggestions: { status, data }, setValue, value, clearSuggestions } = usePlacesAutocomplete({
    defaultValue: initValue ?? EMPTY_VALUE,
  });

  const onInputChange = (_: React.ChangeEvent<{}>, value: string) => setValue(value);

  const fetchGeocode = async (address: string) => {
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      onSelect(lat, lng, address);
    } catch (error) {
      console.error(`[fetchGeocode]: ${error}`);
    }
  }; 

  const onInputSelect = async (option: IArcGoogleSearchOption | null | string) => {
    if (!option) return;

    const address = typeof option === 'string' ? option : option.value;

    setValue(address);
    clearSuggestions();

    await fetchGeocode(address);
  };

  const options = status === 'OK' ? data.map((s) => ({
    label: s.description,
    value: s.description,
  })) : [];

  return (
    <Autocomplete 
      freeSolo
      fullWidth
      size='small'
      disabled={!ready}
      options={options} 
      value={initValue}
      inputValue={value}
      onInputChange={onInputChange}
      onChange={(_, option) => onInputSelect(option)}
      renderInput={(params) => (
        <ArcOutlinedInput 
          {...params}
          fullWidth 
          label={label} 
          error={error} 
          helperText={helperText} /> 
      )} />
  );
};

export default ArcGoogleSearch;