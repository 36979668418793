import React from 'react';
import { ArcButton, ArcStack } from '@src/core/ui';
import { OrdersWrapperView, ToggleCollapseView } from '../../components';
import { NewSectionProps } from './types';
import { ArcOrder } from '@src/core/components';
import { useNavigate } from 'react-router-dom';
import { OrderStatusEnum } from '@src/features/order/entities';

const NewSection: React.FC<NewSectionProps> = (props) => {
  const { orders, selected, onSelectOrder } = props;

  const navigate = useNavigate();
  const redirectToAssignDrone = () => navigate('assign-drone');

  return (
    <ArcStack>
      <ToggleCollapseView 
        count={orders.length}
        status={OrderStatusEnum.NEW}>
        <ArcStack py={2} spacing={2}>
          <ArcButton.brand 
            size='large' 
            onClick={redirectToAssignDrone}> 
            Assign Drone 
          </ArcButton.brand>
          <OrdersWrapperView>
            {orders.map((order) => (
              <ArcOrder.primary 
                key={order.id} 
                order={order}
                width='calc(50% - 4px)'
                selected={order.id === selected?.id}
                onClick={() => onSelectOrder(order, OrderStatusEnum.NEW)} />
            ))}
          </OrdersWrapperView>
        </ArcStack>
      </ToggleCollapseView>
    </ArcStack>
  );
};

export default NewSection;