import { IOrderForm } from "./types";

export const DEFAULT_VALUES: IOrderForm = {
  address: '',
  altitude: '130',
  clientName: '',
  deliveryMethod: 'TETHER',
  packageContent: '',
  deliveryAltitude: '80',
  speed: '40',
  latitude: '',
  longitude: '',
};

export const methods = ['PLATFORM', 'TETHER'];