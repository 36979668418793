import React from 'react';
import { ArcButton, ArcList, ArcListItem, ArcStack, ArcText } from '@src/core/ui';
import { TakeOffConfirmViewProps } from './types';

const TakeOffConfirmView: React.FC<TakeOffConfirmViewProps> = (props) => {
  const { show } = props;

  return (
    <ArcStack>
      <ArcText.caption 
        fontSize={12}
        color='text.primary'> 
        Preflight Checklist: 
      </ArcText.caption>
      <ArcList dense>
        <ArcListItem> 
          <ArcText.caption 
            color='text.primary'> 
            Payload is loaded 
          </ArcText.caption>
        </ArcListItem>
        <ArcListItem> 
          <ArcText.caption 
            color='text.primary'>  
            Payload is secured 
          </ArcText.caption>
        </ArcListItem>
        <ArcListItem> 
          <ArcText.caption 
            color='text.primary'>  
            Flight is authorized  
          </ArcText.caption>
        </ArcListItem>
        <ArcListItem> 
          <ArcText.caption 
            color='text.primary'>  
            Propellers are secure  
          </ArcText.caption>
        </ArcListItem>
        <ArcListItem> 
          <ArcText.caption 
            color='text.primary'>  
            Nothing is obstructing the drone’s path within 10 ft radius area  
          </ArcText.caption>
        </ArcListItem>
        <ArcListItem> 
          <ArcText.caption 
            color='text.primary'>  
            Stand at least 10 ft away from the drone  
          </ArcText.caption>
        </ArcListItem>
      </ArcList>
      <ArcButton.brand onClick={show}> Confirm </ArcButton.brand> 
    </ArcStack>
  );
};

export default TakeOffConfirmView;