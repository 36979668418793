import React from 'react';
import { CreateJwtKeyProps, ICreateJwtKeyForm } from './types';
import { useForm } from 'react-hook-form';
import { ArcButton, ArcDialog, ArcOutlinedInput, ArcStack } from '@src/core/ui';
import { REQUIRED_VALIDATION_TEXT } from '@src/core/config';

const CreateJwtKey: React.FC<CreateJwtKeyProps> = (props) => {
  const { open, onClose, onSubmit, isSubmitting } = props;
  const { register, handleSubmit, formState: { errors } } = useForm<ICreateJwtKeyForm>();

  return (
    <ArcDialog 
      title='Create access key' 
      open={open} 
      onClose={onClose}>
      <ArcStack 
        spacing={2}
        component='form' 
        alignItems='flex-end'
        onSubmit={handleSubmit(onSubmit)}>
        <ArcOutlinedInput 
          fullWidth
          label='Name' 
          autoComplete='off'
          error={Boolean(errors.name)} 
          helperText={errors.name?.message}
          {...register('name', { 
            required: REQUIRED_VALIDATION_TEXT,
          })} />

        <ArcButton.brand 
          size='large' 
          type='submit' 
          isLoading={isSubmitting}> 
          Create 
        </ArcButton.brand>

      </ArcStack>
    </ArcDialog>
  );
};

export default CreateJwtKey;
