import React from 'react';
import { ArcOutlinedSelectProps } from './types';
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';

const ArcOutlinedInput: React.FC<ArcOutlinedSelectProps> = (props) => {
  const { labelProps, helperText,  ...rest } = props;

  return (
    <FormControl color='brand' size='small' error={rest.error}>
      <InputLabel {...labelProps}> {rest.label} </InputLabel>
      <Select {...rest} />
      <FormHelperText error={rest.error}> {helperText} </FormHelperText>
    </FormControl>
  );
};

export default ArcOutlinedInput;