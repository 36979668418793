import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "@src/service";
import { IGetDroneHistoryResponse, IGetDronesResponse, IRegisterDroneRequest, IRegisterDroneResponse, IResetPartDroneRequest } from "./types";

export const droneFeatureApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'droneFeatureApi',
  tagTypes: ['Drones', 'DroneHistory'],
  endpoints: (builder) => ({
    getDrones: builder.query<IGetDronesResponse, void>({
      query: () => ({
        url: '/api/v1/drone',
        method: 'GET',
      }),
      providesTags: ['Drones'],
    }),
    getDroneHistory: builder.query<IGetDroneHistoryResponse, number>({
      query: (droneId) => ({
        url: `/api/v1/drone/${droneId}/orders`,
        method: 'GET',
      }),
      providesTags: ['DroneHistory'],
    }),
    deleteDrone: builder.mutation<void, number>({
      query: (droneId) => ({
        url: `/api/v1/drone/archive/${droneId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Drones'],
    }),
    registerDrone: builder.mutation<IRegisterDroneResponse, IRegisterDroneRequest>({
      query: (data) => ({
        url: `/api/v1/drone/activate`,
        method: 'PUT',
        data
      }),
      invalidatesTags: ['Drones'],
    }),
    resetPartDrone: builder.mutation<void, IResetPartDroneRequest>({
      query: ({ id, part }) => ({
        url: `/api/v1/drone/${id}/${part}/reset`,
        method: 'PUT',
      }),
      invalidatesTags: ['DroneHistory'],
    })
  })
});

export const { 
  useGetDronesQuery, 
  useGetDroneHistoryQuery, 

  useDeleteDroneMutation,
  useRegisterDroneMutation,
  useResetPartDroneMutation,
} = droneFeatureApi;