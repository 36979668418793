import React from 'react';
import { useSelector } from 'react-redux';
import NewPopup from './new/popup';
import AssignedPopup from './assigned/popup';
import TakeOffPopup from './take-off/popup';
import CompletedPopup from './completed/popup';
import CancelledPopup from './cancelled/popup';
import ErrorPopup from './error/popup';
import { OrderEntity, OrderMissionEntity, OrderStatusEnum } from '@src/features/order/entities';
import { selectOrder } from '../redux';

const Popups: React.FC = () => {

  const order = useSelector(selectOrder);

  if (!order) return null;

  if (order.status === OrderStatusEnum.NEW) {
    return <NewPopup order={order as OrderEntity} />;
  }

  if (order.status === OrderStatusEnum.ASSIGNED) {
    return <AssignedPopup order={order as OrderMissionEntity} />;
  }

  if (order.status === OrderStatusEnum.READY_FOR_TAKE_OFF) {
    return <TakeOffPopup order={order as OrderMissionEntity} />;
  }

  if (order.status === OrderStatusEnum.COMPLETED) {
    return <CompletedPopup order={order as OrderMissionEntity} />;
  }

  if (order.status === OrderStatusEnum.CANCELLED) {
    return <CancelledPopup order={order as OrderMissionEntity} />;
  }

  if (order.status === OrderStatusEnum.ERROR) {
    return <ErrorPopup order={order as OrderMissionEntity} />;
  }

  return null;
};



export default Popups;