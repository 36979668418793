import React from 'react';
import { ArcStack } from '@src/core/ui';
import { RootLayoutProps } from './types';
import { Header, Sidebar } from './components';
import { HEADER_HEIGHT } from './config/const';

const RootLayout: React.FC<RootLayoutProps> = (props) => {
  const { children } = props;

  return (
    <ArcStack>
      <Header />
      <ArcStack direction='row'>
        <Sidebar />
        <ArcStack 
          pl={10}
          width='100%'
          bgcolor='background.default'
          minHeight={`calc(100vh - ${HEADER_HEIGHT})`}>

          {children}
          
        </ArcStack>
      </ArcStack>
    </ArcStack>
  );
};

export default RootLayout;