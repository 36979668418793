import React from 'react';
import { MyProfile, UpdateHubPage } from './pages';
import { Route, Routes } from 'react-router-dom';

const ProfileFeature: React.FC = () => {
  return (
    <Routes>
      <Route index element={<MyProfile />}/>
      <Route path='/update-hub' element={<UpdateHubPage />}/>
    </Routes>
  );
};

export default ProfileFeature;