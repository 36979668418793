import React from 'react';
import Maps from './status/maps';
import { ArcDroneMarker } from '@src/core/controllers';
import { useDroneProvider } from '@src/features/drone/provider';
import { DroneStatusEnum } from '@src/features/drone/entities';

const OrdersMap: React.FC = () => {
  const { drones } = useDroneProvider(DroneStatusEnum.IDLE);

  return (
    <React.Fragment>
      <Maps />
      {drones.map((drone) => (
        <ArcDroneMarker.idle
          key={drone.id} 
          position={{ 
            lat: Number(drone.latitude), 
            lng: Number(drone.longitude), 
          }} />
      ))}
    </React.Fragment>
  );
};

export default OrdersMap;