import React from 'react';
import { ArcButton, ArcStack, ArcTabPanel } from '@src/core/ui';
import { SectionWrapper, DroneHeader, DroneList, PanelWrapper } from './components';
import { ArcBaseContent, ArcBaseSidebar } from '@src/core/components';
import { MaintenancePanel, OrderHistoryPanel } from './panel';
import { DronesPageProps } from './types';
import { useNavigate } from 'react-router-dom';

const DronesPage: React.FC<DronesPageProps> = () => {
  const navigate = useNavigate();

  return (
    <ArcStack direction='row'>
      <ArcBaseSidebar>
        <ArcStack spacing={3}>
          <ArcButton.brand onClick={() => navigate('add-drone')}> 
            Add drone 
          </ArcButton.brand>
          <DroneList />
        </ArcStack>
      </ArcBaseSidebar>
      <ArcBaseContent>
        <SectionWrapper>
          <DroneHeader />
          <PanelWrapper>
            <ArcTabPanel value={1}> 
              <OrderHistoryPanel /> 
            </ArcTabPanel>
            <ArcTabPanel value={2}>
              <MaintenancePanel />
            </ArcTabPanel>
          </PanelWrapper>
        </SectionWrapper>
      </ArcBaseContent>
    </ArcStack>
  );
};

export default DronesPage;