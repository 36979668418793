import React from 'react';
import { ArcOrderMarker } from '@src/core/controllers';
import { NewMapProps } from './types';

const NewMap: React.FC<NewMapProps> = (props) => {
  const { order } = props;

  return (
    <React.Fragment>
      <ArcOrderMarker.active 
        position={{ 
          lat: Number(order.deliveryLocation.latitude), 
          lng: Number(order.deliveryLocation.longitude)
        }}/>
    </React.Fragment>
  );
};

export default NewMap;