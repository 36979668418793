import React from 'react';
import { ArcButton } from '@src/core/ui';
import { useNavigate } from 'react-router-dom';
import Sections from './status/sections';
import Popups from './status/popups';
import { useDispatch } from 'react-redux';
import { setSelectedOrder } from './redux';

const OrdersPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    return () => { dispatch(setSelectedOrder(null)); };
  }, [dispatch]);

  return (
    <React.Fragment>
      <ArcButton.brand 
        size='large' 
        onClick={() => navigate('create')}>
        CREATE ORDER
      </ArcButton.brand>

      <Sections />

      <Popups />
    </React.Fragment>
  );
};

export default OrdersPage;