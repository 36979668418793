import React from 'react';
import { ArcContentProps } from './types';
import { Box } from '@mui/material';

const ArcContent: React.FC<ArcContentProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <Box 
      p={2} 
      borderRadius={1} 
      bgcolor='background.paper' 
      {...rest}>
      {children}
    </Box>
  );
};

export default ArcContent;