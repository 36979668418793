import slice from './slice';

export const { 
  resetState,

  setSelectedDrone,
  setSelectedOrder,

  addOrderToMission, 
  removeOrderFromMission,
} = slice.actions;