import React from 'react';
import { AssignDroneMapProps } from './types';
import { ArcDroneMarker, ArcOrderMarker, ArcPolyline } from '@src/core/controllers';
import { useAssignDrone } from './hooks';
import { arcOrderPath } from '@src/core/extensions';
import { LatLng } from 'use-places-autocomplete';

const AssignDroneMap: React.FC<AssignDroneMapProps> = () => {

  const { 
    newOrders, 
    idleDrones,
    missionOrders,
    selectedDrone
  } = useAssignDrone();

  const path = arcOrderPath(missionOrders, selectedDrone) as LatLng[];

  return (
    <React.Fragment>

      <ArcPolyline.future path={path} />
      <ArcPolyline.return path={selectedDrone ? path.slice(-2) : []} />
      
      {newOrders.map((order) => (
        <ArcOrderMarker.default
          key={order.id} 
          position={{
            lat: Number(order.deliveryLocation.latitude),
            lng: Number(order.deliveryLocation.longitude),
          }} />
      ))}

      {missionOrders.map((order) => (
        <ArcOrderMarker.active
          key={order.id} 
          position={{
            lat: Number(order.deliveryLocation.latitude),
            lng: Number(order.deliveryLocation.longitude),
          }} />
      ))}

      {idleDrones.map((drone) => (
        <ArcDroneMarker.idle
          key={drone.id} 
          position={{ 
            lat: Number(drone.latitude), 
            lng: Number(drone.longitude), 
          }} />
      ))}

    </React.Fragment>
  );
};

export default AssignDroneMap;