import React from 'react';
import { ArcStack } from '@src/core/ui';
import { ReactComponent as ArcLogo } from 'src/assets/arc_primary_logo.svg';

import { AuthWrapperProps } from './types';

const AuthWrapper: React.FC<AuthWrapperProps> = (props) => {
  const { children } = props;
  return (

      
    
    <ArcStack 
      minHeight='100vh'
      alignItems='center'
      justifyContent='center'
      bgcolor='background.default'>
      <ArcStack 
        p={5}
        width='100%' 
        maxWidth={520}
        borderRadius={1}
        bgcolor='background.paper'>

        <ArcStack>
          <ArcStack height={100} justifyContent='center'>
            <ArcLogo />
          </ArcStack>
        </ArcStack>
        {children}
      </ArcStack>
    </ArcStack>

  );
};

export default AuthWrapper;