import React from 'react';
import { ArcStack } from '@src/core/ui';
import { OrdersWrapperView, ToggleCollapseView } from '../../components';
import { ArcDrone } from '@src/core/components';
import { ErrorSectionProps } from './types';
import { OrderStatusEnum } from '@src/features/order/entities';

const ErrorSection: React.FC<ErrorSectionProps> = (props) => {
  const { orders, selected, onSelectOrder } = props;

  return (
    <ArcStack>
      <ToggleCollapseView 
        count={orders.length}
        status={OrderStatusEnum.ERROR}>
        <OrdersWrapperView>
          {orders.map((order) => (
            <ArcDrone.error 
              key={order.missionId} 
              width='calc(50% - 4px)' 
              drone={order.drone ?? {}} 
              selected={order.missionId === selected?.missionId}
              onClick={() => onSelectOrder(order, OrderStatusEnum.ERROR)} />
          ))}
        </OrdersWrapperView>
      </ToggleCollapseView>
    </ArcStack>
  );
};

export default ErrorSection;