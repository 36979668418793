import React from 'react';
import { ArcPasswordInputProps } from './types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { TextField } from '@mui/material';
import { useDisclosure } from '@src/core/hooks';
import ArcInputAdornment from '../arc-input-adornment';
import ArcIconButton from '../arc-icon-button';

const ArcPasswordInput = React.forwardRef<HTMLInputElement, ArcPasswordInputProps>(
  ({ ...props }, ref) => {

    const { open, toggle } = useDisclosure();

    return <TextField 
      size='small'
      variant="outlined" 
      inputRef={ref} 
      type={open ? 'text' : 'password'}
      slotProps={{ 
        input: {
          endAdornment: (
            <ArcInputAdornment position='end'>
              <ArcIconButton onClick={toggle}>
                {open 
                  ? <VisibilityIcon sx={{ fontSize: 18 }} /> 
                  : <VisibilityOffIcon sx={{ fontSize: 18 }} /> 
                }
              </ArcIconButton>
            </ArcInputAdornment>
          )
        }
      }}
      {...props} />;
  }
);

ArcPasswordInput.displayName = 'ArcPasswordInput';

export default ArcPasswordInput;