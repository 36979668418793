import React from 'react';
import { ArcButton } from '@src/core/ui';
import { useNavigate } from 'react-router-dom';
import { ModifyGroupOrderButtonProps } from './types';

const ModifyGroupOrderButton: React.FC<ModifyGroupOrderButtonProps> = (props) => {
  const { missionId } = props;
  const navigate = useNavigate();
    
  return (
    <ArcButton.brand fullWidth onClick={() => navigate(`modify-route/${missionId}`)}> 
      Modify 
    </ArcButton.brand>
  );
};

export default ModifyGroupOrderButton;