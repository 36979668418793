import React from 'react';
import Providers from './providers';
import { RootLayout } from '@src/layouts';
import { GOOGLE_MAP_KEY } from '@src/core/config';
import { ArcGlobalLoading } from '@src/core/widgets';
import { useGetMyProfileQuery } from '@src/features/profile/redux';
import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { AdminRoutes, OperatorRoutes, ViewerRoutes } from './routes';
import { ProfileEntity, UserRoleTypeEnum } from '@src/features/profile/entites';
import { useGetSettingsQuery } from '@src/features/settings/redux';

const libraries = ['places'] as Libraries;

const Root: React.FC = () => {
  const { isLoading: isSettingsLoading } = useGetSettingsQuery();
  const { data = {} as ProfileEntity, isLoading: isProfileLoading } = useGetMyProfileQuery();

  const { isLoaded } = useJsApiLoader({ googleMapsApiKey: GOOGLE_MAP_KEY, libraries });

  if (isProfileLoading || !isLoaded || isSettingsLoading) {
    return <ArcGlobalLoading />;
  }

  if (data.role === UserRoleTypeEnum.ADMIN) {
    return (
      <Providers>
        <RootLayout>
          <AdminRoutes />
        </RootLayout>
      </Providers>
    );
  }

  if (data.role === UserRoleTypeEnum.VIEWER)
    return (
      <Providers>
        <RootLayout>
          <ViewerRoutes />
        </RootLayout>
      </Providers>
    );

  if (data.role === UserRoleTypeEnum.OPERATOR) {
    return (
      <Providers>
        <RootLayout>
          <OperatorRoutes />
        </RootLayout>
      </Providers>
    );
  }

  return null;
};

export default Root;