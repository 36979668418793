import React from 'react';
import { useSelector } from "react-redux";
import { ArcButton, ArcDialog, ArcStack, ArcText } from "@src/core/ui";
import { selectConfirm } from "../../redux";
import { useConfirm } from "../../hooks";

const Confirm: React.FC = () => {
  const { open, description } = useSelector(selectConfirm);
  const { okCallback, noCallback } = useConfirm();

  return (
    <ArcDialog open={open} onClose={noCallback}>
      <ArcStack spacing={2}>
        <ArcText.h6> {description ?? 'Are you sure you want to confirm?'} </ArcText.h6>
        <ArcStack direction='row' spacing={1} justifyContent='flex-end'>
          <ArcButton.tertiary onClick={noCallback}> Cancel </ArcButton.tertiary>
          <ArcButton.brand onClick={okCallback}> Ok </ArcButton.brand>
        </ArcStack>
      </ArcStack>
    </ArcDialog>
  );
};

export default Confirm;