import React from 'react';
import { styled, Switch } from '@mui/material';

import { ArcSwitchProps, ArcSwitchVariantProps } from './types';

const MuiSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase:not(.Mui-checked) .MuiSwitch-thumb': {
    color: theme.palette.ghost.main,
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.background.paper,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ArcSwitch: React.FC<ArcSwitchProps> & ArcSwitchVariantProps = (props) => {
  return <MuiSwitch  {...props} />;
};

ArcSwitch.brand = (props) => (
  <ArcSwitch color='brand' {...props} />
);




export default ArcSwitch;