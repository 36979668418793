import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "@src/service";
import { ISetPasswordRequest } from "./types";
import { ProfileEntity, UserRoleTypeEnum } from "../entites";

export const profileApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'profileApi',
  tagTypes: ['Profile'],
  endpoints: (builder) => ({
    getMyProfile: builder.query<ProfileEntity, void>({
      query: () => ({
        url: '/api/v1/user/profile',
        method: 'GET',
      }),
      transformResponse: (response: ProfileEntity) => {
        return {
          ...response,
          isAdmin: response.role === UserRoleTypeEnum.ADMIN,
          isViewer: response.role === UserRoleTypeEnum.VIEWER,
          isOperator: response.role === UserRoleTypeEnum.OPERATOR,
        };
      },
      providesTags: ['Profile'],
    }),
    setUserPassword: builder.mutation<void, ISetPasswordRequest>({
      query: (data) => ({
        url: '/api/v1/user/password',
        method: 'POST',
        data,
      })
    }),
    updateHub: builder.mutation({
      query: (data) => ({
        url: '/api/v1/organizations/hubs',
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['Profile'],
    })
  })
});

export const { 
  useGetMyProfileQuery, 
  useUpdateHubMutation,
  useSetUserPasswordMutation 
} = profileApi;