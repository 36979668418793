import React from 'react';
import { OrdersWrapperView, PopupWrapperView } from '../../components';
import { EMPTY_STRING } from '@src/core/config';
import { CompletedPopupProps } from './types';
import { ArcOrder } from '@src/core/components';
import { OrderMissionEntity } from '@src/features/order/entities';

const CompletedPopup: React.FC<CompletedPopupProps> = (props) => {
  const { order = {} as OrderMissionEntity } = props;
  return (
    <PopupWrapperView title={`Drone - ${order.drone?.name ?? EMPTY_STRING}`}>
      <OrdersWrapperView>
        {order.orders.map((order) => (
          <ArcOrder.secondary 
            key={order.id} 
            order={order} 
            width='calc(50% - 8px)' />
        ))}
      </OrdersWrapperView>
    </PopupWrapperView>
  );
};

export default CompletedPopup;