import React from 'react';
import { ArcStack } from '@src/core/ui';
import { Settings, WeatherSwitch } from './components';

const ParameterPanel: React.FC = () => {
  return (
    <ArcStack spacing={2} p={8}>
      <WeatherSwitch />
      <Settings />
    </ArcStack>
  );
};

export default ParameterPanel;