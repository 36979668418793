import React from 'react';

const useDisclosure = (init: boolean = false) => {
  const [open, setOpen] = React.useState(init);

  const show = () => setOpen(true);
  const hide = () => setOpen(false);
  const toggle = () => setOpen((prev) => !prev);

  return {
    open,
    show,
    hide,
    toggle,
  };
};

export default useDisclosure;
