import React from 'react';
import { EnRouteFlightDroneProps } from './types';
import { useFlightProvider } from '@src/features/order/provider';
import { ArcDroneMarker, setMapCenter } from '@src/core/controllers';
import { useDispatch } from 'react-redux';

const EnRouteFlightDrone: React.FC<EnRouteFlightDroneProps> = (props) => {
  const { drone } = props;
  const dispatch = useDispatch();
  const { flightData } = useFlightProvider();

  const droneLat = Number(drone?.latitude);
  const droneLng = Number(drone?.longitude);

  const flightLat = Number(flightData?.latitude);
  const flightLng = Number(flightData?.longitude);

  React.useEffect(() => {
    if (isNaN(droneLat) || isNaN(droneLng)) return;
    dispatch(dispatch(setMapCenter({ lat: droneLat, lng: droneLng })));
  }, [droneLat, droneLng, dispatch]);

  if (isNaN(flightLat) || isNaN(flightLng)) return null;

  return (
    <ArcDroneMarker.idle 
      position={{ 
        lat: flightLat, 
        lng: flightLng,
      }} />
  );
};

export default EnRouteFlightDrone;