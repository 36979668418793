import React from 'react';
import { ArcGrid, ArcSkeleton, ArcStack, ArcText } from '@src/core/ui';
import { useDroneProvider } from '@src/features/drone/provider';
import { ArcDroneIcon } from '@src/core/widgets';
import { colors } from './constant';

const PreFlightCheckListPage: React.FC = () => {
  const { drones, isLoading } = useDroneProvider();

  return (
    <ArcStack p={5}>
      <ArcStack width='100%' spacing={4}>
        <ArcStack spacing={1}>
          <ArcText.h2 fontWeight={500}> Welcome! </ArcText.h2>
          <ArcText.h5> Pre-flight Checklist  </ArcText.h5>
        </ArcStack>

        {isLoading 
          ? <Loading /> 
          : <ArcGrid container spacing={4}>
            {drones.map((drone) => (
              <ArcGrid key={drone.id} size={1.5}>
                <ArcStack alignItems='center'>
                  <ArcDroneIcon 
                    width={42} 
                    height={42} 
                    iconColor={colors[drone.status] || '#666666'} />
                  <ArcText.caption
                    mt={1}
                    fontSize={12} 
                    textAlign='center'
                    color={colors[drone.status] || 'text.secondary'}> 
                    {drone.name} ({drone.serialNumber})  
                  </ArcText.caption>
                  <ArcText.caption 
                    fontSize={12}
                    textAlign='center'
                    color={colors[drone.status] || 'text.secondary'}> 
                    {drone.status}  
                  </ArcText.caption>
                </ArcStack>
              </ArcGrid>
            ))}
          </ArcGrid>}
      </ArcStack>
    </ArcStack>
  );
};

const Loading: React.FC = () => (
  <ArcGrid container spacing={4}>
    <ArcGrid size={1.5}> <ArcSkeleton height={100} /> </ArcGrid>
    <ArcGrid size={1.5}> <ArcSkeleton height={100} /> </ArcGrid>
    <ArcGrid size={1.5}> <ArcSkeleton height={100} /> </ArcGrid>
    <ArcGrid size={1.5}> <ArcSkeleton height={100} /> </ArcGrid>
    <ArcGrid size={1.5}> <ArcSkeleton height={100} /> </ArcGrid>
    <ArcGrid size={1.5}> <ArcSkeleton height={100} /> </ArcGrid>
    <ArcGrid size={1.5}> <ArcSkeleton height={100} /> </ArcGrid>
    <ArcGrid size={1.5}> <ArcSkeleton height={100} /> </ArcGrid>
  </ArcGrid>
);

export default PreFlightCheckListPage;