import React from 'react';
import { TakeOffButtonProps } from './types';
import { useDispatch } from 'react-redux';
import { useTakeOffMutation } from '@src/features/order/redux';
import { setSelectedOrder } from '../../redux';
import { toast } from 'react-toastify';
import { ArcButton } from '@src/core/ui';

const TakeOffButton: React.FC<TakeOffButtonProps> = (props) => {
  const { missionId } = props;

  const dispatch = useDispatch();
  const [takeOff, { isLoading }] = useTakeOffMutation();

  const onTakeOff = () => {
    takeOff(missionId).unwrap().then(() => {
      dispatch(setSelectedOrder(null));
      toast.success('Take Off Success Text');
    });
  };

  return (
    <ArcButton.brand 
      onClick={onTakeOff}
      isLoading={isLoading}> 
      TAKE OFF 
    </ArcButton.brand>
  );
};

export default TakeOffButton;