import { DroneEntity } from "@src/features/drone/entities";
import { OrderEntity } from "@src/features/order/entities";

export const arcOrderPath = (orders: OrderEntity[], drone?: DroneEntity | null) => {
  if (orders.length < 1) return [];

  const wps = orders.map((o) => getPositionOrder(o)).filter((wp) => wp !== null);

  if (drone) {
    const dronePosition =  getPositionDrone(drone);
    if (dronePosition) {
      return [ dronePosition, ...wps, dronePosition ];
    }
  }
  return wps;
};

export const getPositionDrone = (drone: DroneEntity | null) => {
  const lat = Number(drone?.latitude);
  const lng = Number(drone?.longitude);
  if (isNaN(lat) || isNaN(lng)) return null;
  return { lat, lng };
};

export const getPositionOrder = (order: OrderEntity) => {
  const lat = Number(order.deliveryLocation.latitude);
  const lng = Number(order.deliveryLocation.longitude);

  if (isNaN(lat) || isNaN(lng)) return null;
  return { lat, lng };
};