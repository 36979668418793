import React from 'react';
import { useSelector } from 'react-redux';
import { ArcSocketContext } from '@src/core/socket';
import { EnRouteCommandEnum, EnRouteEventTypeEnum } from '@src/features/order/pages/en-route-page/entities';
import { selectManualControl } from '../../redux';
import { EMPTY_VALUE } from '@src/core/config';

const PITCH_UP_KEY = 'pitchUp';
const PITCH_DOWN_KEY = 'pitchDown';
const YAW_LEFT_KEY = 'yawLeft';
const YAW_RIGHT_KEY = 'yawRight';

export const useJoystick = (serialNumber?: string) => {
  const status = useSelector(selectManualControl);
  const { arcSocket } = React.useContext(ArcSocketContext);

  const [connected, setConnected] = React.useState(false);
  const [joystickId, setJoystickId] = React.useState<string>(EMPTY_VALUE);

  const zoomState = React.useRef<any>({ 
    zoomIn: false, 
    zoomOut: false 
  });

  const gimbalState = React.useRef<any>({
    [PITCH_UP_KEY]: false,
    [PITCH_DOWN_KEY]: false,
    [YAW_LEFT_KEY]: false,
    [YAW_RIGHT_KEY]: false,
  });
  
  React.useEffect(() => {

    let intervalId: NodeJS.Timeout | null = null;

    const pollGamepad = () => {
      const socket = arcSocket();
      const gamepads = navigator.getGamepads();
      const currentGamepad = gamepads[0];

      if (currentGamepad && socket && socket.readyState === WebSocket.OPEN) {

        const { id, buttons, axes } = currentGamepad;

        const zoomInButton = buttons[6].value >= 1;
        const zoomOutButton = buttons[7].value >= 1;

        if (zoomInButton && !zoomState.current.zoomIn) {
          socket.send(
            JSON.stringify({
              type: EnRouteEventTypeEnum.CAMERA_COMMAND,
              command: EnRouteCommandEnum.ZOOM_CONTROL,
              serialNumber,
              zoom: 'in',
            })
          );
        }
        if (zoomOutButton && !zoomState.current.zoomOut) {
          socket.send(
            JSON.stringify({
              type: EnRouteEventTypeEnum.CAMERA_COMMAND,
              command: EnRouteCommandEnum.ZOOM_CONTROL,
              serialNumber,
              zoom: 'out',
            })
          );
        }

        zoomState.current.zoomIn = zoomInButton;
        zoomState.current.zoomOut = zoomOutButton;

        // GIMBAL_CONTROL
        const gimbalControlButttons = [
          { 
            key: PITCH_UP_KEY,
            button: buttons[12], 
            payload: { pitch: 5 }, 
            prevPressed: gimbalState.current[PITCH_UP_KEY],
          },
          { 
            key: PITCH_DOWN_KEY,
            button: buttons[13], 
            payload: { pitch: -5 },
            prevPressed: gimbalState.current[PITCH_DOWN_KEY],
          },
          { 
            key: YAW_LEFT_KEY,
            button: buttons[14], 
            payload: { yaw: -5 },
            prevPressed: gimbalState.current[YAW_LEFT_KEY],
          },
          { 
            key: YAW_RIGHT_KEY,
            button: buttons[15], 
            payload: { yaw: 5 },
            prevPressed: gimbalState.current[YAW_RIGHT_KEY],
          },
        ];

        gimbalControlButttons.map(({ key, button, payload, prevPressed }) => {
          if (button.pressed && !prevPressed) {
            socket.send(
              JSON.stringify({
                type: EnRouteEventTypeEnum.CAMERA_COMMAND,
                command: EnRouteCommandEnum.GIMBAL_CONTROL,
                serialNumber,
                ...payload,
              })
            );
          }
          gimbalState.current[key] = button.pressed;
        });

        // MANUAL_CONTROL
        const [yaw, throttle, roll, pitch] = axes.map((axis) => Number(axis.toFixed(2)));

        socket.send(
          JSON.stringify({
            type: EnRouteEventTypeEnum.MANUAL_CONTROL_COMMAND,
            keys: [pitch, roll, yaw, throttle],
            serialNumber,
          })
        );
        setConnected(true);
        setJoystickId(id);
      }
    };

    if (status) {
      intervalId = setInterval(pollGamepad, 150);
    } else if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
      setConnected(false);
      setJoystickId(EMPTY_VALUE);
    }
  

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
        setConnected(false);
        setJoystickId(EMPTY_VALUE);
      };
    };
  }, [status, serialNumber]);

  
  return { connected, joystickId };


};

export default useJoystick;