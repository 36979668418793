import { useGetDroneHistoryQuery } from '@src/features/drone/redux';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectDrone } from '../../redux';
import { DroneEntity } from '@src/features/drone/entities';

const OrderHistoryPanel: React.FC = () => {
  const selectedDrone = useSelector(selectDrone) ?? {} as DroneEntity;

  const { data } = useGetDroneHistoryQuery(selectedDrone.id, { 
    skip: Object.keys(selectedDrone).length < 1,
  });

  console.log({ data });
  
  return null;
};

export default OrderHistoryPanel;