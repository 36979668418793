import React from 'react';
import { ArcStack } from '@src/core/ui';
import { useSelector } from 'react-redux';
import { selectDrone } from '../../redux';
import { SectionWrapperProps } from './types';

const SectionWrapper: React.FC<SectionWrapperProps> = (props) => {
  const { children } = props;
  const selectedDrone = useSelector(selectDrone);

  if (!selectedDrone) return null;

  return <ArcStack py={3} px={5}> {children} </ArcStack>;
};

export default SectionWrapper;