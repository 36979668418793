import React from "react";
import { ArcDroneMarker, ArcOrderMarker, ArcPolyline } from "@src/core/controllers";
import { ErrorMapProps } from "./types";
import { arcMissionOrderPath } from "@src/core/extensions";

const ErrorMap: React.FC<ErrorMapProps> = (props) => {
  const { order } = props;

  return (
    <React.Fragment>
      <ArcPolyline.error path={arcMissionOrderPath(order)} />

      {order.orders.map((o) => {
        const lat = Number(o.deliveryLocation.latitude);
        const lng = Number(o.deliveryLocation.longitude);
            
        if (isNaN(lat) || isNaN(lng)) return null;
            
        return <ArcOrderMarker.default key={o.id} position={{ lat, lng }}/>;
      })}

      <ArcDroneMarker.error position={{ 
        lat: Number(order.drone.latitude), 
        lng: Number(order.drone.longitude) 
      }} />
      
    </React.Fragment>
  );
};

export default ErrorMap;