import React from 'react';
import { useSelector } from 'react-redux';
import NewMap from './new/map';
import ErrorMap from './error/map';
import CancelledMap from './cancelled/map';
import CompletedMap from './completed/map';
import AssignedMap from './assigned/map';
import TakeOffMap from './take-off/map';
import { OrderEntity, OrderMissionEntity, OrderStatusEnum } from '@src/features/order/entities';
import { selectOrder } from '../redux';

const Maps: React.FC = () => {
  const order = useSelector(selectOrder);

  if (!order) return null;

  if (order.status === OrderStatusEnum.NEW) {
    return <NewMap order={order as OrderEntity} />;
  }

  if (order.status === OrderStatusEnum.ASSIGNED) {
    return <AssignedMap order={order as OrderMissionEntity} />;
  }

  if (order.status === OrderStatusEnum.READY_FOR_TAKE_OFF) {
    return <TakeOffMap order={order as OrderMissionEntity} />;
  }

  if (order.status === OrderStatusEnum.ERROR) {
    return <ErrorMap order={order as OrderMissionEntity} />;
  }

  if (order.status === OrderStatusEnum.CANCELLED) {
    return <CancelledMap order={order as OrderMissionEntity} />;
  }

  if (order.status === OrderStatusEnum.COMPLETED) {
    return <CompletedMap order={order as OrderMissionEntity} />;
  }

  return null;
};

export default Maps;