import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IAssignDroneInitialState } from "./types";
import { OrderEntity } from "@src/features/order/entities";
import initialState from "./state";

const resetState: CaseReducer<IAssignDroneInitialState, PayloadAction> = () => {
  return initialState;
};

const setSelectedOrder: CaseReducer<IAssignDroneInitialState, PayloadAction<IAssignDroneInitialState['selectedOrder']>> = (state, action) => {
  state.selectedOrder = action.payload;
};

const setSelectedDrone: CaseReducer<IAssignDroneInitialState, PayloadAction<IAssignDroneInitialState['selectedDrone']>> = (state, action) => {
  state.selectedDrone = action.payload;
};

const addOrderToMission: CaseReducer<IAssignDroneInitialState, PayloadAction<OrderEntity>> = (state, action) => {
  state.missionOrders = [...state.missionOrders, action.payload];
};

const removeOrderFromMission: CaseReducer<IAssignDroneInitialState, PayloadAction<OrderEntity>> = (state, action) => {
  state.missionOrders = state.missionOrders.filter((o) => o.id !== action.payload.id);
};

export default {
  resetState,
  
  setSelectedOrder,
  setSelectedDrone,
  
  addOrderToMission,
  removeOrderFromMission,
};