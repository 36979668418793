import React from 'react';
import { routesConfig } from '@src/core/routes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ChecklistFeature, DroneFeature, IntegrationFeature, OrderFeature, ProfileFeature, SettingsFeature, ViewerFeature } from '@src/features';

const AdminRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/orders/*' element={<OrderFeature />} />
      <Route path='/drones/*' element={<DroneFeature />} />
      <Route path='/viewer/*' element={<ViewerFeature />} />
      <Route path='/profile/*' element={<ProfileFeature />} />
      <Route path='/settings/*' element={<SettingsFeature />} />
      <Route path='/checklist/*' element={<ChecklistFeature />} />
      <Route path='/integration/*' element={<IntegrationFeature />} />
      <Route path='*' element={<Navigate to={routesConfig.orders}/>} />
    </Routes>
  );
};

export default AdminRoutes;