import React from 'react';
import { useGetMissionOrderQuery } from '../../redux';
import { useParams } from 'react-router-dom';
import { arcOrderPath, arcWpsPath } from '@src/core/extensions';
import { LatLng } from 'use-places-autocomplete';
import { ArcOrderMarker, ArcPolyline } from '@src/core/controllers';
import { EnRouteFlightDrone } from './components';

const EnRouteMap: React.FC = () => {
  const { missionId } = useParams();
  const { data, } = useGetMissionOrderQuery(missionId as string);

  const path = arcWpsPath(data?.waypoints ?? []) as LatLng[];

  return (
    <React.Fragment>
      <ArcPolyline.future path={path} />
      <EnRouteFlightDrone drone={data?.drone} />

      {data?.orders?.map((order) => {
        const lat = Number(order.deliveryLocation.latitude);
        const lng = Number(order.deliveryLocation.longitude);

        if (isNaN(lat) || isNaN(lng)) return null;

        return (
          <ArcOrderMarker.active
            key={order.id} 
            position={{ lat, lng }} />
        );
      })}
    </React.Fragment>
  );
};

export default EnRouteMap;