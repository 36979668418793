import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { EMPTY_STRING } from '@src/core/config';
import { ArcCellInfo, ArcDivider, ArcIconButton, ArcSkeleton, ArcStack, ArcText } from "@src/core/ui";
import { ProfileContentProps } from "./types";

const ProfileContent: React.FC<ProfileContentProps> = (props) => {
  const { profile, isLoading, onClickPassword } = props;

  if (isLoading) {
    return <ArcSkeleton height={300} />;
  }

  return (
    <ArcStack 
      p={3} 
      spacing={2}
      borderRadius={1}
      bgcolor='background.paper'>
      <ArcText.h2> 
        {profile?.firstname ?? EMPTY_STRING} {profile?.lastname ?? EMPTY_STRING} 
      </ArcText.h2>

      <ArcDivider />

      <ArcStack spacing={1}>
        <ArcCellInfo label='Email'>
          {profile?.email ?? EMPTY_STRING}
        </ArcCellInfo>
        <ArcCellInfo label='Phone'>
          {profile?.phone ?? EMPTY_STRING}
        </ArcCellInfo>
        <ArcCellInfo label='Role'>
          {profile?.role ?? EMPTY_STRING}
        </ArcCellInfo>
        <ArcCellInfo label='Change password?'>
          <ArcStack 
            spacing={1}
            direction='row' 
            alignItems='center'>
            <ArcText.h6 height={16}>
            ********* 
            </ArcText.h6>
            <ArcIconButton size='small' onClick={onClickPassword}>
              <EditIcon sx={{ fontSize: 16 }} />
            </ArcIconButton>
          </ArcStack>
        </ArcCellInfo>
      </ArcStack>
    </ArcStack>
  );
};

export default ProfileContent;