import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { JwtKeysPage } from './pages';


const IntegrationFeature: React.FC = () => {
  return (
    <Routes>
      <Route index element={<JwtKeysPage />}/>
    </Routes>
  );
};

export default IntegrationFeature;