import { MapTypeStatus } from "../entities";

export const DEFAULT_GOOGLE_MAP_ZOOM = 15;
export const DEFAULT_GOOGLE_MAP_LATITUDE = 43.238949;
export const DEFAULT_GOOGLE_MAP_LONGITUDE = 76.889709;
export const DEFAULT_GOOGLE_MAP_TYPE = MapTypeStatus.ROADMAP;

export const SELECTED_GOOGLE_MAP_ZOOM = 18;

export const MAP_CENTER_STORAGE_KEY = 'asend.google.maps.center.key';

export const ARC_GOOGLE_MAP_KEY = 'arc.google.map.key';

