import React from 'react';
import { ArcStack } from '@src/core/ui';
import { OrdersWrapperView, PopupWrapperView } from '../../components';
import { EMPTY_STRING } from '@src/core/config';
import { ArcOrder } from '@src/core/components';
import { useNavigate } from 'react-router-dom';
import { CancelMissionOrderButton, ModifyGroupOrderButton, RequestAuthButton } from '../../actions';
import { AssignedPopupProps } from './types';
import { OrderMissionEntity } from '@src/features/order/entities';

const AssignedPopup: React.FC<AssignedPopupProps> = (props) => {
  const { order = {} as OrderMissionEntity } = props;

  const navigate = useNavigate();

  return (
    <PopupWrapperView title={`Drone - ${order.drone?.name ?? EMPTY_STRING}`}>
      <OrdersWrapperView>
        {order.orders.map((order) => (
          <ArcOrder.secondary 
            key={order.id} 
            order={order} 
            width='calc(50% - 4px)'
            onClick={() => navigate(`update/${order.id}`)} />
        ))}
      </OrdersWrapperView>

      <ArcStack spacing={1}>
        <ArcStack spacing={1} direction='row'>
          <ModifyGroupOrderButton missionId={order.missionId} />
          <CancelMissionOrderButton missionId={order.missionId} />
        </ArcStack>
        
        <RequestAuthButton missionId={order.missionId} />
      </ArcStack>
    </PopupWrapperView>
  );
};

export default AssignedPopup;