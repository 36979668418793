export const routesConfig = {
  app: '/app',
  orders: '/app/orders',
  createOrder: '/app/orders/create',
  updateOrder: '/app/orders/update/',
  enRouteOrder: '/app/orders/en-route',
  enRouteViewer: '/app/orders/en-route-viewer',

  assignDrone: '/app/orders/assign-drone',
  modifyDrone: '/app/orders/modify-routes/',

  drones: '/app/drones',
  addDrone: '/app/drones/add-drone',
  
  viewer: '/app/viewer',
  profile: '/app/profile',
  settings: '/app/settings',
  checklist: '/app/checklist',
  integration: '/app/integration',

  auth: '/auth',
  signIn: '/auth/signIn',
  signUp: '/auth/signUp',
  activate: '/auth/activate',
    
};