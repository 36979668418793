import React from 'react';
import { ArcBox, ArcIconButton, ArcStack, ArcTooltip } from '@src/core/ui';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useDispatch } from 'react-redux';
import { getGeocode } from 'use-places-autocomplete';
import { setMapLocation } from '../../redux';
import { toast } from 'react-toastify';

const FindMeButton: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = React.useState(false);

  const findMe = () => {
    if (!navigator.geolocation) return;
    setLoading(true);
    navigator.geolocation.getCurrentPosition(async (position) => {
      try {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        const payload = { location: { lat, lng } };
        const addresses = await getGeocode(payload);
        const [{ formatted_address }] = addresses;

        dispatch(setMapLocation({
          latitude: lat,
          longitude: lng,
          address: formatted_address,
        }));

      } catch (error) {
        toast.error('Something went wrong');
      } finally  {
        setLoading(false);
      }
    }, () => {
      setLoading(false);
    });
  };


  return (
    <ArcBox position='absolute' right={5} top={5} zIndex={100}>
      <ArcStack 
        width={50} 
        height={50} 
        borderRadius={2}
        alignItems='center' 
        justifyContent='center' 
        bgcolor='background.paper'>
        <ArcTooltip title='Find me'>
          <ArcIconButton isLoading={isLoading} onClick={findMe}>
            <MyLocationIcon />
          </ArcIconButton>
        </ArcTooltip>
      </ArcStack>
    </ArcBox>
  );
};

export default FindMeButton;