import { ButtonProps } from "@mui/material";

export interface ArcButtonProps extends ButtonProps {
    isLoading?: boolean;
}

export interface ArcButtonVariantProps {
    ghost: React.FC<ArcButtonProps>;
    brand: React.FC<ArcButtonProps>;
    tertiary: React.FC<ArcButtonProps>;
}

export enum ButtonVariantEnum {
    ghost = 'ghost',
    brand = 'brand',
}