import React from "react";
import { ArcDroneMarker, ArcOrderMarker, ArcPolyline } from "@src/core/controllers";
import { AssignedMapProps } from "./types";
import { arcMissionOrderPath } from "@src/core/extensions";

const AssignedMap: React.FC<AssignedMapProps> = (props) => {
  const { order } = props;

  const idleDroneLat = Number(order.drone.latitude);
  const idleDroneLng = Number(order.drone.longitude);

  return (
    <React.Fragment>
      <ArcPolyline.future path={arcMissionOrderPath(order)} />

      {order.orders.map((o) => {
        const lat = Number(o.deliveryLocation.latitude);
        const lng = Number(o.deliveryLocation.longitude);

        if (isNaN(lat) || isNaN(lng)) return null;

        return <ArcOrderMarker.active key={o.id} position={{ lat, lng }}/>;
      })}

      {isNaN(idleDroneLat) || isNaN(idleDroneLng) 
        ? null 
        : <ArcDroneMarker.idle position={{ lat: idleDroneLat, lng: idleDroneLng }} /> 
      }
      
    </React.Fragment>
  );
};

export default AssignedMap;