import React from 'react';
import { OrderItemProps } from './types';
import { useDragDrop } from '../../hooks';
import { ArcOrder } from '@src/core/components';

const OrderItem: React.FC<OrderItemProps> = (props) => {
  const { order, selectedOrder, onToggle } = props;
  const { ref, handlerId, opacity } = useDragDrop(props);

  return (
    <div ref={ref} data-handler-id={handlerId}>
      <ArcOrder.primary 
        order={order}
        sx={{ opacity }}
        onClick={() => onToggle(order)}
        selected={selectedOrder?.id === order.id}
      />
    </div>
  );
};

export default OrderItem;