import React from 'react';
import { ArcStack, ArcTab, ArcTabContext, ArcTabs } from '@src/core/ui';
import { PanelWrapperProps } from './types';

const PanelWrapper: React.FC<PanelWrapperProps> = (props) => {
  const {  children} = props;
  const [tab, setTab] = React.useState(1);
  
  const onChange = (e: React.SyntheticEvent, value: number) =>  setTab(value);
  
  return (
    <ArcStack p={2} mt={2} borderRadius={1} bgcolor='background.paper'>
      <ArcTabContext value={tab}>
        <ArcTabs 
          value={tab} 
          onChange={onChange} 
          variant='fullWidth' 
          TabIndicatorProps={{ sx: { bgcolor: 'brand.main' } }}>
          <ArcTab value={1} label='Order History' /> 
          <ArcTab value={2} label='Maintenance' />  
        </ArcTabs>
        {children}
      </ArcTabContext>
    </ArcStack>
  );
};

export default PanelWrapper;