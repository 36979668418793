import React from 'react';
import { toast } from 'react-toastify';
import { ArcMenuItem, ArcOutlinedSelect, ArcSkeleton, ArcStack, ArcTable, ArcTableBody, ArcTableCell, ArcTableHead, ArcTableRow } from '@src/core/ui';
import { useGetUserRolesQuery, useGetUsersQuery, useSetUserRoleMutation } from '@src/features/settings/redux';
import { ResetPasswordForm } from '../../components';
import { useGetMyProfileQuery } from '@src/features/profile/redux';
import { UserEntity, UserRoleTypeEnum } from '@src/features/profile/entites';

const UserPanel: React.FC = () => {

  const { data = {} as UserEntity } = useGetMyProfileQuery();
  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery();
  const { data: roles = [], isLoading: isRolesLoading } = useGetUserRolesQuery();

  const [setUserRole, { isLoading: isSubmitting }] = useSetUserRoleMutation();

  const onSetUserRole = (id: number, role: string) => {
    setUserRole({ id, role }).unwrap().then(() => {
      toast.success('SET USER ROLE SUCCESS TEXT');
    });
  };

  if (isUsersLoading || isRolesLoading) {
    return (
      <ArcStack spacing={1}>
        <ArcSkeleton height={50} />
        <ArcSkeleton height={50} />
        <ArcSkeleton height={50} />
        <ArcSkeleton height={50} />
        <ArcSkeleton height={50} />
      </ArcStack>
    );
  }

  return (
    <ArcTable>
      <ArcTableHead>
        <ArcTableRow>
          <ArcTableCell sx={{ fontWeight: 600, fontSize: 12, border: 0 }}> FULL NAME </ArcTableCell>
          <ArcTableCell sx={{ fontWeight: 600, fontSize: 12, border: 0 }}> EMAIL </ArcTableCell>
          {data.isAdmin && <ArcTableCell sx={{ fontWeight: 600, fontSize: 12, border: 0 }}> ROLE </ArcTableCell>}
          {data.isAdmin&& <ArcTableCell sx={{ fontWeight: 600, fontSize: 12, border: 0 }} />}
        </ArcTableRow>
      </ArcTableHead>

      <ArcTableBody>
        {users?.data?.map((user) => (
          <ArcTableRow key={user.id}>
            <ArcTableCell sx={{ border: 0 }}> {user.firstname} {user.lastname} </ArcTableCell>
            <ArcTableCell sx={{ border: 0 }}> {user.email} </ArcTableCell>
            { <ArcTableCell sx={{ border: 0, width: 250 }}> 
              <ArcOutlinedSelect 
                sx={{ width: 250 }}
                value={user.role} 
                disabled={isSubmitting} 
                onChange={(e) => onSetUserRole(user.id, e.target.value as string)}>
                {roles.map((role) => (
                  <ArcMenuItem key={role} value={role}>
                    {role}
                  </ArcMenuItem>
                ))}
              </ArcOutlinedSelect>
            </ArcTableCell>}
            <ArcTableCell sx={{ border: 0, width: 100 }}> 
              <ResetPasswordForm user={user} /> 
            </ArcTableCell>
          </ArcTableRow>
        ))}
      </ArcTableBody>
    </ArcTable>
  );
};

export default UserPanel;