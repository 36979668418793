import { setMapLocation } from '@src/core/controllers';
import { useDispatch, useSelector } from 'react-redux';
import { useOrderProvider } from '@src/features/order/provider';
import { useDroneProvider } from '@src/features/drone/provider';
import { DroneEntity, DroneStatusEnum } from '@src/features/drone/entities';
import { OrderEntity, OrderStatusEnum, OrderWayPointTypeEnum } from '@src/features/order/entities';
import { addOrderToMission, removeOrderFromMission, selectDrone, selectMissionOrders, selectOrder, setSelectedDrone, setSelectedOrder } from '../redux';
import { useAssignDroneMutation, useModifyRouteMutation } from '@src/features/order/redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { arcConvertOrderToWp, getPositionDrone } from '@src/core/extensions';

export const useAssignDrone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedOrder = useSelector(selectOrder);
  const selectedDrone = useSelector(selectDrone);
  const missionOrders = useSelector(selectMissionOrders);

  const { orders, isLoading: isOrdersLoading } = useOrderProvider();
  const { drones, isLoading: isDronesLoading } = useDroneProvider(DroneStatusEnum.IDLE);

  const [assignDrone, { isLoading: isAssignSubmitting }] = useAssignDroneMutation();
  const [modifyRoute, { isLoading: isModifySubmitting }] = useModifyRouteMutation();

  const addOrder = (order: OrderEntity) => {
    dispatch(addOrderToMission(order));
  };
  
  const removeOrder = (order: OrderEntity) => {
    dispatch(removeOrderFromMission(order));
  };

  const onAssignDrone = () => {
    if (!selectedDrone) {
      toast.error('Available drone error text');
      return;
    };

    if (missionOrders.length < 1) {
      toast.error('Mission order error text');
      return;
    };

    assignDrone({ 
      droneId: selectedDrone.id, 
      orders: missionOrders.map((o) => o.id) }).unwrap()
      .then(({ missionId }) => {
        const dronePosition = { 
          type: OrderWayPointTypeEnum.DRONE,
          latitude: Number(selectedDrone.latitude), 
          longitude: Number(selectedDrone.longitude),
        };
        modifyRoute({ missionId, waypoints: [
          dronePosition,
          ...missionOrders.map((o) => arcConvertOrderToWp(o)),
          dronePosition,
        ]}).unwrap().then(() => {
          toast.success('Assign drone success text');
          navigate(-1);
        });
      
      });
  };

  const onToggleOrder = (order: OrderEntity) => {
    if (order.id === selectedOrder?.id) {
      dispatch(setSelectedOrder(null));
      return;
    }
    dispatch(setSelectedOrder(order));

    dispatch(setMapLocation({
      latitude: Number(order.deliveryLocation.latitude),
      longitude: Number(order.deliveryLocation.longitude),
    }));
  }; 

  const onToggleDrone = (drone: DroneEntity) => {
    if (drone.id === selectedDrone?.id) {
      dispatch(setSelectedDrone(null));
      return;
    }
    dispatch(setSelectedDrone(drone));

    dispatch(setMapLocation({
      latitude: Number(drone.latitude),
      longitude: Number(drone.longitude),
    }));
  }; 

  const newOrders = (orders[OrderStatusEnum.NEW] ?? []).filter(
    (o) => !missionOrders.some((mO) => mO.id === o.id)
  );

  return {
    newOrders,
    missionOrders,
    idleDrones: drones,
    isDronesLoading,
    isOrdersLoading,

    selectedDrone,
    selectedOrder,

    addOrder,
    removeOrder,

    onToggleOrder,
    onToggleDrone,

    onAssignDrone,
    isSubmitting: isAssignSubmitting || isModifySubmitting,
  };

};