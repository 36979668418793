import React from 'react';
import ArcText from '../arc-text';
import ArcStack from '../arc-stack';

import { ArcCellInfoProps } from './types';

const ArcCellInfo: React.FC<ArcCellInfoProps> = (props) => {
  const { label, children, ...rest } = props;
  return (
    <ArcStack {...rest}>
      <ArcText.caption textTransform='uppercase'>
        {label}
      </ArcText.caption>

      <ArcText.h6 fontWeight={500}>
        {children}
      </ArcText.h6>
    </ArcStack>
  );
};

export default ArcCellInfo;