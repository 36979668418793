import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ArcSocketContext, ChannelResponseEnum } from '@src/core/socket';
import { CameraEntity, CommandStatusEnum, FlightEntity, FlightOrderDataEntity, FLightOrderStatusEnum, TelemetryAttiudeEulerEntity, TelemetryBatteryEntity, TelemetryDistanceSensorEntity, TelemetryEventEntity, TelemetryStatusTextEntity, TelemetryTypeEnum, TelemetryVelocityNedEntity } from '../../entities';
import { IFlightContext } from "./types";

export const FlightContext = React.createContext<IFlightContext>({
  flightData: {},
  cameraData: {},
});

export const FlightProvider: React.FC<React.PropsWithChildren> = ({ children }) => {

  const { subscribe, unsubscribe } = React.useContext(ArcSocketContext);

  const navigate = useNavigate();
  const [flightData, setFlightData] = React.useState<FlightEntity>({});
  const [cameraData, setCameraData] = React.useState<CameraEntity>({});

  const [battery, setBattery] = React.useState<TelemetryBatteryEntity>();
  const [status, setStatus] = React.useState<TelemetryStatusTextEntity>();
  const [velocityNed, setVelocityNed] = React.useState<TelemetryVelocityNedEntity>();
  const [attitudeEuler, setAttitudeEuler] = React.useState<TelemetryAttiudeEulerEntity>();
  const [distanceSensor, setDistanceSensor] = React.useState<TelemetryDistanceSensorEntity>();

  React.useEffect(() => {
    subscribe(ChannelResponseEnum.TELEMETRY, (event: TelemetryEventEntity) => {
      if (event.telemetry_type === TelemetryTypeEnum.StatusText) {
        setStatus((event.value ?? {}) as TelemetryStatusTextEntity);
        return;
      }
      if (event.telemetry_type === TelemetryTypeEnum.Battery) {
        setBattery((event.value ?? {}) as TelemetryBatteryEntity);
        return;
      }
      if (event.telemetry_type === TelemetryTypeEnum.VelocityNed) {
        setVelocityNed((event.value ?? {}) as TelemetryVelocityNedEntity);
        return;
      }
      if (event.telemetry_type === TelemetryTypeEnum.AttitudeEuler) {
        setAttitudeEuler((event.value ?? {}) as TelemetryAttiudeEulerEntity);
        return;
      }
      if (event.telemetry_type === TelemetryTypeEnum.DistanceSensor) {
        setDistanceSensor((event.value ?? {}) as TelemetryDistanceSensorEntity);
        return;
      }
    });
    subscribe(ChannelResponseEnum.FLIGHT_DATA, (event: { flightData: FlightEntity }) => {
      setFlightData(event.flightData ?? {});
    });
    subscribe(ChannelResponseEnum.CAMERA_DATA, (data: CameraEntity) => {
      setCameraData(data ?? {});
    });
    subscribe(ChannelResponseEnum.COMMAND_STATUS, (data) => {
      if (data.command === CommandStatusEnum.DELIVERY_COMPLETED) {
        if (data.status === 'ERROR') {
          toast.error('[DELIVERY_COMPLETED]: Something went wrong!');
          return;
        }
        toast.success(data.status ?? '');

      }
      if (data.command === CommandStatusEnum.START_MANUAL_CONTROL) {
        if (data.status === 'ERROR') {
          toast.error('[START_MANUAL_CONTROL]: Something went wrong!');
          return;
        }
        toast.success(data.status ?? '');
      }
      if (data.command === CommandStatusEnum.WINCH_DELIVERY) {
        if (data.status === 'ERROR') {
          toast.error('[WINCH_DELIVERY]: Something went wrong!');
          return;
        }
        toast.success(data.status ?? '');
      }
    });
    subscribe(ChannelResponseEnum.ORDER_STATUS, (event: { data: FlightOrderDataEntity }) => {
      if (event.data.status === FLightOrderStatusEnum.COMPLETED) {
        toast.success('Order Status Completed Text');
        navigate(-1);
        return;
      }
    });

    return () => {
      unsubscribe(ChannelResponseEnum.TELEMETRY);
      unsubscribe(ChannelResponseEnum.FLIGHT_DATA);
      unsubscribe(ChannelResponseEnum.CAMERA_DATA);
      unsubscribe(ChannelResponseEnum.ORDER_STATUS);
      unsubscribe(ChannelResponseEnum.COMMAND_STATUS);
    };
  }, [subscribe, unsubscribe]);


  return (
    <FlightContext.Provider 
      value={{ 
        status,
        battery, 
        flightData,
        velocityNed, 
        attitudeEuler, 
        distanceSensor,
        cameraData ,
      }}>
      {children}
    </FlightContext.Provider>
  );
};