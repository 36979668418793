import React from 'react';
import { IconButton } from '@mui/material';

import { ArcIconButtonProps } from './types';
import ArcCircularProgress from '../arc-circular-progress';

const ArcIconButton = React.forwardRef<HTMLButtonElement, ArcIconButtonProps>(
  ({ children, isLoading = false, ...rest }, ref) => {
    return (
      <IconButton ref={ref} disabled={isLoading} {...rest}>
        {isLoading ? <ArcCircularProgress size={18} sx={{ color: 'action.disabled' }} /> : children}
      </IconButton>
    );
  }
);

ArcIconButton.displayName = 'ArcIconButton';

export default ArcIconButton;
