import React from 'react';
import { EnRouteWinchDownActionProps } from './types';
import { ArcButton } from '@src/core/ui';
import { EnRouteCommandEnum, EnRouteEventTypeEnum } from '../../entities';
import { ArcSocketContext } from '@src/core/socket';

const EnRouteWinchDownAction: React.FC<EnRouteWinchDownActionProps> = (props) => {
  const { serialNumber } = props;
  const { arcSocket } = React.useContext(ArcSocketContext);
    
  const winchDown = () => {
    const socket = arcSocket();
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          type: EnRouteEventTypeEnum.WINCH_COMMAND,
          command: EnRouteCommandEnum.WINCH_DELIVERY,
          serialNumber,
        })
      );
    }
  };
  return (
    <ArcButton.brand 
      sx={{ width: 200 }} 
      onClick={winchDown}> 
        WINCH DOWN 
    </ArcButton.brand>
  );
};

export default EnRouteWinchDownAction;