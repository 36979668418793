import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SettingsPage } from './pages';

const SettingFeature: React.FC = () => {
  return (
    <Routes>
      <Route index element={<SettingsPage />} />
    </Routes>
  );
};

export default SettingFeature;