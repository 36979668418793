import { OrderPageReducer } from "./pages/orders-page/redux";
import { EnRoutePageReducer } from "./pages/en-route-page/redux";
import { ModifyRouteReducer } from "./pages/modify-route-page/redux";
import { AssignDronePageReducer } from "./pages/assign-drone-page/redux";
import { orderFeatureApi } from "./redux";

export const OrderFeatureMiddlewares = [
  orderFeatureApi.middleware,
];

export const OrderFeatureReducers = {
  OrderPageReducer,
  EnRoutePageReducer,
  ModifyRouteReducer,
  AssignDronePageReducer,
  [orderFeatureApi.reducerPath]: orderFeatureApi.reducer,
};
