import initialState from "./state";
import { IEnRouteInitialState } from "./types";
import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";

const resetState: CaseReducer<IEnRouteInitialState, PayloadAction> = (state) => {
  return initialState;
};

const setManualControl: CaseReducer<IEnRouteInitialState, PayloadAction<IEnRouteInitialState['isManualControl']>> = (state, action) => {
  state.isManualControl = action.payload;
};

export default { resetState, setManualControl };