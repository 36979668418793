import React from 'react';
import { ModifyRouteMapProps } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { selectDrone, selectMissionOrders, selectWayPoints, setWayPoints } from './redux';
import { ArcDroneMarker, ArcOrderMarker, ArcPolyline } from '@src/core/controllers';
import { arcWpsPath } from '@src/core/extensions';
import { toast } from 'react-toastify';
import { OrderWayPointEntity, OrderWayPointTypeEnum } from '../../entities';
import { insertWayPoint, swapWayPoints } from './utils';

const ModifyRouteMap: React.FC<ModifyRouteMapProps> = () => {
  const dispatch = useDispatch();
  const routeRef = React.useRef<any>(null);

  const wps = useSelector(selectWayPoints);
  const selectedDrone = useSelector(selectDrone);
  const missionOrders = useSelector(selectMissionOrders);

  const droneLat = Number(selectedDrone?.latitude);
  const droneLng = Number(selectedDrone?.longitude);

  const onLoadPolyline = (polyline: google.maps.Polyline) => {
    routeRef.current = polyline;
  };

  const onAddWayPoint = (e: any) => {
    const polyline = routeRef.current as google.maps.Polyline;
    const paths = polyline.getPath().getArray().map((path) => path.toJSON());

    const wpIdx = (e?.edge || 0) + 1;

    const wp: OrderWayPointEntity = {
      type: OrderWayPointTypeEnum.WAY_POINT,
      latitude: paths[wpIdx].lat,
      longitude: paths[wpIdx].lng,
    };

    if (wps.length === paths.length) {
      dispatch(setWayPoints(swapWayPoints(wps, paths)));
      return;
    }

    if (wps.length < paths.length) {
      dispatch(setWayPoints(insertWayPoint(wps, wpIdx, wp)));
      return;
    }
  };

  const onRemoveWayPoint = (e: any) => {
    const vertex = e.vertex || 0;

    if (wps[vertex].type === OrderWayPointTypeEnum.WAY_POINT) {
      dispatch(setWayPoints(wps.filter((_, idx) => idx !== vertex)));
      return;
    }
    toast.info('You can delete only the waypoints that were added');
  };

  return (
    <React.Fragment>

      <ArcPolyline.future 
        // @ts-ignore
        ref={routeRef}
        options={{ 
          editable: true, 
          clickable: true 
        }} 
        onLoad={onLoadPolyline}
        onMouseUp={onAddWayPoint}
        onRightClick={onRemoveWayPoint}
        path={arcWpsPath(wps)} />


      <ArcPolyline.return path={arcWpsPath(wps).slice(-2)}/>

      {missionOrders.map((o) => {
        const lat = Number(o.deliveryLocation.latitude);
        const lng = Number(o.deliveryLocation.longitude);

        if (isNaN(lat) || isNaN(lng)) return null;

        return <ArcOrderMarker.active key={o.id} position={{ lat, lng }}/>;
      })}

      { isNaN(droneLat) || isNaN(droneLng) 
        ? null 
        : <ArcDroneMarker.idle 
          position={{ 
            lat: droneLat, 
            lng: droneLng,
          }}/> 
      }

    </React.Fragment>
  );
};

export default ModifyRouteMap;