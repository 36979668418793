import React from 'react';
import { IUpdateHubForm, UpdateHubPageProps } from './types';
import { ArcButton, ArcOutlinedInput, ArcStack } from '@src/core/ui';
import { ArcBaseContent, ArcBaseSidebar } from '@src/core/components';
import { useGetMyProfileQuery, useUpdateHubMutation } from '../../redux';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { DEFAULT_GOOGLE_MAP_LATITUDE, DEFAULT_GOOGLE_MAP_LONGITUDE } from '@src/core/controllers';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { EMPTY_VALUE, REQUIRED_VALIDATION_TEXT } from '@src/core/config';
import { toast } from 'react-toastify';

const UpdateHubPage: React.FC<UpdateHubPageProps> = () => {

  const navigate = useNavigate();
  const { data, isLoading } = useGetMyProfileQuery();
  const [updateHub] = useUpdateHubMutation();

  const hub = data?.hub;

  const lat = Number(data?.hub?.latitude);
  const lng = Number(data?.hub?.longitude);

  const defaultLat = isNaN(lat) ? DEFAULT_GOOGLE_MAP_LATITUDE : lat;
  const defaultLng = isNaN(lng) ? DEFAULT_GOOGLE_MAP_LONGITUDE : lng;

  const [coords, setCoords] = React.useState({
    lat: defaultLat,
    lng: defaultLng,
  });

  const onChangeCoords = (e: google.maps.MapMouseEvent) => {
    const newLat = e.latLng?.lat();
    const newLng = e.latLng?.lng();
    if (newLat && newLng) {
      setCoords({ lat: newLat, lng: newLng });
    }
  };

  const { register, formState: { errors }, setValue, handleSubmit } = 
    useForm<IUpdateHubForm>({ 
      defaultValues: {
        name: hub?.name ?? EMPTY_VALUE,
        contacts: hub?.contacts ?? EMPTY_VALUE,
        altitude: hub?.altitude ?? EMPTY_VALUE,
        description: hub?.description ?? EMPTY_VALUE
      }}
    );

  React.useEffect(() => {
    if (hub) {
      setValue('name', hub.name ?? EMPTY_VALUE);
      setValue('contacts', hub.contacts ?? EMPTY_VALUE);
      setValue('altitude', hub.altitude ?? EMPTY_VALUE);
      setValue('description', hub.description ?? EMPTY_VALUE);
    }
  }, [hub]);

  const onSubmit = (form: IUpdateHubForm) => {
    if (!hub) return;

    updateHub({ 
      id: hub.id, 
      latitude: coords.lat, 
      longitude: coords.lng, 
      ...form,
    }).unwrap().then(() => {
      toast.success('UPDATE HUB SUCCESS TEXT');
      navigate(-1);
    });
  };

  return (
    <ArcStack direction='row'>
      <ArcBaseSidebar>
        <ArcStack 
          height='100%' 
          component='form'
          justifyContent='space-between'
          onSubmit={handleSubmit(onSubmit)}
        >
          <ArcStack spacing={2}>

            <ArcOutlinedInput
              label='Name' 
              autoComplete='off'
              error={Boolean(errors.name)} 
              helperText={errors.name?.message}
              {...register('name', { 
                required: REQUIRED_VALIDATION_TEXT
              })} 
            />
            <ArcOutlinedInput
              label='Contacts' 
              autoComplete='off'
              error={Boolean(errors.contacts)} 
              helperText={errors.contacts?.message}
              {...register('contacts', { 
                required: REQUIRED_VALIDATION_TEXT
              })} 
            />

            <ArcOutlinedInput
              label='Altitude' 
              autoComplete='off'
              error={Boolean(errors.altitude)} 
              helperText={errors.altitude?.message}
              {...register('altitude', { 
                required: REQUIRED_VALIDATION_TEXT
              })} 
            />

            <ArcOutlinedInput
              label='Description' 
              autoComplete='off'
              error={Boolean(errors.description)} 
              helperText={errors.description?.message}
              {...register('description', { 
                required: REQUIRED_VALIDATION_TEXT
              })} 
            />

          </ArcStack>

          <ArcStack spacing={2} direction='row' px={1}>
            <ArcButton.ghost
              fullWidth 
              size='large'
              onClick={() => navigate(-1)}> 
                Cancel 
            </ArcButton.ghost>
            
            <ArcButton.brand 
              fullWidth
              size='large'
              type='submit'
              isLoading={isLoading}> 
              UPDATE
            </ArcButton.brand>
          </ArcStack>

        </ArcStack>


      </ArcBaseSidebar>
      <ArcBaseContent>
        <GoogleMap 
          zoom={10}
          center={{ lat: defaultLat, lng: defaultLng }}
          mapContainerStyle={{ 
            width: '100%', 
            height: '100%', 
          }}
          options={{
            zoomControl: false,
            scaleControl: false,
            rotateControl: false,
            mapTypeControl: false,
            streetViewControl: false,
          }}
          onDblClick={onChangeCoords}>
          <Marker 
            draggable
            onDragEnd={onChangeCoords}
            position={{ lat: coords.lat, lng: coords.lng }} />
        </GoogleMap>
      </ArcBaseContent>
    </ArcStack>
  );
};

export default UpdateHubPage;