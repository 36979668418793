import React from 'react';
import { OrderContext } from '.';

export const useOrderProvider = () => {
  const { data, isLoading, refetch } = React.useContext(OrderContext);

  return {
    orders: data,
    isLoading,
    refetch,
  };
};