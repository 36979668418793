import React from 'react';
import { RegisterDronePageProps } from './types';
import { ArcStack, ArcText } from '@src/core/ui';
import { DroneList } from './components';

const AddDronePage: React.FC<RegisterDronePageProps> = () => {
  return (
    <ArcStack p={5}>
      <ArcStack spacing={4}>
        <ArcStack spacing={1}>
          <ArcText.h2> Add Drone </ArcText.h2>
          <ArcText.h5> Unregistered drones in your network </ArcText.h5>
        </ArcStack>

        <DroneList />
        
      </ArcStack>
    </ArcStack>
  );
};

export default AddDronePage;