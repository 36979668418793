import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArcStack, ArcSwitch, ArcText } from '@src/core/ui';
import { selectManualControl, setManualControl } from '../../redux';
import { EnRouteCommandEnum, EnRouteEventTypeEnum } from '../../entities';
import { ArcSocketContext } from '@src/core/socket/base';
import { EnRouteManualControlProps } from './types';
import { useJoystick } from '../../hooks';

const EnRouteManualControl: React.FC<EnRouteManualControlProps> = (props) => {
  const { drone } = props;
  const dispatch = useDispatch();

  const { arcSocket } = React.useContext(ArcSocketContext);
  const isManualControl = useSelector(selectManualControl);
  const { connected, joystickId } = useJoystick(drone?.serialNumber);

  const onToggle = (_: unknown, checked: boolean) => {
    const ws = arcSocket();

    if (!ws) return;

    if (checked) {
      const payload = {
        type: EnRouteEventTypeEnum.COMMAND,
        command: EnRouteCommandEnum.START_CONTROL,
        serialNumber: drone?.serialNumber,
      };
      ws.send(JSON.stringify(payload));
    } else {
      const payload = {
        type: EnRouteEventTypeEnum.COMMAND,
        command: EnRouteCommandEnum.CONTINUE,
        serialNumber: drone?.serialNumber,
      }; 
      ws.send(JSON.stringify(payload));
    }

    dispatch(setManualControl(checked));
  };

  const touch = isManualControl && !connected;
  const started = isManualControl && connected;

  return (
    <ArcStack spacing={1}>
      <ArcStack 
        spacing={1} 
        direction='row' 
        alignItems='center' 
        justifyContent='space-between'>
        <ArcText.caption color='text.primary' fontWeight={500}> 
        MANUAL CONTROL  
        </ArcText.caption>
        <ArcSwitch.brand checked={isManualControl} onChange={onToggle} />
      </ArcStack>

      {touch 
        ? <ArcText.caption color='info' textAlign='center'> CONNECT YOUR GAMEPAD AND PRESS BUTTONS TO BEGIN </ArcText.caption> 
        : started 
          ? <ArcText.caption color='success' textAlign='center'> {joystickId} is connected </ArcText.caption> 
          : null
      }
    </ArcStack>
  );
};

export default EnRouteManualControl;