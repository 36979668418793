import React from 'react';
import { line } from './constants';
import { Polyline } from '@react-google-maps/api';
import { ArcPolylineProps, ArcPolylineVariant } from './types';

const ArcPolylineBase = React.forwardRef<Polyline, ArcPolylineProps>((props, ref) => {
  return <Polyline ref={ref} {...props} />;
});
ArcPolylineBase.displayName = 'ArcPolyline';


const ArcDefaultPolyline = React.forwardRef<Polyline, ArcPolylineProps>((props, ref) => {
  const options = {
    ...props.options,
    ...line.default,
  };
  return <Polyline ref={ref} {...props} options={options} />;
});

ArcDefaultPolyline.displayName = 'ArcPolyline.default';



const ArcFuturePolyline = React.forwardRef<Polyline, ArcPolylineProps>((props, ref) => {
  const options = {
    ...props.options,
    ...line.future,
  };
  return <Polyline ref={ref} {...props} options={options} />;
});

ArcFuturePolyline.displayName = 'ArcPolyline.future';


const ArcErrorPolyline = React.forwardRef<Polyline, ArcPolylineProps>((props, ref) => {
  const options = {
    ...props.options,
    ...line.error,
  };
  return <Polyline ref={ref} {...props} options={options} />;
});

ArcErrorPolyline.displayName = 'ArcPolyline.error';

const ArcReturnPolyline = React.forwardRef<Polyline, ArcPolylineProps>((props, ref) => {
  const options = {
    ...props.options,
    ...line.return,
  };
  return <Polyline ref={ref} {...props} options={options} />;
});

ArcReturnPolyline.displayName = 'ArcPolyline.return';

const ArcPolyline: ArcPolylineVariant = Object.assign(ArcPolylineBase, {
  default: ArcDefaultPolyline,
  future: ArcFuturePolyline,
  return: ArcReturnPolyline,
  error: ArcErrorPolyline,
});

export default ArcPolyline;