import React from 'react';
import { arcToFixed } from '@src/core/extensions';
import { ArcContent, ArcDivider, ArcStack, ArcText } from '@src/core/ui';
import { ReactComponent as DefaultDroneIcon } from '@src/assets/drone-icons/default.svg';
import { useFlightProvider } from '@src/features/order/provider';

import { EnRouteDroneInfoProps } from './types';
import { EMPTY_STRING } from '@src/core/config';

const EnRouteDroneInfo: React.FC<EnRouteDroneInfoProps> = (props) => {
  const { drone } = props;

  const { flightData } = useFlightProvider();

  return (
    <ArcContent>
      <ArcStack spacing={1}>
        <ArcText.caption> {arcToFixed(flightData.batteryHealth)}% BATTERY </ArcText.caption>
        <ArcDivider />
        <ArcStack spacing={1} direction='row' alignItems='center'>
          <DefaultDroneIcon width={24} />
          <ArcText.h6 fontWeight={500}> {drone?.name ?? EMPTY_STRING} </ArcText.h6>
        </ArcStack>
      </ArcStack>
    </ArcContent>
  );
};

export default EnRouteDroneInfo;