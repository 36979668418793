import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IOrderForm, OrderFormView } from '../../components';

import { CreateOrderPageProps } from './types';
import { useCreateOrderMutation } from '../../redux';

const CreateOrderPage: React.FC<CreateOrderPageProps> = () => {
  const navigate = useNavigate();
  const [createOrder, { isLoading }] = useCreateOrderMutation();
  
  const onCreate = (form: IOrderForm) => {
    const { 
      latitude, 
      longitude, 
      address, 
      deliveryAltitude, 
      ...rest 
    } = form;

    createOrder({
      ...rest,
      deliveryLocation: {
        address,
        latitude,
        longitude,
        altitude: deliveryAltitude,
      }
    }).unwrap().then(() => {
      navigate(-1);
      toast.success('Your order has been created successfully!');
    });
  };

  return (
    <OrderFormView 
      onSubmit={onCreate}
      isLoading={isLoading}
      submitText='Create Order' />
  );
};

export default CreateOrderPage;