import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IDronesInitialState } from "./types";
import initialState from "./state";

const resetState: CaseReducer<IDronesInitialState, PayloadAction> = () => {
  return initialState;
};

const setSelectedDrone: CaseReducer<IDronesInitialState, PayloadAction<IDronesInitialState['selectedDrone']>> = (state, action) => {
  state.selectedDrone = action.payload;
};

export default { resetState, setSelectedDrone };