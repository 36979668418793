import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ArcFab, ArcStack } from '@src/core/ui';
import { EnRouteCameraControlProps } from './types';
import { useDisclosure } from '@src/core/hooks';
import { ArcSocketContext } from '@src/core/socket';
import { EnRouteCommandEnum, EnRouteEventTypeEnum } from '../../entities';

const EnRouteCameraControl: React.FC<EnRouteCameraControlProps> = (props) => {
  const { serialNumber } = props;
    
  const { open, toggle } = useDisclosure();
  const { arcSocket } = React.useContext(ArcSocketContext);

  const onChangeZoom = (zoom: 'in' | 'out') => {
    const socket = arcSocket();
    if (socket && socket.readyState === WebSocket.OPEN) {;
      socket.send(
        JSON.stringify({
          type: EnRouteEventTypeEnum.CAMERA_COMMAND,
          command: EnRouteCommandEnum.ZOOM_CONTROL,
          serialNumber,
          zoom,
        })
      );
    }
  };

  const onChangeGimbal = (payload: Record<string, number>) => {
    const socket = arcSocket();
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          type: EnRouteEventTypeEnum.CAMERA_COMMAND,
          command: EnRouteCommandEnum.GIMBAL_CONTROL,
          serialNumber,
          ...payload,
        })
      );
    }
  };

  return (
    <ArcStack 
      top='50%'
      right={15}
      spacing={2}
      zIndex={100}
      direction='row' 
      alignItems='center' 
      position='absolute'
      sx={{ transform: 'translateY(-50%)' }}>
      {open && <ArcStack direction='row' spacing={1}> 
        <ArcStack justifyContent='center'>
          <ArcFab size='small' onClick={() => onChangeGimbal({ yaw: -5 })}>
            <KeyboardArrowLeftIcon />
          </ArcFab>
        </ArcStack>
        <ArcStack justifyContent='space-between'>
          <ArcFab size='small' onClick={() => onChangeGimbal({ pitch: 5 })}>
            <KeyboardArrowUpIcon />
          </ArcFab>
          <ArcFab size='small' onClick={() => onChangeGimbal({ pitch: -5 })}>
            <KeyboardArrowDownIcon />
          </ArcFab>
        </ArcStack>
        <ArcStack spacing={1}>
          <ArcFab size='small' onClick={() => onChangeZoom('in')}>
            <AddIcon />
          </ArcFab>
          <ArcFab size='small' onClick={() => onChangeGimbal({ yaw: 5 })}>
            <KeyboardArrowRightIcon />
          </ArcFab>
          <ArcFab size='small' onClick={() => onChangeZoom('out')}>
            <RemoveIcon />
          </ArcFab>
        </ArcStack>
      </ArcStack>}

      <ArcFab size='small' onClick={toggle}>
        <FullscreenIcon sx={{ rotate: '45deg' }} />
      </ArcFab>
    </ArcStack>
  );
};

export default EnRouteCameraControl;