import React from 'react';
import { SettingsPageProps } from './types';
import { ArcStack, ArcTab, ArcTabContext, ArcTabPanel, ArcTabs } from '@src/core/ui';
import { ParameterPanel, UserPanel } from './panels';

const SettingsPage: React.FC<SettingsPageProps> = () => {
  const [tab, setTab] = React.useState(1);

  const onChange = (e: React.SyntheticEvent, value: number) =>  setTab(value);

  return (
    <ArcStack p={8}>

      <ArcTabContext value={tab}>
        <ArcTabs 
          value={tab} 
          onChange={onChange} 
          variant='fullWidth' 
          TabIndicatorProps={{ sx: { bgcolor: 'brand.main' } }}>
          <ArcTab value={1} label='PARAMETER' /> 
          <ArcTab value={2} label='USER' />  
        </ArcTabs>

        <ArcTabPanel value={1}> <ParameterPanel /> </ArcTabPanel>
        <ArcTabPanel value={2}> <UserPanel /> </ArcTabPanel>
      </ArcTabContext>

    </ArcStack>
  );
};

export default SettingsPage;