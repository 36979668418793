import { createTheme } from "@mui/material";
import { palette } from "./palette";

export const theme = createTheme({
  palette,

  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-select': {
            borderColor: 'white',
          },
          '&.Mui-focused .MuiSelect-select': {
            borderColor: '#9E9EF3',
          },
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#9E9EF3',
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#9E9EF3',
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#A0A0A0',
          '&.Mui-selected': {
            color: '#9E9EF3',
          }
        }
      }
    }
  }
});
