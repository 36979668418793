import { BaseSocketProvider } from '@src/core/socket';
import { DroneProvider } from '@src/features/drone/provider';
import { OrderProvider } from '@src/features/order/provider';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ProvidersProps } from './types';
import WeatherProvider from '@src/core/socket/weather';

const Providers: React.FC<ProvidersProps> = ({ children }) => (
  <WeatherProvider>
    <DndProvider backend={HTML5Backend}>
      <BaseSocketProvider>
        <DroneProvider>
          <OrderProvider>
            {children}
          </OrderProvider>
        </DroneProvider>
      </BaseSocketProvider>
    </DndProvider>
  </WeatherProvider>
);

export default Providers;