import React from 'react';
import { Typography } from '@mui/material';
import { ArcTextProps, ArcTextVariantProps } from './types';

const ArcText: React.FC<ArcTextProps> & ArcTextVariantProps  = (props) => {
  const { children, maxLines, disabled, color, ...rest } = props;

  const { sx, ...textProps } = rest;

  return (
    <Typography 
      color={disabled ? 'action.disabled' : color}
      sx={{ 
        overflow: 'hidden', 
        display: '-webkit-box', 
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: maxLines ?? null,
      }} 
      {...textProps}>
      {children}
    </Typography>
  );
};

ArcText.caption = ({ children, ...rest }) => (
  <ArcText 
    fontSize={10} 
    fontWeight={400} 
    color='text.secondary' 
    {...rest}>

    {children}

  </ArcText>
);

ArcText.button = ({ children, ...rest }) => (
  <ArcText 
    fontSize={12} 
    fontWeight={500} 
    color = 'text.primary'
    textTransform='uppercase' 
    {...rest}>

    {children}

  </ArcText>
);

ArcText.h2 = ({ children, ...rest }) => (
  <ArcText 
    fontSize={24} 
    fontWeight={400}
    color='text.primary' 
    {...rest}>

    {children}

  </ArcText>
);

ArcText.h3 = ({ children, ...rest }) => (
  <ArcText 
    fontSize={20} 
    fontWeight={400}
    color='text.primary' 
    {...rest}>

    {children}

  </ArcText>
);

ArcText.h4 = ({ children, ...rest }) => (
  <ArcText 
    fontSize={18} 
    fontWeight={400}
    color='text.primary' 
    {...rest}>

    {children}

  </ArcText>
);

ArcText.h5 = ({ children, ...rest }) => (
  <ArcText 
    fontSize={16} 
    fontWeight={400}
    color='text.primary' 
    {...rest}>

    {children}

  </ArcText>
);

ArcText.h6 = ({ children, ...rest }) => (
  <ArcText 
    fontSize={14} 
    fontWeight={400} 
    color='text.primary' 
    {...rest}>

    {children}

  </ArcText>
);


  

export default ArcText;