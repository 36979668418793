import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent } from '@mui/material';
import ArcStack from '../arc-stack';
import ArcText from '../arc-text';
import ArcIconButton from '../arc-icon-button';
import { ArcDialogProps } from './types';

const ArcDialog: React.FC<ArcDialogProps> = (props) => {
  const { children, contextSX, title, onClose, ...rest } = props;

  const handleClose = (
    event = {},
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    onClose && onClose(event, reason);
  };


  return (
    <Dialog 
      onClose={handleClose} 
      {...rest}>
      <DialogContent sx={{ px: 3, py: 2, minWidth: 450, ...contextSX }}>
        <ArcStack 
          mb={2}
          direction='row' 
          alignItems='center'
          justifyContent='space-between'>
          <ArcText.h5> {title} </ArcText.h5>
          <ArcIconButton 
            size='small' 
            sx={{ 
              width: 50, 
              height: 50,
            }} 
            onClick={(e) => handleClose(e, 'backdropClick')}> 
            <CloseIcon /> 
          </ArcIconButton>
        </ArcStack>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default ArcDialog;