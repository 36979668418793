import React from 'react';
import { AssignDronePageProps } from './types';
import { ArcButton, ArcStack, ArcText } from '@src/core/ui';
import { useNavigate } from 'react-router-dom';
import { DronesSection, OrdersSection } from './components';
import { useAssignDrone } from './hooks';
import { resetState } from './redux';
import { useDispatch } from 'react-redux';

const AssignDronePage: React.FC<AssignDronePageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { 
    newOrders, 
    missionOrders,
    isOrdersLoading, 

    idleDrones,
    isDronesLoading, 

    selectedDrone,
    onToggleDrone,
    
    selectedOrder,
    onToggleOrder,

    isSubmitting,
    onAssignDrone,

    addOrder,
    removeOrder,
  } = useAssignDrone();


  React.useEffect(() => {
    return () => { dispatch(resetState()); };
  }, [dispatch]);

  return (
    <ArcStack 
      height='100%' 
      position='relative' 
      justifyContent='space-between'>
      <ArcStack pb={10}>
        <ArcText.h4 fontWeight={500}> Plan route </ArcText.h4>

        <OrdersSection 
          title='New Orders' 
          actionText='add' 
          orders={newOrders}
          isLoading={isOrdersLoading}
          onClick={addOrder}
          selectedOrder={selectedOrder}
          onToggle={onToggleOrder}
        />

        <OrdersSection 
          title='Mission Orders' 
          actionText='remove' 
          orders={missionOrders}
          isLoading={isOrdersLoading}
          onClick={removeOrder}
          selectedOrder={selectedOrder}
          onToggle={onToggleOrder}
        />

        <DronesSection
          title='Available drones' 
          drones={idleDrones}
          isLoading={isDronesLoading}
          selectedDrone={selectedDrone}
          onToggle={onToggleDrone}
        />
      </ArcStack>

      <ArcStack
        p={2}
        spacing={2} 
        width={368}
        bottom={0}
        direction='row'
        position='fixed'
        bgcolor='background.default'>
        <ArcButton.ghost
          fullWidth 
          size='large'
          onClick={() => navigate(-1)}> 
          Cancel
        </ArcButton.ghost>
      
        <ArcButton.brand 
          fullWidth 
          size='large'
          type='submit'
          isLoading={isSubmitting}
          onClick={onAssignDrone}> 
          Modify Route
        </ArcButton.brand>
      </ArcStack>
    </ArcStack>
  );
};

export default AssignDronePage;