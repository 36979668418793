import React from 'react';
import { iceServers } from './iceServers';
import { ArcSocketContext, ChannelRequestEnum, ChannelResponseEnum } from '@src/core/socket';

const MAX_RECONNECT_ATTEMPTS = 3;
const RECONNECT_DELAY = 3000; // 3 seconds

const useDroneCamera = (droneSerialNumber: string) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [reconnectAttempts, setReconnectAttempts] = React.useState(0);
  const [isReconnecting, setIsReconnecting] = React.useState(false);
  
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const peerConnections = React.useRef<RTCPeerConnection | null>(null);
  const reconnectTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const { arcSocket, subscribe, unsubscribe } = React.useContext(ArcSocketContext);


  const initWebRTC = async (): Promise<void> => {
    try {
    // Create peer connection
      peerConnections.current = new RTCPeerConnection({ iceServers });

      // Set the direction to 'recvonly'
      peerConnections.current.addTransceiver('video', { direction: 'recvonly' }).receiver.track,
      peerConnections.current.addTransceiver('audio', { direction: 'recvonly' }).receiver.track,

      // Handle incoming stream
      peerConnections.current.ontrack = (event: RTCTrackEvent) => {
        if (videoRef.current) {
          videoRef.current.srcObject = event.streams[0];
        }
      };

      // Create offer to receive only
      const offer = await peerConnections.current.createOffer();
      await peerConnections.current.setLocalDescription(offer);

      // Send offer to the signaling server
      const webSocket = arcSocket();
      const payload = {
        type: ChannelRequestEnum.WEBRTC,
        command: 'offer',
        data: offer.sdp,
        serialNumber: droneSerialNumber,
      };
      webSocket?.send(JSON.stringify(payload));

      // Send local ICE candidates to the signaling server
      peerConnections.current.onicecandidate = (event) => {
        if (event.candidate) {
          const payload = { 
            type: ChannelRequestEnum.WEBRTC, 
            command: "ice_candidate", 
            data: JSON.stringify(event.candidate), 
            serialNumber: droneSerialNumber,
          };

          webSocket?.send(JSON.stringify(payload));
        }
      };

      peerConnections.current.oniceconnectionstatechange = () => {
        if (peerConnections.current?.iceConnectionState === 'failed') {
          console.error('ICE connection failed. Checking network and TURN/STUN servers.');
        }
      };
    } catch (error) {
      console.error("Error initializing WebRTC:", error);
    }
  };

  const reconnect = async () => {
    if (isReconnecting || reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
      console.error('Max reconnection attempts reached or already reconnecting');
      return;
    }

    setIsReconnecting(true);
    setReconnectAttempts(prev => prev + 1);

    try {
      // Clean up existing connection
      onStopVideo();
      
      // Wait for a short delay before attempting to reconnect
      await new Promise(resolve => setTimeout(resolve, RECONNECT_DELAY));
      
      // Attempt to establish new connection
      await initWebRTC();
      
      // Reset reconnection state on success
      setReconnectAttempts(0);
      setIsReconnecting(false);
    } catch (error) {
      console.error('Reconnection attempt failed:', error);
      setIsReconnecting(false);
      
      // Schedule next reconnection attempt if we haven't reached max attempts
      if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
        reconnectTimeoutRef.current = setTimeout(reconnect, RECONNECT_DELAY);
      }
    }
  };

  const onStopVideo = () => {
    const webSocket = arcSocket();

    const payload = {
      type: ChannelRequestEnum.WEBRTC,
      command: 'stop',
      data: null,
      serialNumber: droneSerialNumber,
    };
    webSocket?.send(JSON.stringify(payload));
    peerConnections.current?.getTransceivers().forEach(transceiver => transceiver.stop());
    peerConnections.current?.close();
    peerConnections.current = null;
  };

  const onStartVideo = async (): Promise<void> => {
    setIsLoading(true);
    await initWebRTC();
  };


  
  const getAnswerFromDrone = async (answer: RTCSessionDescriptionInit): Promise<void> => {
    if (peerConnections.current) {
      await peerConnections.current.setRemoteDescription(new RTCSessionDescription(answer));
    }
  };

  const getIceCandidateFromDrone = (remoteCandidate: RTCIceCandidateInit): void => {
    if (peerConnections.current) {
      const current = { ...remoteCandidate, candidate: remoteCandidate.candidate?.trim() };
      peerConnections.current.addIceCandidate(new RTCIceCandidate(current)).catch(error => console.error("Error adding ICE candidate:", error));
    }
  };

  React.useEffect(() => {
    onStartVideo();
    return () => {
      onStopVideo();
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, []);

  React.useEffect(() => {
    const handleWEBRTC = (event: any) => {
      if (event.command === ChannelResponseEnum.WEBRTC_ANSWER) {
        getAnswerFromDrone({ type: 'answer', sdp: event.data });
      } else if (event.command === ChannelResponseEnum.WEBRTC_ICE_CANDIDATE) {
        getIceCandidateFromDrone(JSON.parse(event.data));
      }
    };

    subscribe(ChannelResponseEnum.WEBRTC, handleWEBRTC);

    return () => {
      unsubscribe(ChannelResponseEnum.WEBRTC);
    };
  }, [subscribe, unsubscribe]);

  React.useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    const handleWaiting = () => {
      console.log('handleWaiting');
      setIsLoading(true);
    };
    const handlePlaying = () => {
      console.log('handlePlaying');
      setIsLoading(false);
    };
    const handleCanPlay = () => {
      console.log('handleCanPlay');
      setIsLoading(false);
    };
    const handleError = () => {
      console.log('handleError');
      setIsLoading(false);
      // Attempt to reconnect on video error
      reconnect();
    };

    videoElement.addEventListener('waiting', handleWaiting);
    videoElement.addEventListener('playing', handlePlaying);
    videoElement.addEventListener('canplay', handleCanPlay);
    videoElement.addEventListener('error', handleError);

    return () => {
      videoElement.removeEventListener('waiting', handleWaiting);
      videoElement.removeEventListener('playing', handlePlaying);
      videoElement.removeEventListener('canplay', handlePlaying);
      videoElement.removeEventListener('error', handleError);
    };
  }, []);


  return {
    onStopVideo,
    onStartVideo,
    isLoading,
    videoRef,
    reconnect,
    isReconnecting,
    reconnectAttempts,
  };

  
};

export default useDroneCamera;