import React from 'react';
import { ArcBaseContentProps } from './types';
import { ArcStack } from '@src/core/ui';

const ArcBaseContent: React.FC<ArcBaseContentProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <ArcStack 
      position='relative'
      width='calc(100% - 400px)'
      height='calc(100vh - 40px)' 
      {...rest}>
      {children}
    </ArcStack>
  );
};

export default ArcBaseContent;