import React from 'react';
import { ArcStack } from '@src/core/ui';
import { OrdersWrapperView, ToggleCollapseView } from '../../components';
import { ArcDrone } from '@src/core/components';
import { CompletedSectionProps } from './types';
import { OrderStatusEnum } from '@src/features/order/entities';

const CompletedSection: React.FC<CompletedSectionProps> = (props) => {
  const { orders, selected, onSelectOrder } = props;
    
  return (
    <ArcStack>
      <ToggleCollapseView 
        count={orders.length}
        status={OrderStatusEnum.COMPLETED}>
        <OrdersWrapperView>
          {orders.map((order) => (
            <ArcDrone.primary 
              key={order.missionId} 
              width='calc(50% - 4px)'
              selected={order.missionId === selected?.missionId}
              onClick={() => onSelectOrder(order, OrderStatusEnum.COMPLETED)}
              drone={order.drone ?? {}} />
          ))}
        </OrdersWrapperView>
      </ToggleCollapseView>
    </ArcStack>
  );
};

export default CompletedSection;