import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AuthWrapper, AuthSubmit } from '../../components';
import { ISignInForm } from './types';
import { ACCESS_TOKEN_KEY, REQUIRED_VALIDATION_TEXT } from '@src/core/config';
import {useSignInMutation } from '../../redux';
import { useNavigate } from 'react-router-dom';
import { routesConfig } from '@src/core/routes';
import { ArcOutlinedInput, ArcPasswordInput, ArcStack } from '@src/core/ui';

const SignInPage: React.FC = () => {
  const navigate = useNavigate();
  const [signIn, { isLoading }] = useSignInMutation();
  const { register, handleSubmit, formState: { errors } } = useForm<ISignInForm>();

  const onSubmit: SubmitHandler<ISignInForm> = async (form) => {
    signIn(form).unwrap()
      .then((data) => { 
        localStorage.setItem(ACCESS_TOKEN_KEY, data.id_token);
        navigate(routesConfig.app);
      });
  };

  return (
    <AuthWrapper> 
      <ArcStack component='form' gap={1} onSubmit={handleSubmit(onSubmit)}>

        <ArcOutlinedInput 
          label='Email'
          autoComplete='off' 
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          {...register('email', { required: REQUIRED_VALIDATION_TEXT })}
        />

        <ArcPasswordInput 
          label='Password'
          autoComplete='off' 
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          {...register('password', { required: REQUIRED_VALIDATION_TEXT })}
        />   

        <AuthSubmit 
          toText='SIGN UP'
          to={routesConfig.signUp}
          buttonText='SIGN IN'
          isLoading={isLoading} />  

      </ArcStack>
       
    </AuthWrapper>
  );
};

export default SignInPage;