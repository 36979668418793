import React from 'react';
import { ArcStatusPointProps, ArcStatusPointVariant } from './types';
import { Box } from '@mui/material';

const ArcStatusPoint: React.FC<ArcStatusPointProps> & ArcStatusPointVariant = (props) => {
  return <Box borderRadius='50%' {...props} />;
};

ArcStatusPoint.s = (props) => <ArcStatusPoint width={10} height={10} {...props} />;
ArcStatusPoint.m = (props) => <ArcStatusPoint {...props} />;
ArcStatusPoint.l = (props) => <ArcStatusPoint {...props} />;

export default ArcStatusPoint;