import React from "react";
import { DroneStatusEnum } from "../../entities";
import { DroneContext } from ".";

export const useDroneProvider = (status?: DroneStatusEnum) => {
  const { data, isLoading, refetch } = React.useContext(DroneContext);

  const drones = React.useMemo(() => {
    if (status) {
      return data.filter((drone) => drone.status === status) ?? [];
    }
    return data;
  }, [data]);

  return {
    drones,
    refetch,
    isLoading,
  };
};