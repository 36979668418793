import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArcIconButton, ArcStack, ArcText } from '@src/core/ui';
import { rootLinks } from '../../config/nav-links';
import { SidebarProps } from './types';
import { useConfirm } from '@src/core/controllers';
import { useGetMyProfileQuery } from '@src/features/profile/redux';
import { UserEntity } from '@src/features/profile/entites';
import { ACCESS_TOKEN_KEY } from '@src/core/config';


const Sidebar: React.FC<SidebarProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { confirm } = useConfirm();
  const { data = {} as UserEntity } = useGetMyProfileQuery();
    
  const logout = async () => {
    if (await confirm()) {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      window.location.reload();
    }
  };
    

  return (
    <ArcStack 
      p={2}
      pt={5}
      width={80} 
      spacing={1}
      top={0}
      position='fixed'
      minHeight='100vh'
      justifyContent='space-between' 
      bgcolor='background.paper'>
      <ArcStack spacing={1}>
        {rootLinks.map((link) => {
          const isPath = link.exact.includes(location.pathname);
          const navIcon = isPath ? link.activeIcon : link.icon;
          const navColor = isPath ? 'brand.main' : 'text.primary';

          const hasPermission = link.roles.includes(data.role);

          if (hasPermission) {
        
            return (
              <ArcIconButton 
                key={link.id}
                onClick={() => navigate(link.path)}
                sx={{ minWidth: 50 }}>
                <ArcStack minWidth={50} spacing='4px' alignItems='center'>
                  <ArcStack width={18} minWidth={18}>
                    {navIcon}
                  </ArcStack>
                  <ArcText.button fontSize={9} color={navColor}> 
                    {link.name} 
                  </ArcText.button>
                </ArcStack>
              </ArcIconButton>
            );
          }
          return null;
        })}
      </ArcStack>


      <ArcIconButton onClick={logout}>
        <ArcStack minWidth={50} spacing='4px' alignItems='center'>
          <LogoutIcon sx={{ fontSize: 18, color: 'error.dark' }} />
          <ArcText.button fontSize={9} color='error.dark'> 
            logout 
          </ArcText.button>
        </ArcStack>
      </ArcIconButton>

    </ArcStack>
  );
};

export default Sidebar;