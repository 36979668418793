import React from 'react';
import { ArcButtonProps, ArcButtonVariantProps } from './types';
import { Button } from '@mui/material';
import ArcText from '../arc-text';
import ArcCircularProgress from '../arc-circular-progress';

const ArcButton: React.FC<ArcButtonProps> & ArcButtonVariantProps = (props) => {
  const { children, sx, isLoading, ...rest } = props;

  return (
    <Button 
      {...rest} 
      sx={sx}
      disabled={isLoading}
      startIcon={isLoading ? <ArcCircularProgress size={18} sx={{ color: 'action.disabled' }} /> : null}> 
      {children} 
    </Button>
  );
}; 

ArcButton.ghost = ({ children, ...rest }) => (
  <ArcButton variant='contained' color='ghost' {...rest}>
    <ArcText.button disabled={rest.isLoading}>
      {children}
    </ArcText.button>
  </ArcButton>
);

ArcButton.brand = ({ children, ...rest }) => {
  return (
    <ArcButton variant='contained' color='brand' {...rest}>
      <ArcText.button disabled={rest.isLoading} color='text.night'>
        {children}
      </ArcText.button>
    </ArcButton>
  );
};

ArcButton.tertiary = ({ children, ...rest }) => (
  <ArcButton variant='text' color='brand' {...rest}>
    <ArcText.button disabled={rest.isLoading} color='brand.main'>
      {children}
    </ArcText.button>
  </ArcButton>
);

export default ArcButton;