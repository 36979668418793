import { useDispatch } from "react-redux";
import { setConfirm } from "../../redux";

let resolveCallback: (value: unknown) => void;

const useConfirm = () => {
  const dispatch = useDispatch();

  const okCallback = () => {
    close();
    resolveCallback(true);
  };
  const noCallback = () => {
    close();
    resolveCallback(false);
  };

  const close = () => {
    dispatch(setConfirm({ open: false }));
  };

  const confirm = (description?: string) => {
    dispatch(
      setConfirm({
        open: true,
        description: description,
      }),
    );

    return new Promise((callback) => {
      resolveCallback = callback;
    });
  };

  return {
    confirm,
    okCallback,
    noCallback,
  };
};

export default useConfirm;

