import React from 'react';
import { ArcAlert, ArcText } from '@src/core/ui';
import { useFlightProvider } from '@src/features/order/provider';
import { EnRouteStatusMessageProps } from './types';

const EnRouteStatusMessage: React.FC<EnRouteStatusMessageProps> = () => {
  const { status } = useFlightProvider();

  if (!status?.text || !status.type) return null;

  return (
    <ArcAlert severity='warning'>
      <ArcText.h6 color='warning.main'> {status?.text} </ArcText.h6>
    </ArcAlert>
  );
};

export default EnRouteStatusMessage;