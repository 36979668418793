export enum EnRouteCommandEnum {

    // command
    CONTINUE = 'CONTINUE',
    START_CONTROL = 'START_MANUAL_CONTROL',
    WINCH_DELIVERY = 'WINCH_DELIVERY',
    DELIVERY_COMPLETED = 'DELIVERY_COMPLETED',

    // camera_command
    ZOOM_CONTROL = 'ZOOM_CONTROL',
    GIMBAL_CONTROL = 'GIMBAL_CONTROL',
}