import React from 'react';
import { ArcStack } from '@src/core/ui';
import { OrdersWrapperView, ToggleCollapseView } from '../../components';
import { TakeOffSectionProps } from './types';
import { ArcDrone } from '@src/core/components';
import { OrderStatusEnum } from '@src/features/order/entities';

const TakeOffSection: React.FC<TakeOffSectionProps> = (props) => {
  const { orders, selected, onSelectOrder } = props;
  return (
    <ArcStack>
      <ToggleCollapseView 
        count={orders.length}
        status={OrderStatusEnum.READY_FOR_TAKE_OFF}>
        
        <OrdersWrapperView>
          {orders.map((order) => (
            <ArcDrone.primary 
              key={order.missionId}
              width='calc(50% - 4px)' 
              drone={order.drone ?? {}}
              selected={order.missionId === selected?.missionId}
              onClick={() => onSelectOrder(order, OrderStatusEnum.READY_FOR_TAKE_OFF)}  />
          ))}
        </OrdersWrapperView>

      </ToggleCollapseView>
    </ArcStack>
  );
};

export default TakeOffSection;