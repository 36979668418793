import { axiosBaseQuery } from '@src/service';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { IGetSwitchResponse, IGetUserResponse, IResetUserPasswordRequest, ISetUserRoleRequest } from './types';
import { SettingEntity, SystemSwitchStatusEnum } from '../entities';
import { SETTING_UNITS_KEY } from '@src/core/config';


export const settingApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'settingApi',
  tagTypes: ['Switch', 'Users', 'Settings'],
  endpoints: (builder) => ({
    getSettings: builder.query<SettingEntity[], void>({
      query: () => ({
        url: '/api/v1/orders/settings',
        method: 'GET',
      }),
      providesTags: ['Settings'],
      transformResponse(settings: SettingEntity[]) {
        const unit = settings.find((u) => u.key === SETTING_UNITS_KEY);
        
        if (typeof unit !== 'undefined') localStorage.setItem(SETTING_UNITS_KEY, unit.value);
  
        return settings;
      },
    }),
    setSettings: builder.mutation<void, SettingEntity[]>({
      query: (data) => ({
        url: '/api/v1/orders/settings',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Settings']
    }),

    getSystemSwitch: builder.query<IGetSwitchResponse, void>({
      query: () => ({
        url: '/api/v1/orders/switch',
        method: 'GET',
      }),
      providesTags: ['Switch'],
    }),

    setSystemSwitch: builder.mutation<void, SystemSwitchStatusEnum>({
      query: (status) => ({
        url: `/api/v1/orders/switch/${status}`,
        method: 'POST',
      }),
      invalidatesTags: ['Switch'],
    }),

    getUsers: builder.query<IGetUserResponse, void>({
      query: () => ({
        url: '/api/v1/user',
        method: 'GET',
      }),
      providesTags: ['Users'],
    }),
    getUserRoles: builder.query<string[], void>({
      query: () => ({
        url: '/api/v1/user/role',
        method: 'GET',
      })
    }),
    setUserRole: builder.mutation<void, ISetUserRoleRequest>({
      query: (data) => ({
        url: '/api/v1/user/role',
        method: 'PUT',
        data
      }),
      invalidatesTags: ['Users'],
    }),
    resetUserPassword: builder.mutation<void, IResetUserPasswordRequest>({
      query: ({ id, data }) => ({
        url: `/api/v1/user/password/${id}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Users'],
    }),

  })
});

export const { 
  useGetSettingsQuery, 
  useGetSystemSwitchQuery, 

  useResetUserPasswordMutation,
  useSetSystemSwitchMutation,
  useSetSettingsMutation,

  useGetUsersQuery, 
  useGetUserRolesQuery,
  useSetUserRoleMutation,
} = settingApi;