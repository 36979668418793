import React from 'react';
import { ArcSkeleton, ArcStack } from '@src/core/ui';
import { 
  EnRouteHeader, 
  EnRouteBattery, 
  EnRouteDroneInfo,
  EnRouteIndicator, 
  EnRouteFlightData,
  EnRouteManualControl,
  EnRouteCamera,
  EnRouteStatusMessage,
  EnRouteEmergencyButtons
} from './components';
import { useParams } from 'react-router-dom';
import { useGetMissionOrderQuery } from '../../redux';
import { ArcSocketContext, ChannelRequestEnum } from '@src/core/socket';
import { EnRouteSidebarProps } from './types';

const EnRouteSidebar: React.FC<EnRouteSidebarProps> = (props) => {
  const { viewer } = props;

  const { missionId } = useParams();
  const { data, isLoading } = useGetMissionOrderQuery(missionId as string);
  const { arcSocket, readyState } = React.useContext(ArcSocketContext);

  const serialNumber = data?.drone?.serialNumber;

  React.useEffect(() => {
    const socket = arcSocket();
    if (socket === null || socket.readyState !== WebSocket.OPEN) return;

    const payload = { type: ChannelRequestEnum.GET_FLIGHT_DATA, data: { missionId  } };
    socket.send(JSON.stringify(payload));
    
    return () => {
      const payload = { type: ChannelRequestEnum.STOP_FLIGHT_DATA };
      socket.send(JSON.stringify(payload));
    };
  }, [readyState]);


  if (isLoading) {
    return (
      <ArcStack spacing={2}>
        <ArcSkeleton height={50} />
        <ArcSkeleton height={80} />
        <ArcSkeleton height={50} />
        <ArcStack spacing={1} direction='row' justifyContent='center'>
          <ArcSkeleton width={181} height={150} />
          <ArcSkeleton width={181} height={150} />
        </ArcStack>
        <ArcSkeleton height={80} />
        <ArcSkeleton height={80} />
      </ArcStack>
    );
  }



  return (
    <React.Fragment>
      <ArcStack spacing={2}>
        <EnRouteHeader drone={data?.drone} />
        <EnRouteDroneInfo drone={data?.drone} />
        <EnRouteBattery />
        {viewer ? null : <EnRouteEmergencyButtons />}
        <EnRouteIndicator />
        <EnRouteFlightData />
        <EnRouteStatusMessage />
        {viewer ? null :<EnRouteManualControl drone={data?.drone} />}
      </ArcStack>
      {serialNumber && <EnRouteCamera serialNumber={serialNumber} />}
    </React.Fragment>
  );
};

export default EnRouteSidebar;