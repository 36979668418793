import { reducer } from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import { rtkErrorLogger } from './rtk-logger';
import { ControllerMiddlewares } from '@src/core/controllers/store';
import { OrderFeatureMiddlewares } from '@src/features/order/store';
import { IntegrationFeatureMiddlewares } from '@src/features/integration/store';
import { ProfileFeatureMiddlewares } from '@src/features/profile/store';
import { DroneFeatureMiddlewares } from '@src/features/drone/store';
import { SettingsFeatureMiddlewares } from '@src/features/settings/store';
import { AuthFeatureMiddlewares } from '@src/features/auth/store';


const middlewares = [
  ...AuthFeatureMiddlewares,
  ...ControllerMiddlewares,
  ...OrderFeatureMiddlewares,
  ...DroneFeatureMiddlewares,
  ...ProfileFeatureMiddlewares,
  ...SettingsFeatureMiddlewares,
  ...IntegrationFeatureMiddlewares,
  rtkErrorLogger
];

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export { store };