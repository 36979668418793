import React from 'react';
import { ModifyRouteSidebarProps } from './types';
import { useModifyRoute } from './hooks';
import { ArcButton, ArcSkeleton, ArcStack, ArcText } from '@src/core/ui';
import { OrderItem } from './components';
import { ArcDrone } from '@src/core/components';
import { useNavigate } from 'react-router-dom';

const ModifyRouteSidebar: React.FC<ModifyRouteSidebarProps> = () => {
  const { 
    missionOrders, 
    selectedDrone, 
    selectedOrder, 

    isFetching, 
    isSubmitting,

    onToggle,
    onDragDrop, 
    onModifyRoute,
  } = useModifyRoute();
  const navigate = useNavigate();

  if (isFetching) {
    return (
      <ArcStack spacing={2}>
        <ArcSkeleton width={150} height={20} />
        <ArcStack spacing={1} my={1}>
          <ArcSkeleton width={120} height={20} />
          <ArcSkeleton height={75} />
          <ArcSkeleton height={75} />
        </ArcStack>
        <ArcStack spacing={1} my={1}>
          <ArcSkeleton width={120} height={20} />
          <ArcSkeleton height={75} />
          <ArcSkeleton height={75} />
        </ArcStack>
      </ArcStack>
    );
  }

  return (
    <ArcStack 
      height='100%' 
      position='relative' 
      justifyContent='space-between'>

      <ArcStack pb={10}>
        <ArcText.h4 fontWeight={500}> Plan route </ArcText.h4>

        <ArcStack my={2}>
          <ArcText.h5 my={1}> Mission Orders </ArcText.h5>
          <ArcStack spacing={1}>
            {missionOrders.map((order, itemIdx) => (
              <OrderItem 
                key={order.id} 
                order={order} 
                itemIdx={itemIdx}
                selectedOrder={selectedOrder}
                onToggle={onToggle}
                onDragDrop={onDragDrop} />
            ))}
          </ArcStack>
        </ArcStack>

        <ArcStack>
          <ArcText.h5 my={1}> Drone </ArcText.h5>
          {selectedDrone && <ArcDrone.primary drone={selectedDrone}/>}
        </ArcStack>

      </ArcStack>
      <ArcStack 
        p={2} 
        spacing={2} 
        width={368} 
        bottom={0}
        direction='row'
        position='fixed'
        bgcolor='background.default'>
        <ArcButton.ghost 
          fullWidth 
          size='large'
          onClick={() => navigate(-1)}> 
          Cancel
        </ArcButton.ghost>

        <ArcButton.brand 
          fullWidth 
          size='large'
          isLoading={isSubmitting}
          onClick={onModifyRoute}> 
          Modify Route 
        </ArcButton.brand>
      </ArcStack>
    </ArcStack>
  );
};

export default ModifyRouteSidebar;