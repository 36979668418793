import React from 'react';
import { OrdersSectionProps } from './types';
import { ArcButton, ArcContent, ArcSkeleton, ArcStack, ArcText } from '@src/core/ui';
import { ArcOrder } from '@src/core/components';

const OrdersSection: React.FC<OrdersSectionProps> = (props) => {
  const { title, actionText, orders, isLoading, onClick, onToggle, selectedOrder } = props;

  if (isLoading) {
    return (
      <ArcStack spacing={1} my={1}>
        <ArcSkeleton width={120} height={20}/>
        <ArcSkeleton height={75} />
        <ArcSkeleton height={75} />
      </ArcStack>
    );
  }

  return (
    <ArcStack my={2}>
      <ArcText.h5 my={1}> {title} </ArcText.h5>
      {orders.length > 0 ? 
        <ArcStack spacing={1}>
          {orders.map((order) => (
            <ArcOrder.primary 
              key={order.id} 
              order={order}
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              onClick={() => onToggle(order)}
              selected={selectedOrder?.id === order.id}>
              <ArcButton.tertiary 
                onClick={(e) => {
                  e.stopPropagation();
                  onClick(order);
                }}> 
                {actionText} 
              </ArcButton.tertiary>
            </ArcOrder.primary>
          ))}
        </ArcStack> : 
        <ArcContent>
          <ArcText.h5 textAlign='center'> No orders </ArcText.h5>
        </ArcContent>
      }
    </ArcStack>
  );
};

export default OrdersSection;