import React from 'react';
import { ArcStack } from '@src/core/ui';
import { OrdersWrapperView, ToggleCollapseView } from '../../components';
import { ArcDrone } from '@src/core/components';
import { useNavigate } from 'react-router-dom';
import { EnRouteSectionProps } from './types';
import { OrderStatusEnum } from '@src/features/order/entities';

const EnRouteSection: React.FC<EnRouteSectionProps> = (props) => {
  const { orders } = props;
  const navigate = useNavigate();

  return (
    <ArcStack>
      <ToggleCollapseView 
        count={orders.length} 
        status={OrderStatusEnum.EN_ROUTE}>
        <OrdersWrapperView>
          {orders.map((order) => (
            <ArcDrone.primary 
              key={order.missionId}
              width='calc(50% - 4px)' 
              drone={order.drone ?? {}}
              onClick={() => navigate(`en-route/${order.missionId}`)}  />
          ))}
        </OrdersWrapperView>
      </ToggleCollapseView>
    </ArcStack>
  );
};

export default EnRouteSection;