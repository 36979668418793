import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetMissionOrderQuery, useModifyRouteMutation } from '@src/features/order/redux';
import { resetState, selectDrone, selectMissionOrders, selectOrder, selectWayPoints, setMissionOrders, setSelectedDrone, setSelectedOrder, setWayPoints } from '../redux';
import { OrderEntity } from '@src/features/order/entities';
import { setMapLocation } from '@src/core/controllers';
import { toast } from 'react-toastify';

export const useModifyRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { missionId } = useParams();
  const waypoints = useSelector(selectWayPoints);
  const selectedDrone = useSelector(selectDrone);
  const selectedOrder = useSelector(selectOrder);
  const missionOrders = useSelector(selectMissionOrders);

  const [ getMissionOrder, { isFetching } ]= useLazyGetMissionOrderQuery();
  const [ modifyRoute, { isLoading: isSubmitting } ] = useModifyRouteMutation();

  const onModifyRoute = () => {
    modifyRoute({ missionId, waypoints }).unwrap().then(() => {
      toast.success('Modify Route Success Text');
      navigate(-1);
    });
  };
  

  const onDragDrop = (dragIdx: number, hoverIdx: number) => {
    const current = [...missionOrders];
    const temp = current[dragIdx];
    current[dragIdx] = current[hoverIdx];
    current[hoverIdx] = temp;
    dispatch(setMissionOrders(current));
  };

  const onToggle = (order: OrderEntity) => {
    if (order.id === selectedOrder?.id) {
      dispatch(setSelectedOrder(null));
      return;
    }
    dispatch(setSelectedOrder(order));

    const latitude = Number(order.deliveryLocation.latitude);
    const longitude = Number(order.deliveryLocation.longitude);

    if (isNaN(latitude) || isNaN(longitude)) return;

    dispatch(setMapLocation({ latitude, longitude }));
  };

  React.useEffect(() => {
    getMissionOrder(missionId).unwrap().then((missionOrder) => {
      dispatch(setWayPoints(missionOrder.waypoints ?? []));
      dispatch(setSelectedDrone(missionOrder.drone ?? null));
      dispatch(setMissionOrders(missionOrder.orders ?? []));
    });

    return () => { dispatch(resetState()); };
  }, [missionId]);

  return {
    isFetching,
    isSubmitting,
    missionOrders,
    selectedDrone,
    selectedOrder,

    onToggle,
    onDragDrop,
    onModifyRoute,
  };
};