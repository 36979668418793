import React from 'react';
import { Stack } from '@mui/material';
import { ArcStackProps } from './types';

const ArcStack: React.FC<ArcStackProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <Stack {...rest}> 
      {children}
    </Stack>
  );
};

export default ArcStack;