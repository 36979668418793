import React from 'react';
import { ArcStack } from '@src/core/ui';
import { OrdersWrapperViewProps } from './types';

const OrdersWrapperView: React.FC<OrdersWrapperViewProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <ArcStack 
      my={2} 
      gap={1}
      width='100%'
      direction='row'
      flexWrap='wrap'
      {...rest}>
      {children}
    </ArcStack>
  );
};

export default OrdersWrapperView;