import React from 'react';
import { ArcOutlinedInputProps } from './types';
import { TextField } from '@mui/material';

const ArcOutlinedInput = React.forwardRef<HTMLInputElement, ArcOutlinedInputProps>(
  ({ ...props }, ref) => {
    return <TextField 
      size='small'
      variant="outlined" 
      inputRef={ref} 
      {...props} />;
  }
);

ArcOutlinedInput.displayName = 'ArcOutlinedInput';

export default ArcOutlinedInput;