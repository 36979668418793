export enum TelemetryTypeEnum {
    Armed = 'armed',
    Health = 'health',
    RawGps = 'raw_gps',
    Battery = 'battery',
    Heading = 'heading',
    StatusText = 'status_text',
    VelocityNed = 'velocity_ned',
    AttitudeEuler = 'attitude_euler',
    DistanceSensor = 'distance_sensor',
}