import React from 'react';
import { useGetMyProfileQuery, useSetUserPasswordMutation } from '../../redux';
import { ArcStack } from '@src/core/ui';
import { MyProfileProps } from './types';
import { useDisclosure } from '@src/core/hooks';
import { IUserChangePasswordForm } from '../../entites';
import { toast } from 'react-toastify';
import { ChangePasswordForm, HubContent, ProfileContent } from '../../components';

const MyProfile: React.FC<MyProfileProps> = () => {
  
  const { open, show, hide } = useDisclosure();
  const [ setPassword, { isLoading: isSubmitting } ] = useSetUserPasswordMutation();
  const { data, isLoading } = useGetMyProfileQuery();

  const onSetPassword = (form: IUserChangePasswordForm) => {
    setPassword(form).unwrap().then(() => {
      toast.success('Set Password Success Text');
      hide();
    });
  };

  return (
    <React.Fragment>
      <ArcStack 
        pt={8}
        direction='row'
        alignItems='flex-start'
        justifyContent='center'>

        <ArcStack
          spacing={2}
          width='100%'
          maxWidth={700}>

          <ProfileContent 
            profile={data} 
            isLoading={isLoading} 
            onClickPassword={show} />

          <HubContent 
            profile={data} 
            isLoading={isLoading} />

        </ArcStack>
    
      </ArcStack>

      <ChangePasswordForm 
        open={open} 
        onClose={hide} 
        onSubmit={onSetPassword}
        isSubmitting={isSubmitting}
      />
    </React.Fragment>
  );
};

export default MyProfile;