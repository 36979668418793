import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { DronesPage, AddDronePage } from './pages';

const OrderFeature: React.FC = () => {
  return (
    <Routes>
      <Route index element={<DronesPage />}/>
      <Route path='/add-drone' element={<AddDronePage />}/>
    </Routes>
  );
};

export default OrderFeature;