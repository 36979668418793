import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { ArcCellInfo, ArcIconButton, ArcSkeleton, ArcStack } from '@src/core/ui';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { DEFAULT_GOOGLE_MAP_LATITUDE, DEFAULT_GOOGLE_MAP_LONGITUDE } from '@src/core/controllers';
import { HubContentProps } from './types';
import { EMPTY_STRING } from '@src/core/config';
import { UserRoleTypeEnum } from '../../entites';
import { useNavigate } from 'react-router-dom';

const HubContent: React.FC<HubContentProps> = (props) => {
  const { profile, isLoading } = props;

  const navigate = useNavigate();

  const lat = Number(profile?.hub?.latitude);
  const lng = Number(profile?.hub?.longitude);

  if (isLoading) return <ArcSkeleton height={300} />;
  
  return (
    <ArcStack 
      p={3} 
      spacing={2}
      borderRadius={1}
      bgcolor='background.paper'>

      <ArcStack spacing={1}>
        {profile?.role === UserRoleTypeEnum.ADMIN && 
          <ArcStack 
            spacing={1}
            direction='row' 
            justifyContent='flex-end' 
            onClick={() => navigate('update-hub')}>
            <ArcIconButton size='small'>
              <EditIcon sx={{ fontSize: 16 }} />
            </ArcIconButton>
          </ArcStack>
        }
        {profile?.hub?.name && (
          <ArcCellInfo label='Name'>
            {profile?.hub?.name ?? EMPTY_STRING}
          </ArcCellInfo>
        )}
        {profile?.hub?.description && (
          <ArcCellInfo label='Description'>
            {profile?.hub?.description ?? EMPTY_STRING}
          </ArcCellInfo>
        )}
        {profile?.hub?.contacts && (
          <ArcCellInfo label='Contacts'>
            {profile?.hub?.contacts ?? EMPTY_STRING}
          </ArcCellInfo>
        )}
        {profile?.hub?.altitude  && (
          <ArcCellInfo label='Altitude'>
            {profile?.hub?.altitude ?? EMPTY_STRING}
          </ArcCellInfo>
        )}
      </ArcStack>

      <GoogleMap 
        zoom={10}
        center={{ 
          lat: isNaN(lat) ? DEFAULT_GOOGLE_MAP_LATITUDE : lat,
          lng: isNaN(lng) ?  DEFAULT_GOOGLE_MAP_LONGITUDE : lng,
        }}
        mapContainerStyle={{ 
          width: '100%',
          height: '300px',
        }}
        options={{
          zoomControl: false,
          scaleControl: false,
          rotateControl: false,
          mapTypeControl: false,
          streetViewControl: false,
        }}>
        <Marker 
          position={{ 
            lat: isNaN(lat) ? DEFAULT_GOOGLE_MAP_LATITUDE : lat, 
            lng: isNaN(lng) ?  DEFAULT_GOOGLE_MAP_LONGITUDE : lng, 
          }}/>
      </GoogleMap>
    </ArcStack>
  );
};

export default HubContent;