import React from 'react';

const useDisclosureData = <T>() => {
  const [data, setData] = React.useState<T | null>(null);

  const hide = () => setData(null);
  const show = (newData: T) => setData(newData);

  return {
    open: Boolean(data),
    show,
    hide,
    data,
  };
};

export default useDisclosureData;
