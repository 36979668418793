import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthSubmitProps } from './types';
import { ArcButton, ArcStack } from '@src/core/ui';

const AuthSubmit: React.FC<AuthSubmitProps> = (props) => {
  const { buttonText, toText, to, isLoading } = props;

  const navigate = useNavigate();

  return (
    <ArcStack 
      my={2}
      gap={1}
      mx='auto'
      width='100%'
      maxWidth={300}>
      <ArcButton.brand type='submit' isLoading={isLoading}> {buttonText} </ArcButton.brand>
      <ArcButton.tertiary onClick={() => navigate(to)}> {toText} </ArcButton.tertiary>
    </ArcStack> 
  );
};

export default AuthSubmit;