import { EMPTY_STRING } from "../config";

export const arcLatToDMS = (value?: number) => {
  if (!value) return EMPTY_STRING;

  const direction = value >= 0 ? 'N' : 'S';

  return arcToDMS(value) + direction;
};

export const arcLngToDMS = (value?: number) => {
  if (!value) return EMPTY_STRING;

  const direction = value >= 0 ? 'E' : 'W';

  return arcToDMS(value) + direction;
};

const arcToDMS = (value: number) => {
  const degrees = Math.floor(Math.abs(value));
  const minutesFloat = (Math.abs(value) - degrees) * 60;
  const minutes = Math.floor(minutesFloat);
  const seconds = (minutesFloat - minutes) * 60;

  return `${degrees}° ${minutes}' ${seconds.toFixed(2)}" `;
};