import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IOrderInitialState } from "./types";
import initialState from "./state";


const setCollapse: CaseReducer<IOrderInitialState, PayloadAction<IOrderInitialState['collapse']>> = (state, action) => {
  state.collapse = action.payload;
};

const setSelectedOrder: CaseReducer<IOrderInitialState, PayloadAction<IOrderInitialState['selectedOrder']>> = (state, action) => {
  state.selectedOrder = action.payload;
};

export default { setCollapse, setSelectedOrder };