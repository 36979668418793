import { OrderWayPointEntity, OrderWayPointTypeEnum } from "@src/features/order/entities";
import { LatLng } from "use-places-autocomplete";

export const swapWayPoints = (wayPoints: OrderWayPointEntity[], paths: LatLng[]) => {
  return wayPoints.map((wp, idx) => {
    if (wp.type === OrderWayPointTypeEnum.WAY_POINT) {
      return {
        ...wp,
        latitude: paths[idx]?.lat,
        longitude: paths[idx]?.lng,
      };
    }
    return wp;
  });
    
};
