import React from 'react';
import { Marker } from '@react-google-maps/api';
import idleDrone from '@src/assets/drone-icons/idle.svg';
import errorDrone from '@src/assets/drone-icons/error.svg';
import defaultDrone from '@src/assets/drone-icons/default.svg';

import { ArcDroneMarkerProps, ArcDroneMarkerVariant } from './types';

const ArcDroneMarker: React.FC<ArcDroneMarkerProps> & ArcDroneMarkerVariant = (props) => {
  const { ...rest } = props;
  return <Marker {...rest} />;
};

ArcDroneMarker.idle = (props) => (
  <ArcDroneMarker 
    icon={{
      url: idleDrone,
      scaledSize: new window.google.maps.Size(50, 50),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(25, 25),
    }}  
    {...props} />
);


ArcDroneMarker.error = (props) => (
  <ArcDroneMarker icon={{
    url: errorDrone,
    scaledSize: new window.google.maps.Size(50, 50),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(25, 25),
  }} 
  {...props} />
);

ArcDroneMarker.default = (props) => (
  <ArcDroneMarker icon={{
    url: defaultDrone,
    scaledSize: new window.google.maps.Size(50, 50),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(25, 25),
  }} 
  {...props} />
);

export default ArcDroneMarker;