import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { IModifyRouteInitialState } from "./types";
import initialState from "./state";

const resetState: CaseReducer<IModifyRouteInitialState, PayloadAction> = (state) => {
  return initialState;
};

const setSelectedDrone: CaseReducer<IModifyRouteInitialState, PayloadAction<IModifyRouteInitialState['selectedDrone']>> = (state, action) => {
  state.selectedDrone = action.payload;
};

const setSelectedOrder: CaseReducer<IModifyRouteInitialState, PayloadAction<IModifyRouteInitialState['selectedOrder']>> = (state, action) => {
  state.selectedOrder = action.payload;
};

const setWayPoints: CaseReducer<IModifyRouteInitialState, PayloadAction<IModifyRouteInitialState['wayPoints']>> = (state, action) => {
  state.wayPoints = action.payload;
};
  
const setMissionOrders: CaseReducer<IModifyRouteInitialState, PayloadAction<IModifyRouteInitialState['missionOrders']>> = (state, action) => {
  state.missionOrders = action.payload;
};

export default { 
  resetState, 
  setWayPoints,
  setMissionOrders,
  setSelectedDrone, 
  setSelectedOrder,  
};