import React from 'react';
import { DroneListProps } from './types';
import { useDroneProvider } from '@src/features/drone/provider';
import { DroneEntity, DroneStatusEnum } from '@src/features/drone/entities';
import { ArcButton, ArcContent, ArcGrid, ArcSkeleton, ArcStack, ArcText } from '@src/core/ui';
import { ArcDroneIcon } from '@src/core/widgets';
import { DroneRegisterForm } from '@src/features/drone/components';
import { useDisclosureData } from '@src/core/hooks';

const DroneList: React.FC<DroneListProps> = () => {
  const { data, open, hide, show } = useDisclosureData<DroneEntity>();
  const { drones, isLoading } = useDroneProvider(DroneStatusEnum.NOT_ACTIVE);

  if (isLoading) {
    return (
      <ArcGrid container spacing={4}>
        <ArcGrid size={2}><ArcSkeleton height={100} /></ArcGrid>
        <ArcGrid size={2}><ArcSkeleton height={100} /></ArcGrid>
        <ArcGrid size={2}><ArcSkeleton height={100} /></ArcGrid>
        <ArcGrid size={2}><ArcSkeleton height={100} /></ArcGrid>
      </ArcGrid>
    );
  }


  if (drones.length < 1) {
    return (
      <ArcContent> 
        <ArcText.h5 textAlign='center'> No drones </ArcText.h5>
      </ArcContent>
    );
  }

  return (
    <React.Fragment>
      <DroneRegisterForm 
        open={open}
        drone={data}
        onClose={hide}
        title='Register drone' 
      />
    
      <ArcGrid container spacing={4}>
        {drones.map((drone) => (
          <ArcGrid key={drone.id} size={2}>
            <ArcStack 
              p={2} 
              spacing={1}
              borderRadius={1} 
              bgcolor='background.paper'>
              <ArcStack 
                spacing={1}
                direction='row' 
                alignItems='center'>
                <ArcDroneIcon />
                <ArcText.h6> {drone.serialNumber} </ArcText.h6>
              </ArcStack>
              <ArcButton.tertiary onClick={() => show(drone)}>
                Register
              </ArcButton.tertiary>
            </ArcStack>
          </ArcGrid>
        ))}
      </ArcGrid>
    </React.Fragment>
  );
};

export default DroneList;