import React from 'react';
import { useGetOrdersQuery } from '../../redux';
import { OrderGroupEntity } from '../../entities';
import { IOrderContext } from './types';
import { ArcSocketContext } from '@src/core/socket/base';
import { ChannelResponseEnum } from '@src/core/socket';

export const OrderContext = React.createContext<IOrderContext>({
  isLoading: false,
  refetch: () => {},
  data: {} as OrderGroupEntity,
});

export const OrderProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { subscribe, unsubscribe } = React.useContext(ArcSocketContext);
  const { data = {} as OrderGroupEntity, refetch, isLoading } = useGetOrdersQuery();

  React.useEffect(() => {
    subscribe(ChannelResponseEnum.UPDATE_ORDERS, () => refetch());
    return () => unsubscribe(ChannelResponseEnum.UPDATE_ORDERS);
  }, [subscribe, unsubscribe]);

  return (
    <OrderContext.Provider 
      value={{
        data, 
        refetch, 
        isLoading,
      }}>
      {children}
    </OrderContext.Provider>
  );
};
