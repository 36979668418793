import React from 'react';
import { ArcButton, ArcMenuItem, ArcOutlinedInput, ArcOutlinedSelect, ArcStack, ArcText } from '@src/core/ui';
import { InputAdornment } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { IOrderForm, OrderFormViewProps } from './types';
import { DEFAULT_VALUES, methods } from './constants';
import { useNavigate } from 'react-router-dom';
import { ArcGoogleSearch } from '@src/core/components';
import { useDispatch, useSelector } from 'react-redux';
import { resetMapLocation, selectMapLocation, setMapLocation } from '@src/core/controllers';
import { EMPTY_VALUE } from '@src/core/config';
import { arcSpatium, arcVelocitas } from '@src/core/extensions';

const OrderFormView: React.FC<OrderFormViewProps> = (props) => {
  const { submitText, isLoading, onSubmit, defaultValues } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useSelector(selectMapLocation);
  
  const { 
    control, 
    register, 
    setValue,
    formState: { errors }, 
    handleSubmit } = useForm<IOrderForm>({ defaultValues: defaultValues ?? DEFAULT_VALUES });

  React.useEffect(() => {
    if (!location) return;
    
    setValue('address', location.address ?? EMPTY_VALUE);
    setValue('latitude', String(location.latitude));
    setValue('longitude', String(location.longitude));
  }, [location, setValue]);

  React.useEffect(() => {
    return () => { dispatch(resetMapLocation()); };
  }, []);

  
  return (
    <ArcStack 
      height='100%' 
      component='form'
      justifyContent='space-between'
      onSubmit={handleSubmit(onSubmit)}>

      <ArcStack spacing={3}>
    
        <ArcStack spacing={2}>
          <ArcText.h4 fontWeight={500}> CREATE ORDER </ArcText.h4>
          <Controller 
            control={control}
            name='address'
            rules={{ required: 'This field is required' }}
            render={({ field: { value } }) => (
              <ArcGoogleSearch 
                initValue={value}
                error={Boolean(errors?.address)}
                helperText={errors.address?.message}
                label='Delivery Location' 
                onSelect={(lat, lng, address) => {
                  setValue('address', address);
                  setValue('latitude', String(lat));
                  setValue('longitude', String(lng));

                  dispatch(setMapLocation({
                    latitude: lat,
                    longitude: lng,
                    address: address,
                  }));
                }} />
            )}/>
          
            
          
          <Controller 
            control={control} 
            name='deliveryMethod' 
            rules={{ required: 'This field is required' }}
            render={({ field: { value, onChange } }) => (
              <ArcOutlinedSelect
                label='Delivery Method'
                value={value}
                onChange={onChange}
                helperText={errors.deliveryMethod?.message}
                error={Boolean(errors.deliveryMethod)}>
                {methods.map((method) => (
                  <ArcMenuItem 
                    key={method} 
                    value={method}> 
                    {method} 
                  </ArcMenuItem>
                ))}
              </ArcOutlinedSelect>
            )} />


          <ArcOutlinedInput 
            autoComplete='off'
            error={Boolean(errors.packageContent)} 
            helperText={errors.packageContent?.message}
            label='Package Content' 
            {...register('packageContent')} />
          <ArcOutlinedInput
            autoComplete='off'
            error={Boolean(errors.clientName)} 
            helperText={errors.clientName?.message}
            label='Client Name' 
            {...register('clientName')} />
        </ArcStack>

        <ArcStack spacing={2}>
          <ArcText.h5 fontWeight={500}> ROUTE </ArcText.h5>
          <ArcStack direction='row' spacing={2}>
            <ArcOutlinedInput
              type='number'
              autoComplete='off'
              error={Boolean(errors.altitude)}
              label='Flight Altitude' 
              {...register('altitude', { 
                required: 'This field is required' 
              })}
              slotProps={{ input: { 
                endAdornment: (
                  <InputAdornment position='end'> 
                    <ArcText.h5> {arcSpatium()} </ArcText.h5> 
                  </InputAdornment> 
                )
              }}} />

            <ArcOutlinedInput
              type='number'
              autoComplete='off'
              error={Boolean(errors.deliveryAltitude)}
              label='Delivery Altitude' 
              {...register('deliveryAltitude', { 
                required: 'This field is required' 
              })}
              slotProps={{ input: { 
                endAdornment: (
                  <InputAdornment position='end'> 
                    <ArcText.h5> {arcSpatium()} </ArcText.h5> 
                  </InputAdornment> 
                )
              }}} />
          </ArcStack>
          <ArcOutlinedInput
            type='number'
            autoComplete='off'
            error={Boolean(errors.speed)}
            label='Cruise Speed' 
            {...register('speed', { 
              required: 'This field is required' 
            })}
            slotProps={{ input: { 
              endAdornment: (
                <InputAdornment position='end'>
                  <ArcText.h5> {arcVelocitas()} </ArcText.h5>
                </InputAdornment> 
              )
            }}} />
        </ArcStack>
      </ArcStack>

      
      <ArcStack spacing={2} direction='row' px={1}>

        <ArcButton.ghost
          fullWidth 
          size='large'
          onClick={() => navigate(-1)}> 
          Cancel 
        </ArcButton.ghost>

        <ArcButton.brand 
          fullWidth
          size='large'
          type='submit'
          isLoading={isLoading}> 
          {submitText}
        </ArcButton.brand>
      </ArcStack>
      
    </ArcStack>
  );
};

export default OrderFormView;