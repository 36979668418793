import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AuthWrapper, AuthSubmit } from '../../components';
import { ISignUpForm } from './types';
import { useSignUpMutation } from '../../redux';
import { routesConfig } from '@src/core/routes';
import { ArcOutlinedInput, ArcPasswordInput, ArcStack } from '@src/core/ui';
import { REQUIRED_VALIDATION_TEXT } from '@src/core/config';

const SignUpPage: React.FC = () => {
  const navigate = useNavigate();
  const [signUp, { isLoading }] = useSignUpMutation();
  const { register, handleSubmit, formState: { errors } } = useForm<ISignUpForm>();

  const onSubmit: SubmitHandler<ISignUpForm> = async (form) => {
    signUp(form).unwrap()
      .then(() => {
        navigate(routesConfig.signIn);
      });
  };

  return (
    <AuthWrapper> 
      <ArcStack gap={1} component='form' onSubmit={handleSubmit(onSubmit)}>

        <ArcOutlinedInput 
          label='Email'
          autoComplete='off' 
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          {...register('email', { required: REQUIRED_VALIDATION_TEXT })}
        />

        <ArcStack gap={1} direction='row'>

          <ArcOutlinedInput 
            fullWidth
            label='First Name'
            autoComplete='off' 
            error={Boolean(errors.firstname)}
            helperText={errors.firstname?.message}
            {...register('firstname', { required: REQUIRED_VALIDATION_TEXT })}
          />

          <ArcOutlinedInput 
            fullWidth
            label='Last Name'
            autoComplete='off' 
            error={Boolean(errors.lastname)}
            helperText={errors.lastname?.message}
            {...register('lastname', { required: REQUIRED_VALIDATION_TEXT })}
          />

        </ArcStack>

        <ArcPasswordInput 
          label='Password'
          autoComplete='off' 
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          {...register('password', { required: REQUIRED_VALIDATION_TEXT })}
        />   

        <ArcPasswordInput 
          label='Re-enter Password'
          autoComplete='off' 
          error={Boolean(errors.password2)}
          helperText={errors.password2?.message}
          {...register('password2', { required: REQUIRED_VALIDATION_TEXT })}
        />  

        <ArcOutlinedInput 
          fullWidth
          label='Phone Number'
          autoComplete='off' 
          error={Boolean(errors.phone)}
          helperText={errors.phone?.message}
          {...register('phone', { required: REQUIRED_VALIDATION_TEXT })}
        />

        <ArcOutlinedInput 
          fullWidth
          label='Zip Code'
          autoComplete='off' 
          error={Boolean(errors.zip)}
          helperText={errors.zip?.message}
          {...register('zip', { required: REQUIRED_VALIDATION_TEXT })}
        />

        <ArcOutlinedInput 
          fullWidth
          label='City'
          autoComplete='off' 
          error={Boolean(errors.city)}
          helperText={errors.city?.message}
          {...register('city', { required: REQUIRED_VALIDATION_TEXT })}
        />
        <AuthSubmit 
          buttonText='SIGN UP' 
          toText='SIGN IN'
          to='/auth/signIn'
          isLoading={isLoading} />

      </ArcStack>

    </AuthWrapper>
  );
};

export default SignUpPage;