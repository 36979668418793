import React from 'react';
import { LinearProgress } from '@mui/material';

import { ArcProgressBarProps, ArcProgressBarVariantProps } from './types';

const ArcProgressBar: React.FC<ArcProgressBarProps> & ArcProgressBarVariantProps = (props) => {
  return (
    <LinearProgress 
      sx={{ 
        height: 6, 
        borderRadius: '2px', 
        backgroundColor: 'background.paper' 
      }} 
      {...props} />
  );
};

ArcProgressBar.success = (props) => (
  <ArcProgressBar color='success' variant='determinate' {...props} />
);

export default ArcProgressBar;