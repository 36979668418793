import React from 'react';
import { ArcBaseSidebarProps } from './types';
import { ArcStack } from '@src/core/ui';


const ArcBaseSidebar: React.FC<ArcBaseSidebarProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <ArcStack 
      p={2} 
      width={400} 
      overflow='auto' 
      height='calc(100vh - 40px)' 
      bgcolor='background.default'
      {...rest}>
      {children}
    </ArcStack>
  );
};

export default ArcBaseSidebar;