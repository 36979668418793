import React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { JwtKeysPageProps } from './types';
import { useCreateJwtKeyMutation, useDeleteJwtKeyMutation, useGetJwtKeysQuery } from '../../redux';
import { ArcButton, ArcIconButton, ArcSkeleton, ArcStack, ArcTable, ArcTableBody, ArcTableCell, ArcTableHead, ArcTableRow, ArcText, ArcTooltip } from '@src/core/ui';
import { useDisclosure } from '@src/core/hooks';
import { CopyContent, CreateJwtKey, ICreateJwtKeyForm } from './components';
import { toast } from 'react-toastify';
import { EMPTY_STRING } from '@src/core/config';
import { useConfirm } from '@src/core/controllers';

const JwtKeysPage: React.FC<JwtKeysPageProps> = () => {
  const { confirm } = useConfirm();
  const { data = [], isLoading } = useGetJwtKeysQuery();
  const [ deleteJwtKey, { isLoading: isDeleting } ] = useDeleteJwtKeyMutation();
  const [ createJwtKey, { isLoading: isSubmitting } ] = useCreateJwtKeyMutation();
  
  const { open, show, hide } = useDisclosure();

  const onCreateJwtKey = (data: ICreateJwtKeyForm) => {
    createJwtKey(data.name).unwrap().then(() => {
      toast.success('Create Jwt Key Success');
      hide();
    });
  };

  const onDeleteJwtKey = async (id: number) => {
    if (await confirm()) {
      deleteJwtKey(id).unwrap().then(() => {
        toast.success('Delete Jwt Key Success');
      });
    }
  };

  return (
    <React.Fragment>
      <CreateJwtKey 
        key={String(open)}
        open={open} 
        onClose={hide} 
        onSubmit={onCreateJwtKey}
        isSubmitting={isSubmitting}
      />

      <ArcStack p={5}>
        <ArcStack spacing={4}>

          <ArcStack spacing={1}>
            <ArcText.h2 fontWeight={500}> Credentials </ArcText.h2>
            <ArcText.h5> Access keys enable you to authenticate with ASEND API. </ArcText.h5>
          </ArcStack>

          {isLoading ? <TableLoading /> :

            <ArcStack spacing={2}>
              <ArcStack 
                spacing={1}
                direction='row' 
                alignItems='center' 
                justifyContent='space-between'>
                <ArcText.h3 fontWeight={500}> 
                  Access Keys
                </ArcText.h3>
                <ArcButton.brand size='large' onClick={show}> 
                  Create Access Key 
                </ArcButton.brand>
              </ArcStack>

              <ArcTable>
                <ArcTableHead>
                  <ArcTableRow>
                    <ArcTableCell 
                      sx={{ fontWeight: 600, fontSize: 11, border: 0 }}> 
                      NAME 
                    </ArcTableCell>
                    <ArcTableCell
                      sx={{ fontWeight: 600, fontSize: 11, border: 0 }}> 
                      DEVELOPER ID 
                    </ArcTableCell>

                    <ArcTableCell 
                      sx={{ fontWeight: 600, fontSize: 11, border: 0 }}> 
                      KEY ID 
                    </ArcTableCell>

                    <ArcTableCell 
                      sx={{ fontWeight: 600, fontSize: 11, border: 0 }}> 
                      SIGNING SECRET 
                    </ArcTableCell>

                    <ArcTableCell sx={{ border: 0 }} /> 
                  </ArcTableRow>
                </ArcTableHead>
                <ArcTableBody>
                  {data.map((jwt) => (
                    <ArcTableRow key={jwt.id}>
                      <ArcTableCell sx={{  border: 0 }}> 
                        {jwt.name ?? EMPTY_STRING} 
                      </ArcTableCell>
                      <CopyContent text={jwt.developerId} />
                      <CopyContent text={jwt.keyId} />
                      <CopyContent text={jwt.signingSecret} />
                      <ArcTableCell sx={{ border: 0 }}> 
                        <ArcIconButton 
                          isLoading={isDeleting} 
                          onClick={() => onDeleteJwtKey(jwt.id)}>
                          <DeleteOutlineIcon sx={{ fontSize: 20, color: 'error.dark' }} />
                        </ArcIconButton>
                      </ArcTableCell>
                    </ArcTableRow>
                  ))}
                </ArcTableBody>
              </ArcTable>
            </ArcStack>
          }
        </ArcStack>
      </ArcStack>
    </React.Fragment>
  );
};



const TableLoading = () => (
  <ArcStack spacing={1}>
    <ArcSkeleton height={50} />
    <ArcSkeleton height={50} />
    <ArcSkeleton height={50} />
    <ArcSkeleton height={50} />
  </ArcStack>
);

export default JwtKeysPage;