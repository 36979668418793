export enum ChannelResponseEnum {
    UPDATE_DRONES = 'UPDATE_DRONES',
    UPDATE_ORDERS = 'UPDATE_ORDERS',
    FLIGHT_DATA = 'FLIGHT_DATA',
    CAMERA_DATA = 'CAMERA_DATA',
    ORDER_STATUS = 'ORDER_STATUS',
    TELEMETRY = 'telemetry',
    WEBRTC = 'WEBRTC',
    WEBRTC_ANSWER = 'answer',
    WEBRTC_ICE_CANDIDATE = 'ice_candidate',
    COMMAND_STATUS = 'COMMAND_STATUS',
} 

export enum ChannelRequestEnum {
    WEBRTC = 'WEBRTC',
    GET_FLIGHT_DATA = 'GET_FLIGHT_DATA',
    STOP_FLIGHT_DATA = 'STOP_FLIGHT_DATA',
}

export type ChannelCallback = (args: any) => void;

export type ArcChannelType = {
    [key in ChannelResponseEnum]?: any;
};

export interface IArcSocketContext {
    readyState: boolean;
    arcSocket: () => WebSocket | null;
    subscribe: (channel: ChannelResponseEnum, callback: ChannelCallback) => void;
    unsubscribe: (channel: ChannelResponseEnum) => void;
}