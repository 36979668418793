import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { ArcIconButton, ArcStack, ArcTooltip } from '@src/core/ui';
import BrandImage from '@src/assets/img/brand.png';
import { HeaderProps } from './types';
import { useLocation, useNavigate } from 'react-router-dom';
import { HEADER_HEIGHT } from '../../config/const';
import { routesConfig } from '@src/core/routes';

const Header: React.FC<HeaderProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isProfilePage = location.pathname === routesConfig.profile;

  return (
    <ArcStack 
      py={2} 
      pr={2}
      pl={11}
      height={HEADER_HEIGHT}
      justifyContent='center' 
      bgcolor='background.paper'>
      <ArcStack spacing={1} direction='row' justifyContent='space-between'>
        <img src={BrandImage} alt='brand image' width={64} height={32} />

        <ArcTooltip title='Profile'>
          <ArcIconButton size='small' onClick={() => navigate(routesConfig.profile)}> 
            <PersonIcon sx={{ 
              fontSize: 18, 
              color: isProfilePage ? 'brand.main' : 'text.primary' }} 
            /> 
          </ArcIconButton>
        </ArcTooltip>
    
      </ArcStack>
    </ArcStack>
  );
};

export default Header;