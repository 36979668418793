import React from 'react';
import { EnRouteBatteryProps } from './types';
import { useFlightProvider } from '@src/features/order/provider';
import { ArcProgressBar, ArcStack, ArcText } from '@src/core/ui';
import { arcToFixed } from '@src/core/extensions';

const EnRouteBattery: React.FC<EnRouteBatteryProps> = () => {
  const { flightData, battery } = useFlightProvider();
    
  return (
    <ArcStack spacing={1}>
      <ArcProgressBar.success value={flightData.batteryHealth ?? 0} />
      <ArcStack spacing={1} direction='row' justifyContent='space-between'>
        <ArcStack spacing='4px' direction='row' alignItems='center'>
          <ArcText.caption fontWeight={500}>
            BATTERY:
          </ArcText.caption>
          <ArcText.caption fontWeight={500} color='text.primary'>
            {40 * (flightData?.batteryHealth ?? 0) / 100} MIN
          </ArcText.caption>
        </ArcStack>
        <ArcStack spacing='4px' direction='row' alignItems='center'>
          <ArcText.caption fontWeight={500}>
            VOLTAGE:
          </ArcText.caption>
          <ArcText.caption fontWeight={500} color='text.primary'>
            {arcToFixed(battery?.voltage_v)} V
          </ArcText.caption>
        </ArcStack>
      </ArcStack>
    </ArcStack>
  ); 
};

export default EnRouteBattery;