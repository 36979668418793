export enum DroneStatusEnum {
    IDLE = 'IDLE',
    NOT_ACTIVE = 'NOT_ACTIVE',
	ACTIVE = 'ACTIVE',
	STANDBY = 'STANDBY',
	ASSIGNED = 'ASSIGNED',
	SENT_FOR_AUTHORIZATION = 'SENT_FOR_AUTHORIZATION',
	AUTHORIZED = 'AUTHORIZED',
	NOT_AUTHORIZED = 'NOT_AUTHORIZED',
	MISSION = 'MISSION',
	BREAK = 'BREAK',
	RTL = 'RTL',
	LAND = 'LAND',
	NOT_USED = 'NOT_USED',
	ERROR = 'ERROR',
	OFFLINE = 'OFFLINE',
}
