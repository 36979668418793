import React from 'react';
import { IWeatherContext } from './types';
import { WeatherContentEntity } from './entities';
import { RECONNECT_TIME, WEATHER_SOCKET_URL } from '@src/core/config';
import { useGetMyProfileQuery } from '@src/features/profile/redux';
import { ProfileEntity } from '@src/features/profile/entites';

export const WeatherContext = React.createContext<IWeatherContext>({});

const WeatherProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const socket = React.useRef<WebSocket>();
  const [data, setData] = React.useState<WeatherContentEntity>();
  const { data: profile = {} as ProfileEntity } = useGetMyProfileQuery();

  const createSocket = React.useCallback(() => {
    const hubURL = `?hubId=${profile.hub.id}`;
    const socketURL = process.env.REACT_APP_WEATHER_SOCKET_URL ?? WEATHER_SOCKET_URL;
    socket.current = new WebSocket(socketURL + hubURL);

    socket.current.onclose = () => {
      setTimeout(() => createSocket(), RECONNECT_TIME);
    };

    socket.current.onmessage = (event) => {
      const weatherData = JSON.parse(event.data);
      setData(weatherData as WeatherContentEntity);
    };
  }, []);

  React.useEffect(() => {
    createSocket();
    return () => socket.current && socket.current.close();;
  }, [createSocket]);

  return (
    <WeatherContext.Provider value={{ data }}>
      {children}
    </WeatherContext.Provider>
  );
};

export default WeatherProvider;