import React from 'react';
import { WeatherContext } from '@src/core/socket';
import { ArcBox, ArcCellInfo, ArcCollapse, ArcStack, ArcText } from '@src/core/ui';
import { useDisclosure } from '@src/core/hooks';
import { EMPTY_STRING } from '@src/core/config';
import { arcTemperature, arcVelocitas } from '@src/core/extensions';


const WeatherContent: React.FC = () => {
  const { open, toggle } = useDisclosure();
  const { data } = React.useContext(WeatherContext);

  if (!data) return null;

  const sys = data?.sys ?? {};
  const main = data?.main ?? {};
  const wind = data?.wind ?? {};
  const clouds = data?.clouds ?? {};
  
  const [ weather ] = data.weather ?? [];

  return (
    <ArcBox position='absolute' right={60} top={5} zIndex={100}>
      <ArcStack alignItems='flex-end'>
        <ArcStack
          pl={2}
          pr={1}
          maxWidth={150}
          direction='row' 
          borderRadius={2}
          alignItems='center' 
          justifyContent='center'
          bgcolor='background.paper'
          sx={{ cursor: 'pointer' }}
          onClick={toggle}>
          <ArcText.h5> {main?.temp} </ArcText.h5>
          <ArcStack width={50} height={50}>
            <img src={`https://openweathermap.org/img/wn/${weather?.icon}@2x.png`} />
          </ArcStack>
        </ArcStack>
        <ArcCollapse in={open}>
          <ArcStack py={2} px={4} spacing={2} mt={1} maxWidth={300} borderRadius={2} bgcolor='background.paper'>
            <ArcStack spacing={4} direction='row' justifyContent='space-between'>
              <ArcCellInfo label='COUNTRY'>
                {sys?.country ?? EMPTY_STRING}
              </ArcCellInfo>
              <ArcCellInfo label='CLOUDS'>
                {clouds?.all ?? EMPTY_STRING}%
              </ArcCellInfo>
            </ArcStack>

            <ArcStack spacing={4} direction='row' justifyContent='space-between'>
              <ArcCellInfo label='HUMIDITY'>
                {main?.humidity ?? EMPTY_STRING}%
              </ArcCellInfo>

              <ArcCellInfo label='PRESSURE'>
                {main?.pressure ?? EMPTY_STRING}hPa
              </ArcCellInfo>
            </ArcStack>

            <ArcStack spacing={4} direction='row' justifyContent='space-between'>
              <ArcCellInfo label='WIND DIRECTION'>
                {wind?.deg ?? EMPTY_STRING}°
              </ArcCellInfo>

              <ArcCellInfo label='WIND SPEED'>
                {arcVelocitas(wind?.speed)}
              </ArcCellInfo>
            </ArcStack>

          </ArcStack>
        </ArcCollapse>
      </ArcStack>
    </ArcBox>
  );
};


export default WeatherContent;