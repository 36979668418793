import React from 'react';
import { IDroneContext } from './types';
import { ArcSocketContext, ChannelResponseEnum } from '@src/core/socket';
import { useGetDronesQuery } from '../../redux';

export const DroneContext = React.createContext<IDroneContext>({
  data: [],
  isLoading: false,
  refetch: () => {}
});

export const DroneProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { subscribe, unsubscribe } = React.useContext(ArcSocketContext);
  const { data = { data: [] }, refetch, isLoading } = useGetDronesQuery();

  React.useEffect(() => {
    subscribe(ChannelResponseEnum.UPDATE_DRONES, () => refetch());
    return () => unsubscribe(ChannelResponseEnum.UPDATE_DRONES);
  }, [refetch, subscribe, unsubscribe]);

  return (
    <DroneContext.Provider value={{ 
      refetch, 
      isLoading, 
      data: data.data, 
    }}>
      {children}
    </DroneContext.Provider>
  );

};
