import React from 'react';
import { EMPTY_VALUE, REQUIRED_VALIDATION_TEXT } from '@src/core/config';
import { useForm } from 'react-hook-form';
import { ArcButton, ArcDialog, ArcPasswordInput, ArcStack } from '@src/core/ui';
import { ChangePasswordFormProps } from './types';
import { IUserChangePasswordForm } from '../../entites';

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = (props) => {

  const { open, onClose, onSubmit, isSubmitting } = props;

  const { register, handleSubmit, formState: { errors } } = useForm<IUserChangePasswordForm>({ 
    defaultValues: { 
      oldPassword: EMPTY_VALUE,
      newPassword: EMPTY_VALUE, 
      newPassword2: EMPTY_VALUE, 
    }  
  });

  return (
    <ArcDialog open={open} onClose={onClose} title='Change password'>
      <ArcStack 
        p={2}
        spacing={2} 
        component='form' 
        onSubmit={handleSubmit(onSubmit)}>
        <ArcPasswordInput 
          autoComplete='off'
          error={Boolean(errors.oldPassword)} 
          helperText={errors.oldPassword?.message}
          label='Current Password' 
          {...register('oldPassword', { 
            required: REQUIRED_VALIDATION_TEXT 
          })} />

        <ArcPasswordInput 
          autoComplete='off'
          error={Boolean(errors.newPassword)} 
          helperText={errors.newPassword?.message}
          label='New password' 
          {...register('newPassword', { 
            required: REQUIRED_VALIDATION_TEXT 
          })} />


        <ArcPasswordInput 
          autoComplete='off'
          error={Boolean(errors.newPassword2)} 
          helperText={errors.newPassword2?.message}
          label='Confirm new password' 
          {...register('newPassword2', { 
            required: REQUIRED_VALIDATION_TEXT 
          })} />

        <ArcButton.brand type='submit' isLoading={isSubmitting}> Save </ArcButton.brand>
      </ArcStack>
    </ArcDialog>
  );
};

export default ChangePasswordForm;