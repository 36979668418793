import React from 'react';
import { toast } from 'react-toastify';
import { ArcSkeleton, ArcStack, ArcSwitch, ArcText } from '@src/core/ui';
import { SystemSwitchStatusEnum } from '@src/features/settings/entities';
import { useGetSystemSwitchQuery, useSetSystemSwitchMutation } from '@src/features/settings/redux';
import { WeatherSwitchProps } from './types';

const WeatherSwitch: React.FC<WeatherSwitchProps> = () => {

  const { data, isLoading: isSystemLoading } = useGetSystemSwitchQuery();
  const [ setSystemSwitch, { isLoading: isSubmitting } ] = useSetSystemSwitchMutation();

  const onSetSystemSwitch = (_: unknown, checked: boolean) => {
    setSystemSwitch(checked ? SystemSwitchStatusEnum.ON : SystemSwitchStatusEnum.OFF).unwrap()
      .then(() => {
        toast.success('SET SYSTEM SWITCH SUCCESS TEXT');
      });
  };

  if (isSystemLoading) {
    return (
      <ArcStack 
        spacing={1} 
        direction='row' 
        justifyContent='space-between'>
        <ArcSkeleton width={250} height={40} />
        <ArcSkeleton width={100} height={40} />
      </ArcStack>
    );
  }
  
  return (
    <ArcStack 
      direction='row' 
      alignItems='center' 
      justifyContent='space-between'>
      <ArcText.h4> WEATHER SYSTEM </ArcText.h4>
      <ArcSwitch.brand 
        disabled={isSubmitting || isSystemLoading}
        checked={data?.status === SystemSwitchStatusEnum.ON} 
        onChange={onSetSystemSwitch} />
    </ArcStack>
  );
};

export default WeatherSwitch;