import { 
  DEFAULT_GOOGLE_MAP_TYPE, 
  DEFAULT_GOOGLE_MAP_ZOOM,
  DEFAULT_GOOGLE_MAP_LATITUDE, 
  DEFAULT_GOOGLE_MAP_LONGITUDE, 
} from "../config";
import { IMapControllerInitialState } from "./types";

const initialState: IMapControllerInitialState = {
  type: DEFAULT_GOOGLE_MAP_TYPE,
  zoom: DEFAULT_GOOGLE_MAP_ZOOM,
  center: { lat: DEFAULT_GOOGLE_MAP_LATITUDE, lng: DEFAULT_GOOGLE_MAP_LONGITUDE },
};

export default initialState;