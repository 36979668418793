/* eslint-disable @typescript-eslint/no-explicit-any */
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const rtkErrorLogger: Middleware = () => (next) => (action: any) => {
  if (isRejectedWithValue(action)) {
    toast.error(
      action?.payload?.response?.data?.message ||
        action?.payload?.message ||
        'Something went wrong'
    );
  }
  return next(action);
};

