import { OrderStatusEnum } from "@src/features/order/entities";
import { IOrderInitialState } from "./types";

const initialState: IOrderInitialState = {
  selectedOrder: null,
  collapse: {
    [OrderStatusEnum.NEW]: false,
    [OrderStatusEnum.ASSIGNED]: false,
    [OrderStatusEnum.READY_FOR_TAKE_OFF]: false,
    [OrderStatusEnum.EN_ROUTE]: false,
    [OrderStatusEnum.COMPLETED]: false,
    [OrderStatusEnum.CANCELLED]: false,
    [OrderStatusEnum.ERROR]: false, 
  }
};

export default initialState;