import { ThemeOptions } from '@mui/material';

const Palette: Pick<ThemeOptions, 'palette'> = {
  palette: {
    
    divider: '#A0A0A0',
    
    background: {
      paper: '#272727',
      default: '#121212',
    },
    text: {
      night: '#121212',
      primary: '#FFFFFF',
      secondary: '#A0A0A0',
    },
    rose: {
      light: '#F9C1C1',
      main: '#3D1D1D',
      dark: '#C17070',
      contrastText: '#ffffff',
    },
    brand: {
      light: '#C1C1F9',
      main: '#9E9EF3',
      dark: '#7070C1',     
      contrastText: '#ffffff'
    },
    ghost: {
      light: '#858585',  
      main: '#5D5D5D',
      dark: '#404040',  
      contrastText: '#ffffff'
    },
    success: {
      light: '#81c784',
      main: '#66bb6a',
      dark: '#388e3c',
      contrastText: '#ffffff',
    },
    error: {
      light: '#E57373',
      main: '#C13E2C',
      dark: '#d32f2f',
      contrastText: '#ffffff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ffa726',
      dark: '#f57c00',
      contrastText: '#ffffff',
    }, 
    action: {
      active: '#FFFFFF',
      hover: 'rgba(255, 255, 255, 0.08)',
      selected: 'rgba(255, 255, 255, 0.16)',
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledOpacity: 0.3,
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      focus: 'rgba(255, 255, 255, 0.12)',
      hoverOpacity: 0.08,
      selectedOpacity: 0.16,
    },
    info: {
      light: '#4fc3f7',
      main: '#29b6f6',
      dark: '#0288d1',
      contrastText: '#ffffff',
    },
  }
};

declare module '@mui/material/styles' {
  interface Palette {
    rose: Palette['success'];
    ghost: Palette['success'];
    brand: Palette['success'];
  }
  interface PaletteOptions {
    rose: Palette['success'];
    ghost: PaletteOptions['success'];
    brand: PaletteOptions['success'];
  }
  interface TypeText {
    night: string;
  }
  interface PaletteTextOptions {
    night: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    ghost: true;
    brand: true,
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    ghost: true;
    brand: true,
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsColorOverrides {
    ghost: true;
    brand: true,
  }
}

declare module '@mui/material/Tabs' {
  interface TabsPropsColorOverrides {
    ghost: true;
    brand: true,
  }
}

declare module '@mui/material/FormControl' {
  interface FormControlPropsColorOverrides {
    ghost: true;
    brand: true,
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    brand: true;
    ghost: true;
  }
}



export const palette = Palette.palette;