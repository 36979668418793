import React from 'react';
import { ArcMenuItem, ArcOutlinedInput, ArcOutlinedSelect, ArcSkeleton, ArcStack, ArcText } from '@src/core/ui';
import { SettingItemProps, SettingsProps } from './types';
import { useGetSettingsQuery, useSetSettingsMutation } from '@src/features/settings/redux';
import { SettingEntity } from '@src/features/settings/entities';
import { toast } from 'react-toastify';
import { EMPTY_VALUE, SETTING_UNITS_KEY } from '@src/core/config';
import { UnitType } from '@src/core/enums';

const Settings: React.FC<SettingsProps> = () => {
  const { data: settings = [], isLoading: isSettingsLoading } = useGetSettingsQuery();
  const [ setSettingItem, { isLoading: isSubmitting } ] = useSetSettingsMutation();

  const onSetSettingItem = (settings: SettingEntity[]) => {
    setSettingItem(settings).unwrap().then(() => {
      toast.success('SET SETTING SUCCESS TEXT');
    });  
  };

  if (isSettingsLoading) {
    return (
      <ArcStack spacing={2}>
        <ArcSkeleton height={50} />
        <ArcSkeleton height={50} />
        <ArcSkeleton height={50} />
        <ArcSkeleton height={50} />
        <ArcSkeleton height={50} />
        <ArcSkeleton height={50} />
      </ArcStack>
    );
  }

  return (
    <ArcStack spacing={2}>
      {settings.map((setting) => {
        if (setting.key === SETTING_UNITS_KEY) {
          return (
            <UnitsSettingItem 
              key={setting.key} 
              setting={setting} 
              isSubmitting={isSubmitting} 
              onSetSettingItem={onSetSettingItem}  />
          );
        }
        return (
          <SettingItem 
            key={setting.key} 
            setting={setting} 
            isSubmitting={isSubmitting}
            onSetSettingItem={onSetSettingItem} />
        );
      })}

    </ArcStack>
  );
};


const UnitsSettingItem: React.FC<SettingItemProps> = (props) => {
  const { setting, isSubmitting, onSetSettingItem } = props;

  return (
    <ArcStack spacing={1} direction='row' alignItems='center' justifyContent='space-between'>
      <ArcText.h5> {setting.name} </ArcText.h5>
      <ArcOutlinedSelect 
        sx={{ width: 250 }}
        value={setting.value}
        disabled={isSubmitting}
        onChange={(e) => {
          const current = e.target.value;
          if (typeof current === 'string') {
            const payload = { ...setting, value: current };
            onSetSettingItem([payload]);
          }
        }}>
        <ArcMenuItem value={UnitType.SI}> {UnitType.SI} </ArcMenuItem>
        <ArcMenuItem value={UnitType.IMPERIAL}> {UnitType.IMPERIAL} </ArcMenuItem>
      </ArcOutlinedSelect>
    </ArcStack>
  );
};

const SettingItem: React.FC<SettingItemProps> = (props) => {

  const { setting, isSubmitting, onSetSettingItem } = props;

  const [readOnly, setReadOnly] = React.useState(true);
  const [value, setValue] = React.useState(setting.value ?? EMPTY_VALUE);

  const onSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {

    if (e.key !== 'Enter') return;

    if (value === setting.value) return;

    onSetSettingItem([{ ...setting, value }]);

    setReadOnly(true);
  };

  return (
    <ArcStack spacing={1} direction='row' alignItems='center' justifyContent='space-between'>
      <ArcText.h5> {setting.name} </ArcText.h5>
      <ArcOutlinedInput 
        value={value} 
        sx={{ width: 250 }} 
        onKeyDown={onSubmit}
        disabled={isSubmitting}
        onDoubleClick={() => setReadOnly(false)}
        onChange={(e) => setValue(e.target.value)}
        slotProps={{ input: { readOnly: readOnly } }} />
    </ArcStack>
  );
};

export default Settings;