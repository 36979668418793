import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { EnRouteEmergencyButtonsProps } from './types';
import { ArcButton, ArcStack, ArcText } from '@src/core/ui';
import { useSendCommandMutation } from '@src/features/order/redux';
import { EmergencyStatusEnum } from '@src/features/order/entities';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const EnRouteEmergencyButtons: React.FC<EnRouteEmergencyButtonsProps> = () => {
  const { missionId } = useParams();
  const [ sendCommand, { isLoading } ] = useSendCommandMutation();

  const onSend = (command: EmergencyStatusEnum) => {
    sendCommand({ missionId, command }).then(() => {
      toast.success(`${command} SUCCESS TEXT`);
    });
  };

  return ( 
    <ArcStack>
      <ArcStack 
        height={18}
        spacing='4px' 
        direction='row' 
        alignItems='center'
        sx={{ cursor: 'pointer' }}>
        <ArcText.caption fontSize={12} fontWeight={400}> EMERGENCY SIGNALS </ArcText.caption>
        <ChevronRightIcon sx={{ color: 'text.secondary', fontSize: 20 }} />
      </ArcStack>

      
      <ArcStack mt={1} spacing={1}>
        <ArcStack direction='row' spacing={1}>
          <ArcButton.ghost 
            fullWidth 
            isLoading={isLoading}
            onClick={() => onSend(EmergencyStatusEnum.LAND)}> 
              LAND 
          </ArcButton.ghost>
          <ArcButton.ghost 
            fullWidth 
            isLoading={isLoading}
            onClick={() => onSend(EmergencyStatusEnum.RETURN)}> 
              RETURN 
          </ArcButton.ghost>
        </ArcStack>
      </ArcStack>
    </ArcStack>
  );
};

export default EnRouteEmergencyButtons;